import React from "react";
import {ArrowDownIcon, SearchIcon} from "../../../../../image";
import './search-texts.scss'
import {useTranslation} from "react-i18next";

function SearchTexts(props) {
    const {searchText, setSearchText, foundedTexts, activeTextIndex, toggleActiveTextIndex} = props;

    const {t} = useTranslation();

    function getInputValue(e) {
        setSearchText(e.target.value)
        toggleActiveTextIndex(0);
    }

    return <div className="texts-search-wrapper">
        <SearchIcon/>
        <input
            onChange={getInputValue}
            value={searchText}
            name="description"
            placeholder={t('input_search')}
            type="text"
        />
        <>
            <div className="arrows">
                <ArrowDownIcon onClick={()=> activeTextIndex > 0 && toggleActiveTextIndex(activeTextIndex - 1)}/>
                <ArrowDownIcon onClick={()=> activeTextIndex < foundedTexts?.length && toggleActiveTextIndex(activeTextIndex + 1)}/>
            </div>
            <div className="count-wrapper">
                {activeTextIndex}/{foundedTexts?.length}
            </div>
        </>
    </div>
}

export default SearchTexts
