import React, {useEffect, useRef, useState} from "react";
import {Loader} from "../../../../../shared/features/Loader";
import {generateMediaUrl} from "../../../../../utils/generateMediaUrl";
import {formatDate} from "../../../../../utils/helperFunctions";
import i18n from "i18next";
import dayjs from "dayjs";
import UserIcons from "../../../../../shared/features/userIcons/UserIcons";
import './VideoBlock.scss';
import {Link} from "react-router-dom";
import {ChainIcon, EditNewIcon, ShevronTopIcon} from "../../../../../image";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import ChainsDropDown from "../../../../../shared/features/Chains/ChainsDropDown/ChainsDropDown";
import {getDistanceRightBody} from "../../../../../shared/features/Chains/helpers";
import {useTranslation} from "react-i18next";
import {Dropdown, Popover, Tooltip} from "antd";
import {MarkDownText} from "../../../../../shared/features/MarkDownText";
import Highlighter from "react-highlight-words";


const VideoBlock = ({video, badge, routs, channelsList, chainList, ref, isChaneType = false, isPreview, color, isSearchText}) => {
	const [isVisibleDefaultPoster, setIsVisibleDefaultPoster] = useState(false);
	const [isLoader, setLoader] = useState(false);
	const [showChannels, setShowChannels] = useState(false);
	const [showChains, setShowChains] = useState(false);
	const blockRef = useRef();
	const badgeChainsId = (badge?.chains || []).map((item)=>item.id);

	const chainsVideo = (chainList || [])
		.filter((item) => (video?.chains_ids || []).includes(item?.id))
		.filter((item) =>  (badgeChainsId).includes(item?.id));

	const {t} = useTranslation();

	useEffect(() => {
		document.addEventListener('click', toggleMenu, {capture: true});
		return () => {
			document.removeEventListener('click', toggleMenu)
		}
	}, []);

	const toggleMenu = (event) => {
		const path = event.composedPath ? event.composedPath() : event.path
		if(!path?.includes(ref?.current)) {
			setShowChannels(false)
		}
	}
	const distanceRight = getDistanceRightBody(video?.id);
	const [openPopover, setOpenPopover] = useState(false);

	const handleOpenChange = (open) => {
		setOpenPopover(open);
	};

	return (
		<div
			className="video--poster"
			style={{
				'--distanceRight': `${distanceRight < 150 ? '10px' : 'auto'}`,
		}}
			id={video?.id}
		>
			{isLoader && (
				<div className="video--loader">
					<Loader size={50}/>
				</div>
			)}
			<div className="video__wrap">
			<Link to={{
				pathname: `/dashboard/videos/video/${video?.id}`,
				state: {routs}
			}} className={'video--poster'}
			>
				{isPreview ?
					<Tooltip
						overlayInnerStyle={{width: '260px', padding: 0, backgroundColor: 'rgba(24, 24, 24, 0.95)'}}
						title={
							<div className='video-preview-block'>
								<p className='video-preview-block--title'>{video?.description}</p>
								<MarkDownText
									className="video-preview-block--text"
									text={video?.summary}
								/>
							</div>
						}
						placement="bottom"
						mouseEnterDelay={0.5}
					>
						<video
							ref={blockRef}
							className={`video--poster`}
							poster={isVisibleDefaultPoster ? "https://grinvich.com/static/assets/images/media-default-video.png" : null}
							width="114" height="74"
							onCanPlay={() => setIsVisibleDefaultPoster(false)}
							onError={() => {
								setIsVisibleDefaultPoster(true);
								setLoader(false);
							}}
							onLoadStart={() => setLoader(true)}
							onLoadedData={() => setLoader(false)}
						>
							<source
								src={video?.url_s3 ? video?.url_s3 : generateMediaUrl(video?.url)}
								type="video/mp4"
							/>
						</video>
					</Tooltip>
					:
					<video
						ref={blockRef}
						className={`video--poster`}
						poster={isVisibleDefaultPoster ? "https://grinvich.com/static/assets/images/media-default-video.png" : null}
						width="114" height="74"
						onCanPlay={() => setIsVisibleDefaultPoster(false)}
						onError={() => {
							setIsVisibleDefaultPoster(true);
							setLoader(false);
						}}
						onLoadStart={() => setLoader(true)}
						onLoadedData={() => setLoader(false)}
					>
						<source
							src={video?.url_s3 ? video?.url_s3 : generateMediaUrl(video?.url)}
							type="video/mp4"
						/>
					</video>
				}
			</Link>
			<div className="video__channels" >
				{(chainList || []).length > 0 ?
					<div className="chain_list" style={{'--chain_сolor': channelsList?.[badge?.id]?.color || color || '#33BCC5'}}>
					<Popover
						arrow={false}
						trigger='click'
						placement="topLeft"
						onOpenChange={handleOpenChange}
						overlayClassName='test'
						content={
								<div className="chain_list" style={{'--chain_сolor': channelsList?.[badge?.id]?.color || color || '#33BCC5'}}>
									{chainsVideo.map((item) => (
										<Link
											key={item?.id}
											to={{
												pathname: `/dashboard/videos/channel/${badge?.id}/chains/${item?.id}`,
												state: {
													routs: [
														{
															path: '/dashboard/videos/review',
															name: t('menu_item_video')
														},
														{
															path: '/dashboard/videos/review',
															name: t('menu_item_review')
														},
														{
															path: `/dashboard/videos/channels/${badge.id}`,
															name: badge?.name
														},
														{
															path: `/dashboard/videos/channel/${badge?.id}/chains/${item?.id}`,
															name: item?.name,
															active: true
														}
													]
												}
											}} className={'chain_item'}>
											<ChainIcon/>
											<p>{item.name}</p>
										</Link>
									))}
									<Popover
										arrow={false}
										trigger='click'
										placement="rightTop"
										overlayClassName='popover_manage-chain'
										content={
											<ChainsDropDown
												isBindChain={true}
												chains={badge?.chains || []}
												channel={badge}
												videoInfo={video}
												routs={routs}
												isAll={isChaneType}
												channelStyle={channelsList?.[badge?.id]}
												isVideoChain={true}
											/>
										}
										>
										<div className="chain_item">
											<EditNewIcon/>
											Manage chain
										</div>
									</Popover>

								</div>

					}
					>
								<div
						 			className="video__channels--badge"
								>
						 			<span className="dote" style={{backgroundColor: channelsList?.[badge?.id]?.color || '#33BCC5'}}/>
									<p>{badge?.name}</p>

										<div className="video__channels--badge--chevron">
											{(chainsVideo || [])?.length ?
												<>
													<span>{(chainsVideo || [])?.length}</span>
													<ChainIcon className="video__channels--badge--chevron-icon"/>
												</>
												: null}
											<ShevronTopIcon style={{transform: openPopover ? 'rotate(180deg)' : 'rotate(360deg)'}}/>
										</div>

							</div>
					</Popover>
					</div>
					:
					<>
						{Object.keys(badge || {}) ?
							<div
								className="video__channels--badge"
								onClick={() => setShowChannels(!showChannels)}
							>
								<span className="dote" style={{backgroundColor: badge?.bg}}/>
								{badge?.name}
							</div>
							: null}
					</>
				}
				{video?.channels?.length > 1 &&
					<div
						className="channels_count"
						onClick={() => setShowChannels(!showChannels)}
					>
						+{video?.channels.length - 1}
					</div>
				}
			</div>
		</div>
			{
				video?.duration && <div className="video__time">
					{video?.duration}
				</div>
			}

			<div className="video__info">
				<div className="video__info--wrap">
					{isSearchText ?
						<Highlighter
							highlightClassName="highlight-text"
							searchWords={[isSearchText]}
							autoEscape={true}
							textToHighlight={video?.description}
						/> :
						<p className="video--name">{video?.description}</p>
						}
					<div className="video__info-date">
						<span>{formatDate(video?.created_at, i18n.language)},</span>
						<span>{dayjs(video?.created_at).format('HH:mm:ss')}</span>
					</div>
				</div>
				<UserIcons persons={video?.persons} videoId={video?.id}/>
			</div>
			{
				showChannels && !!video?.channels.length &&
				<div className="channels_list">
					{
						video?.channels?.map(item => {
							return <div className="channel_item"
							            key={channelsList[item]?.name}
							            style={{
								            backgroundColor: channelsList[item]?.bgColor,
								            color: channelsList[item]?.color,
							            }}>
								{channelsList[item]?.name}
							</div>
						})
					}
				</div>
			}

		</div>
	)
}

export default VideoBlock