// Import packages
import React, {useEffect, useState} from 'react';
import {
    NavLink, useLocation
} from 'react-router-dom';
import {connect} from "react-redux";
import {Accordion, OverlayTrigger, Tooltip} from "react-bootstrap";

// Import assets
import video from '../../../image/icons/ic_video.svg';
import personal from '../../../image/icons/personal.svg';
import roles from '../../../image/icons/ic_roles.svg';
import {
    AnonymousMenuIcon,
    BuildingsIcon,
    CatalogueMenuIcon,
    ManageMenuIcon,
    OpenMenuIcon, PersonIcon, RolesIcon, VideoIcon,
    VideoMenuIcon
} from "../../../image";
import './menu.scss';

// Import Components
import DropdownMenu from "../dropdownMenu";

// Import Utils
import {history} from "../../../utils/history";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    GetUserById,
    GetUserProfile,
    LogOut,
    SetIsFullWidth,
    GetPersonById,
    ClearProfile,
    SetUserAvatar
} from "../../../redux/actions";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {useTranslation} from "react-i18next";
import {getAuthorName} from "../../../utils/helperFunctions";
import config from "../../../utils/config";

function Menu({
                  LogOut,
                  GetUserProfile,
                  userProfile,
                  GetUserById,
                  userById,
                  isFullWidth,
                  SetIsFullWidth,
                  GetPersonById,
                  userRoles,
                  ClearProfile,
                  SetUserAvatar,
                  userAvatar
              }) {
    const location = useLocation()
    const {t, i18n} = useTranslation();

    const [hoverIcon, setHoverIcon] = useState(false);
    useEffect(() => {
        GetUserProfile().then((res) => {
            res?.attached_person?.id && GetPersonById(res?.attached_person?.id, true).then((res) => {
                SetUserAvatar(res?.avatar)
            })
        })
    }, []);
    useEffect(() => {
        // userProfile?.attached_person?.id && GetPersonById(userProfile?.attached_person?.id).then((res) => {
        //     setAvatar(res?.avatar)
        // });
        SetIsFullWidth(!!location?.pathname?.includes('review'))
    }, [location?.pathname])

    useEffect(() => {
        GetUserById(userProfile?.id)
    }, [userProfile?.id])

    const renderTooltip = (props, text) => (
        <Tooltip id="menu-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    const tooltipWrapper = ({element, text}) => {
        return <OverlayTrigger
            flip={false}
            popperConfig={{}}
            onHide={undefined}
            onToggle={undefined}
            target={'auto-start'}
            defaultShow={false}
            show={undefined}
            trigger={undefined}
            placement="right"
            delay={{show: 100, hide: 200}}
            overlay={(props) => renderTooltip(props, text)}
        >
            {element}
        </OverlayTrigger>
    }

    const logOut = () => {
        LogOut()
            .then(() => {
                history.push('/');
            })
        ClearProfile()
    }

    return (
        <div className={`menu ${!isFullWidth ? 'small' : ''}`}>
            <div className="">
                {isFullWidth ? <NavLink to="/dashboard/videos/review"
                                        className={`menu__logo ${!isFullWidth ? 'small-menu' : ''}`}
                                        exact>
                        {t('secretopus_name')}
                        {isFullWidth && <button className="open-menu" onClick={() => {
                            SetIsFullWidth(!isFullWidth)
                        }}>
                            <OpenMenuIcon/>
                        </button>}
                    </NavLink>
                    : <button className={`open-menu menu__logo ${!isFullWidth ? 'small-menu' : ''}`}
                              onMouseOver={() => setHoverIcon(true)}
                              onMouseLeave={() => setHoverIcon(false)}
                              onClick={() => {
                                  SetIsFullWidth(!isFullWidth)
                              }}>
                        {!hoverIcon ? <span className={''}>
                            {t('secretopus_name')?.slice(0, 1)}
                        </span> : tooltipWrapper({
                            element: <OpenMenuIcon/>,
                            text: t('menu_open')
                        })}
                    </button>}
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className={`sub_menu ${!isFullWidth ? 'small-menu' : ''}`}>
                        <Accordion.Header
                            className={`menu_item ${location.pathname.includes('videos') ? 'active_menu' : ''} ${!isFullWidth ? 'small-menu' : ''}`}>
                            <div className="menu_link">
                                {isFullWidth ? <>
                                    <VideoIcon/>
                                    {isFullWidth && <p>{t('menu_item_video')}</p>}
                                </> : tooltipWrapper({
                                    element: <VideoIcon/>,
                                    text: t('menu_item_video')
                                })}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <NavLink to={'/dashboard/videos/review'}
                                     className="menu_link menu__sub_item"
                                     activeClassName="active_link"
                                     exact>
                                {isFullWidth ? t('menu_item_review') : tooltipWrapper({
                                    element: <VideoMenuIcon/>,
                                    text: t('menu_item_review')
                                })}
                            </NavLink>
                            <NavLink to={'/dashboard/videos/manage'}
                                     className="menu_link menu__sub_item"
                                     activeClassName="active_link"
                                     exact>
                                {isFullWidth ? t('menu_item_manage_video')
                                    : tooltipWrapper({
                                        element: <ManageMenuIcon/>,
                                        text: t('menu_item_manage_video')
                                    })}
                            </NavLink>
                        </Accordion.Body>
                    </Accordion.Item>
                    {<Accordion.Item eventKey="1" className={`sub_menu ${!isFullWidth ? 'small-menu' : ''}`}>
                        <Accordion.Header
                            className={`menu_item ${location.pathname.includes('users') ? 'active_menu' : ''}`}>
                            <div className="menu_link">
                                {
                                    isFullWidth ? <>
                                        <PersonIcon/>
                                        {isFullWidth && <p>{t('menu_item_persons')}</p>}
                                    </> : tooltipWrapper({
                                        element: <PersonIcon/>,
                                        text: t('menu_item_persons')
                                    })}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            {userRoles?.permissions?.find(i => i.name === 'users_read')
                                && userRoles?.permissions?.find(i => i.name === 'persons_read') &&
                                <NavLink to={'/dashboard/users/persons'}
                                         className="menu_link menu__sub_item"
                                         activeClassName="active_link"
                                         exact>
                                    {isFullWidth ? t('menu_item_catalogue') :
                                        tooltipWrapper({
                                            element: <CatalogueMenuIcon/>,
                                            text: t('menu_item_catalogue')
                                        })}
                                </NavLink>}
                            {userRoles?.permissions?.find(i => i.name === 'persons_read') &&
                                <NavLink to={'/dashboard/users/new'}
                                         className="menu_link menu__sub_item"
                                         activeClassName="active_link"
                                         exact>
                                    {isFullWidth ? t('menu_item_anonymous') : tooltipWrapper({
                                        element: <AnonymousMenuIcon/>,
                                        text: t('menu_item_anonymous')
                                    })}
                                </NavLink>}
                        </Accordion.Body>
                    </Accordion.Item>}
                    {userRoles?.permissions?.find(i => i.name === 'roles_read') &&
                        <Accordion.Item eventKey="2" className={`sub_menu ${!isFullWidth ? 'small-menu' : ''}`}>
                            <Accordion.Header
                                className={`menu_item ${location.pathname.includes('roles') ? 'active_menu' : ''}`}>
                                <NavLink to={'/dashboard/roles'}
                                         className="menu_link"
                                         activeClassName="active_link"
                                         exact>
                                    {
                                        isFullWidth ? <>
                                            <RolesIcon className="roles-icon"/>
                                            {isFullWidth && <p>{t('menu_item_roles')}</p>}
                                        </> : tooltipWrapper({
                                            element: <RolesIcon className="roles-icon"/>,
                                            text: t('menu_item_roles')
                                        })
                                    }
                                </NavLink>
                            </Accordion.Header>
                        </Accordion.Item>}
                </Accordion>
            </div>
            <div className="bottom-part">
                <DropdownMenu button={
                    <div className={`menu__user-content`}>
                        <img src={generateMediaUrl(userAvatar)} alt=""/>
                        {isFullWidth && <div className="inner">
                            <div className="name">
                                {getAuthorName(userProfile?.attached_person?.id ? userProfile?.attached_person : userById, null, i18n.language )}
                            </div>
                            <div className="company">
                                <BuildingsIcon/>
                                <span>{userProfile?.company?.name}</span>
                            </div>
                            {!!userProfile?.roles?.length &&
                                <div className="user">{userProfile?.roles?.[0]?.name}</div>}
                        </div>}
                    </div>
                }
                              className={`${!isFullWidth ? 'small-menu' : ''}`}
                              placement={'top'}
                              closeAfterToggle={true}
                              contentWidth={'190px'}>
                    <div className="menu-item" onClick={() => {
                        history.push('/dashboard/profile');
                    }}>{t('profile_profile')}
                    </div>
                    <div className="menu-item log-out" onClick={logOut}>
                        {t('profile_log_out')}
                    </div>
                </DropdownMenu>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'userById',
        'personById',
        'userProfile',
        'isFullWidth',
        'userRoles',
        'userAvatar',
    ])
};

const mapDispatchToProps = {
    LogOut,
    GetUserById,
    GetUserProfile,
    SetIsFullWidth,
    GetPersonById,
    ClearProfile,
    SetUserAvatar
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
