import React, {useState} from 'react';
import './Chat.scss'
import {ArrowLeftIcon} from "../../../../image";
import MessageBlock from "../MessageBlock/MessageBlock";
import SearchBlock from "../SearchBlock/SearchBlock";
import {useTranslation} from "react-i18next";

export const TABS = {
	MESSAGE: 0,
	SEARCH: 1
}

const Chat = (props) => {
	const {isVisibleChat,hidePopover} = props;
	const {t} = useTranslation();
	const [activeTab, setActiveTab] = useState(TABS.MESSAGE);

	return (
		<div className="chat">
			<div className={`chat__header ${activeTab === TABS.SEARCH ? 'chat__header--search' : 'chat__header--message'}`}>
				<span className="chat__header--title">{activeTab === TABS.SEARCH ? t('input_search') : t('chat_title')}</span>
				<ArrowLeftIcon onClick={hidePopover}/>
			</div>
			{activeTab === TABS.MESSAGE ?
				<MessageBlock
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					isVisibleChat={isVisibleChat}
				/>
				: null}
			{activeTab === TABS.SEARCH ?
				<SearchBlock
					activeTab={activeTab}
					setActiveTab={setActiveTab}
				/>
				: null}
		</div>
	);
};

export default Chat;