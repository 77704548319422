// Import packages
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Highlighter from "react-highlight-words";
import {Link, useLocation} from "react-router-dom";
import {Toast} from "react-bootstrap";

// Import assets
import './searcher.scss';

// Import Components
import FuseSearchInput from '../FuseSearchInput';
import NewRecorder from "../RecorderNew/NewRecorder";
import DropdownMenu from "../dropdownMenu";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
	ClearVideosSearch,
	GetVideosSearch,
	uploadVideo,
	VideoUrlRecord,
	GetVideos,
	ClearTextSearch
} from "../../../redux/actions";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import UserInfoModal from "../../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import {AvatarPlaceholder, PlayIcon, VideoCameraIcon} from "../../../image";
import {history} from "../../../utils/history";
import {useTranslation} from "react-i18next";
import Notifications from "../notification/Notifications";
import debounce from "lodash.debounce";
import SearchVideoBlock from "../FuseSearchInput/SearchVideoBlock";
import {getAuthorName} from "../../../utils/helperFunctions";
import dayjs from "dayjs";
import {monthsShort, monthsShortEn} from "../../../utils/constants";


const formatDate = (date, lng) => {
	const day = dayjs(date).date();
	const monthIndex = dayjs(date).month(); // 0-11
	const year = dayjs(date).year();
	const month = lng === 'ru' ? monthsShort[monthIndex] : monthsShortEn[monthIndex];

	return `${day} ${month} ${year}`;
};

function Searcher(props) {
	const {changeVideoList, className, searchVideos, isFullWidth} = props;
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(undefined);
	const [showToast, setShowToast] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [searchText, setSearchText] = useState('');
	const [isFirstSearch, setIsFirstSearch] = useState(false);
	const findSearch = (searchText || '').replace(/ё/gi, 'е');
	const {i18n} = useTranslation();

	const [showAll, setShowAll] = useState({
		videos: false,
		persons: false,
		transcriptions: false,
	});
	const [showPersonsModal, setShowPersonsModal] = useState(false)
	const [currentUser, setCurrentUser] = useState(null)
	const [focusedURL, setFocusedURL] = useState(false)
	const [urlValue, setUrlValue] = useState('')
	const [errorUrl, setErrorUrl] = useState(false)
	const [isLoading, setIsLoading] = useState(false);
	const uploadRef = useRef();
	const location = useLocation();

	const {t} = useTranslation();

	const onSearchVideos = React.useCallback(debounce(async (value) => {
		try {
			if(value) {
				setIsLoading(true);
				setIsFirstSearch(true);
				await props.GetVideosSearch({
					search_query: value
				})
				setIsLoading(false);
			} else {
				props.ClearVideosSearch();
				setIsFirstSearch(false);
				props.ClearTextSearch();
			}
		} catch (error) {
			setIsLoading(false);
			console.warn(error)
		}
	}, 500), [])

	function openModal(data) {
		setShowPersonsModal(true)
		setCurrentUser(data)
	}

	function closeModal() {
		setShowPersonsModal(false)
	}

	function submitFile(newFile) {
		let formData = new FormData();
		formData.append('file', newFile);
		setLoading(true)
		props.uploadVideo(formData)
			.then(() => {
				props.GetVideos({limit: 20, manage: true})
			})
			.catch(() => {
				setShowToast(true)
			})
			.finally(() => {
				setLoading(false)
				setFile(undefined)
			});
	}

	function handleUploadClick() {
		uploadRef.current?.click();
	}

	function toggleShowAll(name) {
		setShowAll({
			...showAll,
			[name]: !showAll[name]
		})
	}

	function handleFileUpload(e) {
		if(!e.target.files) {
			return;
		}
		if(e.target.files[0].type.includes('mp4')) {
			setFile(e.target.files[0]);
			submitFile(e.target.files[0])
		} else {
			setErrorText(t('error_file'));
			toggleToast()
		}
	}

	function handleBlur() {
		// setSearchText('')
		// props.ClearVideosSearch()
	}

	const toggleToast = () => setShowToast(!showToast);

	function ErrorToast() {
		return <Toast show={showToast} onClose={toggleToast} className={'upload_video_answer'}>
			<Toast.Header>
				<img
					src=""
					className="rounded me-2"
					alt=""
				/>
				<strong className="me-auto">{t('error_title')}</strong>
			</Toast.Header>
			<Toast.Body>{errorText ? errorText : t('error_description')}</Toast.Body>
		</Toast>
	}

	function getInputValue(e) {
		const {value} = e.target

		setUrlValue(value)
		setErrorUrl(false)
	}

	function saveUrl() {
		if(urlValue) {
			props.VideoUrlRecord({
				meeting_url: urlValue
			}).then((res) => {
				setUrlValue('')
				setFocusedURL(false)
				history.push({
					pathname: `/dashboard/videos/video/${res?.video_id}`,
					state: {openEdit: true}
				})
			}).catch(() => {
				setErrorUrl(true)
			})
		}
	}

	const visibleContent = searchVideos && !!Object.keys(searchVideos)?.length &&
		(!!searchVideos?.videos?.length ||
			!!searchVideos?.persons?.length ||
			!!searchVideos?.transcriptions?.length);

	useEffect(() => {
		setSearchText(props.search_query);

		return () => {
			props.ClearTextSearch();
		}
	}, []);

	return (
		<div className={`searcher-wrapper ${className ? className : ''} ${isFullWidth ? '' : 'full'}`}>
			<DropdownMenu
				className={'search-input-wrapper'}
				button={
					<FuseSearchInput
						type="text"
						placeholder={t('input_search')}
						searchKeysDeep={['name']}
						searchText={findSearch}
						value={searchText}
						setSearchText={(value) => {
							setSearchText(value);
							onSearchVideos(value);
						}}
						changeVideoList={changeVideoList}
						handleBlur={handleBlur}
						isLoader={isLoading}
						onClear={() => {
							setSearchText('');
							props.ClearVideosSearch();
							setIsFirstSearch(false);
							props.ClearTextSearch();
						}}
					/>
				}
				closeAfterToggle={true} contentWidth={'670px'}
			>
				{(!visibleContent && isFirstSearch && !isLoading) ?
					<div className="non-found-text">Не удалось найти результатов, соответствующих вашему запросу</div>
					:
					<div className="search-content">
						{!!searchVideos?.videos?.length &&
							<div className="search_videos">
								<div className="search__header">
									<span className="search__header--title">{t('table_video')}</span>
									{(searchVideos?.videos?.length || 0) > 2 &&
										<span
											className="search__header--count"
											onClick={(e) => {
												e.stopPropagation();
												toggleShowAll('videos');
											}}
										>
                                            {showAll.videos ? t('hide_all') : t('show_all')}&nbsp;
											{searchVideos?.videos?.length}
                                        </span>
									}
								</div>
								<div className="search_videos__list">
									{searchVideos?.videos?.slice(0, showAll.videos ? searchVideos?.videos?.length : 2)?.map(item => {
										return <Link to={{
											pathname: `/dashboard/videos/video/${item?.id}`,
											state: {
												routs: [
													{
														path: '/dashboard/videos/review',
														name: t('menu_item_video')
													},
													{
														path: '/dashboard/videos/review',
														name: t('menu_item_review')
													},
													{
														isBack: true,
														name: t('input_search')
													}
												]
											}
										}}
										             className="search_videos__list--item" key={item.id}>
											<SearchVideoBlock
												item={item}
												searchText={findSearch}
											/>
										</Link>
									})}
								</div>
							</div>}

						{!!searchVideos?.persons?.length && <div className="search_persons">
							<div className="search__header">
								<span className="search__header--title">{t('menu_item_persons')}</span>
								{(searchVideos?.persons?.length || 0) > 2 &&
									<span
										className="search__header--count"
										onClick={(e) => {
											e.stopPropagation();
											toggleShowAll('persons');
										}}
									>
                                             {showAll.persons ? t('hide_all') : t('show_all')}&nbsp;{searchVideos?.persons?.length}
                                        </span>
								}
							</div>
							<div className="search_persons__list">
								{searchVideos?.persons?.slice(0, showAll.persons ? searchVideos?.persons?.length : 2)?.map(item => {
									return (
										<div
											key={item.id}
											className="search_persons__list-item"
											onClick={(e) => {
												e.stopPropagation();
												openModal(item)
											}}
										>
											{item?.avatar && item?.avatar !== "None"
												? <img src={generateMediaUrl(item?.avatar)} alt=""/>
												: <img src={AvatarPlaceholder} alt={""}/>
											}
											<div
												className="search_persons__list-item--title">{item?.first_name ?
												<Highlighter
													highlightClassName="highlight-text"
													searchWords={[findSearch]}
													autoEscape={true}
													textToHighlight={item?.first_name}
												/>
												: ''} {item?.last_name ?
												<Highlighter
													highlightClassName="highlight-text"
													searchWords={[findSearch]}
													autoEscape={true}
													textToHighlight={item?.last_name}
												/>
												: ''}
											</div>
										</div>
									)
								})}
							</div>
						</div>}

						{!!searchVideos?.transcriptions?.length &&
							<div className="search_transcriptions">
								<div className="search__header">
									<span className="search__header--title">{t('transcribed')}</span>
									{(searchVideos?.transcriptions?.length || 0) > 2 &&
										<span
											className="search__header--count"
											onClick={(e) => {
												e.stopPropagation();
												toggleShowAll('transcriptions');
											}}
										>
                                            {showAll.transcriptions ? t('hide_all') : t('show_all')}&nbsp;{searchVideos?.transcriptions?.length}
                                        </span>
									}
								</div>
								<div className="search_transcriptions__list">
									{searchVideos?.transcriptions?.slice(0, showAll.transcriptions ? searchVideos?.transcriptions?.length : 2)?.map(item => {
										return (
											<div className="search_transcriptions__list--item" key={item.id}>
												<div className="search_transcriptions__list--header">
													<p
														className="search_transcriptions__list--header-name"
														onClick={(e) => {
															e.stopPropagation();
															if(item?.person?.first_name && item?.person?.last_name) {
																openModal(item?.person);
															}
														}
														}
													>
														{getAuthorName(item?.person, item?.person?.id, i18n.language)}
													</p>
													<Link
														to={{
															pathname: `/dashboard/videos/video/${item?.video?.id}/time/${item?.start_time}`,
															state: {
																routs: [
																	{
																		path: '/dashboard/videos/review',
																		name: t('menu_item_video')
																	},
																	{
																		path: '/dashboard/videos/review',
																		name: t('menu_item_review')
																	},
																	{
																		isBack: true,
																		name: t('input_search')
																	}
																]
															}
														}}
														className="search_transcriptions__list--header-video-name">
														<PlayIcon/>
														{item?.video?.description || ''}
													</Link>
												</div>
												<Link
													to={{
														pathname: `/dashboard/videos/video/${item?.video?.id}/time/${item?.start_time}`,
														state: {
															routs: [
																{
																	path: '/dashboard/videos/review',
																	name: t('menu_item_video')
																},
																{
																	path: '/dashboard/videos/review',
																	name: t('menu_item_review')
																},
																{
																	isBack: true,
																	name: t('input_search')
																}
															]
														}
													}}
													className="search_transcriptions__list--link">
													<Highlighter
														highlightClassName="highlight-text"
														searchWords={[findSearch]}
														autoEscape={true}
														textToHighlight={item?.text}
													/>
												</Link>
												{item?.video?.created_at ?
													<div className="search_transcriptions__list--footer">
														<span>{formatDate(item?.video?.created_at, i18n.language)}, </span>
														<span>{dayjs(item?.video?.created_at).format('HH:mm:ss')}</span>
													</div>
													: null}
											</div>)
									})}
								</div>
							</div>}
					</div>
				}
			</DropdownMenu>
			<div className={`search__buttons ${focusedURL ? 'open-url' : ''}`}>
				<div className={`url-wrapper ${errorUrl ? 'error' : ''}`}
				     onClick={() => setFocusedURL(true)}
				     style={{
					     display: location.pathname.includes('/dashboard/videos/video') ? 'none' : 'flex'
				     }}>
					<VideoCameraIcon/>
					<input
						onChange={getInputValue}
						value={urlValue}
						name="description"
						placeholder={t('input_paste_url')}
						type="text"
						onKeyDown={(e) => {
							if(e.key === 'Enter') {
								saveUrl()
							}
						}}
					/>
					<button className="start" onClick={saveUrl}>{t('button_start')}</button>
				</div>
				<div className="overlay" onClick={() => setFocusedURL(false)}/>
				<NewRecorder token={props.token}/>
				<button className="search__buttons-import"
				        disabled={loading}
				        onClick={handleUploadClick}>
					{loading && <div className="spinner-border" role="status"/>}
					{file ? t('button_upload') : t('button_import')}
				</button>

				<input
					type="file"
					ref={uploadRef}
					onChange={handleFileUpload}
					style={{display: 'none'}}
				/>
				{/*     {record ? (
                            <Recorder record={record}/>
                        ) : (
                            <button
                                onClick={() => setRecord(true)}
                                className="search__buttons-record"
                            >
                                <RecordIcon/> Record
                            </button>
                        )}*/}
			</div>
			<Notifications/>
			{/*</div>*/}
			{ErrorToast()}
			{showPersonsModal && <UserInfoModal show={showPersonsModal} closeModal={closeModal} user={currentUser}/>}
		</div>
	);
}

const mapStateToProps = (state) => {
	return getPropsFromState(state, [
		'isMobile',
		'isFullWidth',
		'headerTitle',
		'searchVideos',
		'token',
		'searchText'
	])
};

const mapDispatchToProps = {
	uploadVideo,
	GetVideosSearch,
	ClearVideosSearch,
	VideoUrlRecord,
	GetVideos,
	ClearTextSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(Searcher);
