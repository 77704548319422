// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";

// Import assets
import './video-card.scss';
import UserIcons from "../../../../../shared/features/userIcons/UserIcons";

// Import Utils
import {generateMediaUrl, generateVideoUrl} from "../../../../../utils/generateMediaUrl";
import {changeTimeFormat} from "../../../../../utils/helperFunctions";
import {CloseIcon} from "../../../../../image";
import InfoTooltip from "../../Profile/tooltip";
import {Toast} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {
	GetVideos,
	UserDetachVideoService
} from "../../../../../redux/actions";
import {connect} from "react-redux";
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {Loader} from "../../../../../shared/features/Loader";
import {MarkDownText} from "../../../../../shared/features/MarkDownText";
import {Tooltip} from "antd";


const checkUrl = (url) => {
	const parts = url.split('/');
	let replaceUrl = url;

	if(Number(parts.at(-1))){
		replaceUrl = parts.slice(0, -1).join('/');
	}

	return ['/dashboard/videos/channels', '/dashboard/videos/review'].includes(replaceUrl)
}
//user - инфа по юзеру. Необязательное поле
//setVideoList - Необязательное поле
function VideoCard(props) {
	const {video, badge, className, channelsList, routs, user, setVideoList, isPreview} = props;
	const [showChannels, setShowChannels] = useState(false);
	const ref = useRef();
	const {t, i18n} = useTranslation();
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const url = window.location.pathname;
	const parts = url.split('/');
	const userIdQuery = parts.at(-1);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.addEventListener('click', toggleMenu, {capture: true});
		return () => {
			document.removeEventListener('click', toggleMenu)
		}
	}, []);

	const toggleMenu = (event) => {
		const path = event.composedPath ? event.composedPath() : event.path
		if(!path?.includes(ref?.current)) {
			setShowChannels(false)
		}
	}

	const onDetachUser = async () => {
		try {
			setIsLoading(true);
			await props.UserDetachVideoService({
				video_id: Number(video?.id),
				person_id: (user?.person_id || user?.id) ? (user?.person_id || user?.id) : (Number(userIdQuery) || null)
			});
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t('unpin_user_video_success')
			});
			const videosResponse = await props.GetVideos({
				limit: 1000,
				returnList: !!user,
				filters: [
					{
						firstColumnVal: 'and',
						secondColumnVal: 'staff',
						thirdColumnVal: 'present',
						fourthColumnVal: (user?.person_id || user?.id) ? (user?.person_id || user?.id) : (Number(userIdQuery) || null)
					}
				]
			});
			setIsLoading(false);
			if(user) {
				setVideoList && setVideoList(videosResponse || {});
			}


		} catch (e) {
			console.log(e);
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t('unpin_user_video_error')
			});
			setIsLoading(false);
		}
	}

	return <div
		className={`video_card ${className ? className : ''}`}
		ref={ref}
	>

			<>
				{isPreview ?
					<Tooltip
						overlayInnerStyle={{width: '260px', padding: 0}}
						title={
							<div className='video-preview-block'>
								<p className='video-preview-block--title'>{video?.description}</p>
								<MarkDownText
									className="video-preview-block--text"
									text={video?.summary}
								/>
							</div>
						}
						placement="bottom"
						mouseEnterDelay={0.5}
					>
						<Link to={{
							pathname: `/dashboard/videos/video/${video?.id}`,
							state: {routs}
						}} className={'video_card__video_wrapper'}>
							{
								video?.thumbnail_url ? <img src={generateMediaUrl(video?.thumbnail_url)} alt=""/>
									: <video width="279" height="167">
										<source src={video?.url_s3 ? video?.url_s3 : generateVideoUrl(video?.url)} type="video/mp4"/>
									</video>
							}
						</Link>
					</Tooltip>
					:
					<Link to={{
						pathname: `/dashboard/videos/video/${video?.id}`,
						state: {routs}
					}} className={'video_card__video_wrapper'}>
						{
							video?.thumbnail_url ? <img src={generateMediaUrl(video?.thumbnail_url)} alt=""/>
								: <video width="279" height="167">
									<source src={video?.url_s3 ? video?.url_s3 : generateVideoUrl(video?.url)} type="video/mp4"/>
								</video>
						}
					</Link>
				}
			</>
		{video?.duration && <div className="video_card__video-time">
			{video?.duration}
		</div>}
		{badge && <div className="video_card__video-section"
		               onClick={() => setShowChannels(!showChannels)}
		>
			<span className="dote" style={{backgroundColor: badge?.bg}}/>
			{badge?.name}
		</div>}
		{video?.channels.length > 1 && <div className="channels_count"
		                                    onClick={() => setShowChannels(!showChannels)}>
			+{video?.channels.length - 1}
		</div>}
		{
			showChannels && !!video?.channels.length && <div className="channels_list">
				{
					video?.channels?.map(item => {
						return <div className="channel_item"
						            key={channelsList[item]?.name}
						            style={{
							            backgroundColor: channelsList[item]?.bgColor,
							            color: channelsList[item]?.color,
						            }}>
							{channelsList[item]?.name}
						</div>
					})
				}
			</div>
		}
		<div className="video_card__video-information" onClick={(e) => e.stopPropagation()}>
			<div className="information_wrapper">
				<div className="information_name">
					{video?.description}
				</div>
				<p className="information_date">
					{changeTimeFormat(video?.created_at, i18n.language)}
				</p>
			</div>

			<UserIcons persons={video?.persons} updateUserInfo={setVideoList} user={user} videoId={video?.id}/>
		</div>
		{(!checkUrl(url) || !!user) &&
			<div className="video_card__detach">
				<InfoTooltip
					description={t('unpin_user_video')}
					placement={'left'}
					customContent={
						<p>{t('unpin_user_video')}</p>
					}
					elem={
						<div
							className="video_card__detach-icon"
							onClick={() => !isLoading ? onDetachUser() : null}
						>
							{isLoading ?
								<Loader size={20}/>
								:
								<CloseIcon/>
							}
						</div>}
				/>
			</div>
		}
		<Toast
			className='toaster'
			bg={toastInfo.type} onClose={() => setToastInfo({isShow: false})}
			show={toastInfo.isShow}
			delay={3000}
			autohide
		>
			{toastInfo?.text || ''}
		</Toast>
	</div>
}

const mapStateToProps = (state) => {
	return getPropsFromState(state, ['userProfile'])
};
const mapDispatchToProps = {
	UserDetachVideoService,
	GetVideos
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCard);

