import {TAGS_CONSTS} from "../constants";
import {_urlTags, request} from "../api";

export const GetTags = () => {
    let requestData = {
        url: `${_urlTags}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: TAGS_CONSTS.GET_TAGS,
                    payload: data
                })
            })
    };
};

export const AddTag = (data) => {
    let requestData = {
        url: `${_urlTags}`,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: TAGS_CONSTS.ADD_TAGS,
                    payload: {...data, id: res?.data?.id}
                })
            })
    };
};

export const UpdateTag = (id, data) => {
    const requestData = {
        url: `${_urlTags}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TAGS_CONSTS.UPDATE_TAGS,
                    payload: {
                        id, data
                    }
                });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};

export const DeleteTag = id => {
    const requestData = {
        url: `${_urlTags}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TAGS_CONSTS.DELETE_TAGS,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};
