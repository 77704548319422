// Import packages
import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './tabs-dropdown.scss';
import {CloseIcon, DeletingIcon, EditIcon, EllipsisIcon, PlusGreenIcon} from "../../../image";

// Import Components
import DropdownMenu from "../dropdownMenu";
import {Form} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {AddTag, DeleteTag, UpdatePersonTags, GetTags, GetPersonById} from "../../../redux/actions";
import {useTranslation} from "react-i18next";

function TabsDropdown(props) {
    const {user, tagsList, personById, show,onChangeTags} = props;

    const [checkedTags, setCheckedTags] = useState(null);
    const [openCreateTag, setOpenCreateTag] = useState(false);
    const [tagName, setTagName] = useState('');
    const [isEditTag, setIsEditTag] = useState({});
    const [editingTag, setEditingTag] = useState('');
    const [menuIsClosed, setMenuIsClosed] = useState(true);

    const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443']

    const {t} = useTranslation();

    useEffect(() => {
        props.GetTags()
        let channels = {}
        !!tagsList?.length && tagsList.forEach(i => {
            channels = {
                ...channels,
                [i.id]: {
                    id: i.id,
                    is_bound: !!personById?.tags?.length && !!personById?.tags.find(tag => tag.id === i.id)?.id
                }
            }
        })
        setCheckedTags(channels)
    }, [personById, user?.person_id, user?.id, show])

    function checkTags(e, id) {
        let channels = {
            ...checkedTags,
            [id]: {
                id,
                is_bound: e?.target?.checked
            }
        }
        setCheckedTags(channels);
        onChangeTags(Object.values(channels || {}).filter((item)=>!!item?.is_bound).map((item)=> item?.id))
    }

    function closeCreateTag(e) {
        e.stopPropagation()
        setOpenCreateTag(false)
        setTagName('')
    }

    function getTagValue(e) {
        const {value} = e.target
        setTagName(value)
    }

    function getEditingTagValue(e) {
        const {value} = e.target
        setEditingTag(value)
    }

    function handleCloseMenu() {
        setMenuIsClosed(!menuIsClosed)
    }

    function createTag(e) {
        e?.stopPropagation()
        props.AddTag({name: tagName})
        setOpenCreateTag(false)
        setTagName('')
    }

    function updateTag(e) {
        e.stopPropagation()
        // isEditTag && props.UpdateTag(id, {name: editingTag})
        // setIsEditTag({
        //     ...isEditTag,
        //     [id]: false
        // })
        setEditingTag('')
    }

    function updatePersonTags() {
        tagName && createTag()

        if(user){
            props.UpdatePersonTags(user?.attached_person?.id || user?.person_id || user?.id,
                {
                    tags: Object.values(checkedTags)
                },
                tagsList
            )
                .then(() => {
                    (user?.attached_person?.id || user?.person_id || user?.id)
                    && props.GetPersonById(user?.attached_person?.id || user?.person_id || user?.id)
                })
        }else {
            onChangeTags(Object.values(checkedTags || {}).filter((item)=>!!item?.is_bound).map((item)=> item?.id))
        }
        handleCloseMenu();
    }

    return <>
        <div className="profile-tags-title">{t('table_tags')}</div>
        <div className="profile-tags">
            {
                user ?
                    <Fragment>
                        {
                            !!personById?.tags?.length && personById?.tags?.slice(0, 4)?.map((data, index) => {
                                return <div className="profile-tags__item"
                                            key={data?.id}
                                            style={{
                                                borderColor: tagColors[index],
                                                color: tagColors[index]
                                            }}>
                                    {data?.name}
                                </div>
                            })
                        }
                    </Fragment>
                    :
                    <Fragment>
                        {
                            !!Object.values(checkedTags || {})?.length && Object.values(checkedTags || {}).filter((item)=>item.is_bound)?.slice(0, 4)?.map((data, index) => {
                               const findItem = tagsList.find((tag) => tag.id === data.id);
                               if(findItem){
                                   return <div className="profile-tags__item"
                                               key={findItem?.id}
                                               style={{
                                                   borderColor: tagColors[index],
                                                   color: tagColors[index]
                                               }}>
                                       {findItem?.name}
                                   </div>
                               }
                            })
                        }
                    </Fragment>
            }
        </div>

        <DropdownMenu
            button={<button className="tags-btn">
                <PlusGreenIcon/>
                {t('profile_add_tag')}
                {/*Add Tag*/}
            </button>}
            closeDropdown={menuIsClosed}
            onClose={handleCloseMenu}
            className={'tabs-dropdown'}
            closeAfterToggle={false}
            contentWidth={'443x'}
            fullWidth={false}>
            <div className="tags-title">
                {t('manage_tags')}
                <CloseIcon onClick={handleCloseMenu}/>
                {/*Manage tag*/}
            </div>
            <div className="tags-list">
                <Form className={'tags-form'}>
                    {!!tagsList?.length && tagsList?.map((data, index) => {
                        return <div key={`default-${data?.id}`} className="tag-item">
                            <Form.Check
                                type={'checkbox'}
                                className={'check-wrapper'}
                                // disabled={!user?.person_id}
                                checked={checkedTags?.[data?.id]?.is_bound}
                                onChange={(e) => {
                                    checkTags(e, data.id)
                                }}
                            />
                            {
                                isEditTag[data?.id] ? <div className="tag-item-edit">
                                        <input type="text"
                                               value={editingTag}
                                               name={'editingTag'}
                                               className="label editing-tag"
                                               onChange={getEditingTagValue}
                                               placeholder={data?.name}
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') {
                                                       updateTag(e, data?.id)
                                                   }
                                               }}
                                               onBlur={() => {
                                                   setIsEditTag({
                                                       ...isEditTag,
                                                       [data?.id]: false
                                                   })
                                                   setEditingTag('')
                                               }}
                                               autoFocus={true}
                                        />

                                        <div className="buttons">
                                            <button className={`close-btn`}
                                                    onClick={() => {
                                                        setIsEditTag({})
                                                        setEditingTag('')
                                                    }}>
                                                {t('button_cancel')}
                                            </button>

                                            <button
                                                className={`save-btn ${data?.name === editingTag ? 'disabled' : ''}`}
                                                disabled={data?.name === editingTag}
                                                onClick={(e) => {
                                                    updateTag(e, data?.id)
                                                }}
                                            >
                                                {t('button_save')}
                                            </button>
                                        </div>
                                    </div>
                                    : <div className="label" style={{
                                        borderColor: tagColors[index],
                                        color: tagColors[index]
                                    }}>{data?.name}</div>}
                            {!isEditTag[data?.id] && <div className="tag-menu" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <DropdownMenu button={<EllipsisIcon className={'icon-menu'}/>}
                                              closeAfterToggle={true}
                                              className={'actions-menu'}
                                              contentWidth={'136px'}>
                                    <div className={'menu-item'} onClick={() => {
                                        setIsEditTag({
                                            ...isEditTag,
                                            [data?.id]: true
                                        })
                                        setEditingTag(data?.name)
                                    }}>
                                        <EditIcon className={''}/>
                                        {t('button_edit_short')}
                                    </div>
                                    <div className={'menu-item'} onClick={() => props.DeleteTag(data?.id)}>
                                        <DeletingIcon className={''}/>
                                        {t('button_delete')}
                                    </div>
                                </DropdownMenu>
                            </div>}
                        </div>
                    })}
                </Form>
                <button className="create-tag" onClick={() => setOpenCreateTag(true)}>
                    {
                        openCreateTag ? <>
                                <input type="text"
                                       value={tagName}
                                       name={'tagName'}
                                       onChange={getTagValue}
                                       placeholder={t('profile_placeholder_tag_name')}
                                       onKeyDown={e => {
                                           e.key === 'Enter' && createTag(e)
                                       }}
                                       autoFocus={true}
                                />
                                <div className="buttons">
                                    <button className={`close-btn`}
                                            onClick={closeCreateTag}>
                                        {t('button_cancel')}
                                    </button>

                                    <button
                                        className={`save-btn`}
                                        onClick={(e) => {
                                            createTag(e)
                                        }}
                                    >
                                        {t('button_save')}
                                    </button>
                                </div>
                            </>
                            : <>
                                <PlusGreenIcon/>
                                <span>{t('create_label')}</span>
                            </>

                    }
                </button>
            </div>
            <button className={'save-tag-btn'}
                // disabled={!user?.person_id}
                    onClick={updatePersonTags}>
                {t('profile_save_tag')}
            </button>
        </DropdownMenu>
    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'tagsList'
    ])
};

const mapDispatchToProps = {
    AddTag, DeleteTag, UpdatePersonTags, GetTags, GetPersonById
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsDropdown);
