import React, {useCallback, useEffect, useState} from 'react';
import './UserSignatures.scss'
import {
	DeleteUserSignaturesService,
	GetUserSignaturesService,
	SaveSignatureService,
} from "../../../redux/actions/signaturesActions";
import {Toast} from "react-bootstrap";
import {CloseStrokeIcon, ErrorIcon, PlusIcon} from "../../../image";
import {connect} from "react-redux";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {GetPersons} from "../../../redux/actions";
import {useTranslation} from "react-i18next";

function validateString(str) {
	const words = str.trim().split(/\s+/);
	return !!(words.length >= 2 && words.every(word => word.length >= 3));
}

//userID? id  пользователя, если не указан. То это для нового пользователя
// onAddSignature - функция которая вызывается, при сохранении сигнатуры
const UserSignatures = (props) => {
	const [error, setErrorText] = useState();
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const [checkSignature, setCheckSignature] = useState();
	const [isVisibleForm, setVisibleForm] = useState(false);

	const toggleForm = useCallback(() => setVisibleForm(prevState => !prevState), []);
	const {userSignatures, personsList,userID, onAddSignature} = props;
	const listSignature = (userSignatures || []).filter((sig) => (sig.person_id === userID));
	const {t} = useTranslation();
	const [signatures, setSignatures] = useState([]);

	const onChangeSignature = async (e) => {
		const value = e.target?.value;
		setCheckSignature(value);

		if(Boolean(error)) setErrorText('');
	}

	const onSaveSignatures = async () => {
		try {
			if(!validateString(checkSignature)) {
				setErrorText(t('validate_signature'))
			} else {
				if(Boolean(error)) setErrorText('');
				if(userID) {
					await SaveSignatureService({
						signature_str: (checkSignature || '').trim(),
						person_id: (userID || null)
					});
					await props.GetUserSignaturesService();
					setToastInfo({
						isShow: true,
						type: 'success',
						text: t("add_signature_success")
					});
				}else {
					if(onAddSignature) onAddSignature([...signatures,checkSignature]);
					setSignatures([...signatures,checkSignature]);
				}
				setCheckSignature('');
			}
		} catch (error) {
			if(error?.response?.data && Number(error?.response?.data)) {
				const findUser = (personsList.results || []).find((item) => item.id === error.response.data);
				setErrorText(`${t('add_signature_error_v1')} ${findUser?.first_name || ''} ${findUser?.last_name || ''}`)
			} else {
				setToastInfo({
					isShow: true,
					type: 'danger',
					text: t('add_signature_error_v2')
				});
			}
		}
	}
	const deleteSignature = async (data) => {
		try {
			if(Boolean(error)) setErrorText('');
			if(userID){
				await props.DeleteUserSignaturesService({
					signature_str: data?.signature_str || null,
				});
				await props.GetUserSignaturesService();
			}else {
				const arr = signatures.filter((item)=>item !== data);
				setSignatures(arr);
				if(onAddSignature) onAddSignature(arr)
			}

			setToastInfo({
				isShow: true,
				type: 'success',
				text: t('delete_signature_success')
			});
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t('add_signature_error_delete')
			});
		}
	}

	useEffect(() => {
		props.GetUserSignaturesService();
		props.GetPersons({
			status: 'known',
			limit: 1000
		});
	}, []);

	return (
		<div className="signature">
			{!isVisibleForm ?
				<div className="signature__add-btn" onClick={toggleForm}>
					<PlusIcon/>
					<button className="button button--success"> {t('add_signature')}</button>
				</div>
				: null
			}
			{isVisibleForm ?
				<div className="signature__form">
					<div
						className="signature__container"
					>
						<input
							type="text"
							className="signature__container--input"
							value={checkSignature || ''}
							onChange={onChangeSignature}
						/>
					</div>
					<button
						className="button"
						onClick={() => {
							toggleForm();
							setCheckSignature('');
							setErrorText('')
						}}
					>
						{t('button_cancel_v2')}
					</button>
					<button
						className="button button--success"
						onClick={onSaveSignatures}
						disabled={!Boolean(checkSignature)}
					>
						{t('button_save')}
					</button>
				</div>
				: null}
			{Boolean(error) &&
				<div className="error__block">
					<ErrorIcon/>
					<p className="error__block--text">{error}</p>
				</div>}
			{(userID ? listSignature : signatures).length ?
				<div className="signature__list">
					{(userID ? listSignature : signatures).map((item, index) => (
						<div
							key={index}
							className="signature__list--item"
						>
							{userID ? item.signature_str : item}
							<CloseStrokeIcon
								onClick={() => deleteSignature(item)}
							/>
						</div>
					))}
				</div>
				: null
			}
			<Toast
				className='toaster'
				bg={toastInfo.type} onClose={() => setToastInfo({isShow: false})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				{toastInfo?.text || ''}
			</Toast>
		</div>
	);
};

const mapStateToProps = (state) => {
	return getPropsFromState(state, ['userSignatures', 'userProfile', 'personsList'])
};

const mapDispatchToProps = {
	GetUserSignaturesService,
	DeleteUserSignaturesService,
	GetPersons
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSignatures);