// Import packages
import React, {Suspense, useEffect} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";

// Import assets
import './styles/layout.scss';

// Import Components
import Dashboard from './pages/Dashboard';
import LogIn from "./pages/Authorization/LogIn/LogIn";
import Registration from "./pages/Authorization/Registration/Registration";
import OIDCAuth from "./shared/features/oidc";
import SetTitleComponent from "./shared/features/productName.jsx";

function App() {
    const suspenseFallback = (
        <div className="suspense-fallback">
            <p>Loading...</p>
        </div>
    );

    useEffect(()=>{}, [])

    return (
        <Suspense fallback={suspenseFallback}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SetTitleComponent/>
                    <Switch>
                        <OIDCAuth>
                        <Route exact strict={true} path="/" component={LogIn} />
                        <Route path="/registration" component={Registration}/>
                        <Route path="/dashboard" component={Dashboard}/>
{/*                         <Route path="*"> */}
{/*                             <Redirect to="/dashboard"/> */}
{/*                         </Route> */}
                        </OIDCAuth>
                    </Switch>
                </PersistGate>
            </Provider>
        </Suspense>
    );
}

export default App;
