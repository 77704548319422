import {_urlAuth, request} from "../api";
import {AUTH_CONSTS} from "../constants";
import {store} from "../store";

export const LoginUser = (loginCreds) => {
    const requestData = {
        url: `${_urlAuth}/login`,
        method: "POST",
        data: loginCreds,
    };
    return dispatch => {

        return request(requestData)
            .then((res) => {
                const loginData = res?.data;
                dispatch({
                    type: AUTH_CONSTS.LOG_IN,
                    payload: {
                        ...loginData,
                    }
                });
            })
    }
};

export const LogOut = () => {
    return async dispatch => {
        await dispatch({
            type: AUTH_CONSTS.LOG_OUT
        });
    }
};
export const LogOutActionCreator = () => {
         store.dispatch({
            type: AUTH_CONSTS.LOG_OUT
        });
};

export const CompleteOIDCAuthLogIn = (token) => {
    return dispatch => {
        dispatch({
           type: AUTH_CONSTS.LOG_IN,
           payload: {
               token,
           }
        });
    }
};

// todo: реализовать процедуру завершения выхода при OIDC авторизации
export const CompleteOIDCAuthLogOut = () => {

};

