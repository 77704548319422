import React, {useState} from 'react';
import './ChatBlock.scss';
import {OctopusIcon} from "../../image";
import {Popover, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import Chat from "./components/Chat/Chat";


const ChatBlock = () => {
	const {t} = useTranslation();
	const [isVisibleChat, setIsVisibleChat] = useState(false);
	const [isVisibleTooltip, setIsVisibleTooltip] = useState(true);
	const [open, setOpen] = useState(false);

	const hidePopover = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	return (
		<div
			className="chat-block"
			onWheel={(e) => e.stopPropagation()}
		>
			<Popover
				arrow={false}
				trigger='click'
				placement="rightTop"
				content={<Chat isVisibleChat={isVisibleChat} hidePopover={hidePopover}/>}
				overlayClassName="chat-block__popover"
				destroyTooltipOnHide={true}
				onOpenChange={handleOpenChange}
				open={open}

			>
				<Tooltip
					open={isVisibleTooltip}
					placement="left"
					title={
						<div className={'chat-block__tooltip'}>
							<span className="chat-block__tooltip--title">{t('chat_main_tooltip_title')}</span>
							<span className="chat-block__tooltip--text">{t('chat_main_tooltip_text')}</span>
						</div>
					}
				>
					<div
						className="badge"
						onMouseEnter={() => setIsVisibleTooltip(true)}
						onMouseLeave={() => setIsVisibleTooltip(false)}
						onClick={() => {
							setIsVisibleTooltip(false);
						}}
					>
						<div className="badge__left"/>
						<div className="badge__right">
							<OctopusIcon/>
						</div>
					</div>
				</Tooltip>
			</Popover>
		</div>
	);
};

export default ChatBlock;