// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Accordion, Modal, Tab, Tabs} from "react-bootstrap";

// Import assets
import './addUserModal.scss';
import '../../../Profile/contacts/profile-contacts.scss'
import {ArrowDownIcon, CloseIcon, EyeIcon} from "../../../../../../image";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {AddUser, GetUsersPersons} from "../../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import config from "../../../../../../utils/config"

// Import Components
import ProfileMedia from "../../../Profile/media";
import ProfileContacts from "../../../Profile/contacts";
import TabsDropdown from "../../../../../../shared/features/tabsDropdown/TabsDropdown";
import ParticipationData from "../../../Profile/ParticipationData";
import InfoTooltip from "../../../Profile/tooltip";
import UserRoles from "../../../../../../shared/profile/UserRoles";
import CombinePersonsDropdown from "../../../../../../shared/features/PombinePersons/CombinePersonsDropdown";
import UserSignatures from "../../../../../../shared/profile/UserSignatures/UserSignatures";
import {initialErrors, initialPersonInfoState} from "./const";
import {generateData, validateUserForm} from "./helpers";
import {Loader} from "../../../../../../shared/features/Loader";

const initialState = {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    position: undefined,
    login: undefined,
    password: undefined,
    note: undefined,
    person_info: undefined,
}
const initialContactState = {
    email: [''],
    phone_number: [''],
    linkedin: [''],
    facebook: [''],
}


function AddUserModal(props) {
    const {show, personById, rolesList,userProfile} = props;
    const {t} = useTranslation();

    const [userInfo, setUserInfo] = useState(initialState);
    const [contactItems, setContactItems] = useState(initialContactState);
    const [personInfo, setPersonInfo] = useState(initialPersonInfoState);
    const [errors, setErrors] = useState(initialErrors);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [userRole, setUserRole] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    let dataForm = generateData(userInfo, personInfo,contactItems, userRole);

    useEffect(() => {
        if((rolesList || 0).length > 1){
            setUserRole(rolesList?.[1])
        }
    }, [rolesList]);


    function getInputValue(e) {
        const {name, value} = e.target
        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function toggleRole(e, role) {
        e.stopPropagation()
        setUserRole(role)
    }

    async function saveChanges() {
        try{
            const isCheckValidateForm = validateUserForm(dataForm);
            if (!!isCheckValidateForm) {
                setErrors(isCheckValidateForm);
            }else {
                setLoading(true);
                setErrors(isCheckValidateForm || initialState);
                await props.AddUser(dataForm)
                await props.GetUsersPersons();
                setLoading(false);
                onClose();
            }
        }catch (e) {
            console.log('e',e)
            setLoading(false);
            setErrors({
                ...errors,
                addUser: `Ошибка при добавлении пользователя.  ${e?.response?.data?.error || ''}`
            })
        }
    }

    function onClose() {
        setUserInfo(initialState)
        setErrors(initialErrors)
        setContactItems(initialContactState)
        props.closeModal()
    }

    const  onChangePersonInfo = (name, value) => {
        setPersonInfo({
            ...personInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    useEffect(()=>{
        if(userProfile){
            onChangePersonInfo('company', userProfile?.company?.name)
        }
    },[userProfile])

    return <Modal
        show={show}
        onHide={onClose}
        size="lg"
        className={'info_modal'}
        centered
    >
        <div className="add_user_modal">
            <div className="user-header">
                {t('add_user_title')}
                <CloseIcon onClick={onClose}/>
            </div>
            <div className="add_user_modal__wrapper">
                <div className="add_user_modal__media">
                    <ProfileMedia/>
                    <TabsDropdown
                        onChangeTags={(tags)=>onChangePersonInfo('tags', tags)}
                        show={show}
                    />
                </div>
                <div className="add_user_modal__content">
                    <div className={'input-form'}>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_name')}</label>
                                <input type="text"
                                       value={userInfo?.first_name}
                                       autoComplete={'off'}
                                       name={'first_name'}
                                       onChange={getInputValue}
                                       placeholder={t('input_name')}
                                />
                                {!!errors?.first_name && (
                                    <div className="input_group__error">{errors?.first_name}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_surname')}</label>
                                <input type="text"
                                       value={userInfo?.last_name}
                                       name={'last_name'}
                                       autoComplete="off"
                                       onChange={getInputValue}
                                       placeholder={t('input_surname')}
                                />
                                {!!errors?.last_name && (
                                    <div className="input_group__error">{errors?.last_name}</div>
                                )}
                            </div>
                        </div>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_company')}</label>
                                <input type="text"
                                       value={personInfo?.company}
                                       name={'company'}
                                       disabled={true}
                                       onChange={getInputValue}
                                       placeholder={t('input_company')}
                                />
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_position')}</label>
                                <input type="text"
                                       value={personInfo?.position}
                                       name={'position'}
                                       onChange={(e) => onChangePersonInfo('position', e?.target?.value || '')}
                                       placeholder={t('input_position')}
                                />
                            </div>
                        </div>

                        <div className="input_group full">
                            <label htmlFor="">{t('input_notes')}</label>
                            <textarea value={personInfo?.note}
                                      name={'note'}
                                      onChange={(e) => onChangePersonInfo('note', e?.target?.value || '')}
                                      placeholder={t(config.productName)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ProfileContacts contactItems={contactItems}
                             setContactItems={setContactItems}
                             errors={errors}
                             setErrors={setErrors}
            />

            <div className="settings_tab">
                <Tabs
                    defaultActiveKey="0"
                    id="uncontrolled-tab-example">
                    <Tab eventKey="0"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                        {t('profile_info_title')}
                                 <InfoTooltip title={t('tooltip_info_title')}
                                              description={t('tooltip_info_description')}/>
                    </span>
                         }>
                        <ParticipationData avatar={personById?.avatar}
                                           userRoles={props.userRoles}/>
                    </Tab>
                    <Tab eventKey="1"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                        {t('profile_settings_title')}
                                 <InfoTooltip title={t('tooltip_settings_title')}
                                              description={t('tooltip_settings_description')}/>
                    </span>
                         }>
                        <div className="user-info-settings">
                            <p>{t('profile_settings_desc')}</p>
                            <div className="input_group">
                                <label htmlFor="">{t('input_login')}</label>
                                <input type="text"
                                       value={userInfo?.login}
                                       name={'login'}
                                       onChange={getInputValue}
                                       placeholder={t('input_login')}
                                       autoComplete={'off'}
                                />
                                {!!errors.login && (
                                    <div className="input_group__error">{errors.login}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_password')}</label>
                                <input
                                    type={`${passwordVisible ? "text" : "password"}`}
                                    value={userInfo?.password}
                                    name={'password'}
                                    onChange={getInputValue}
                                    placeholder={t('input_password')}
                                    autoComplete="new-password"
                                />
                                <EyeIcon className="input-icon"
                                         onClick={() => setPasswordVisible(!passwordVisible)}/>
                                {!!errors.password && (
                                    <div className="input_group__error">{errors.password}</div>
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>

            <Accordion defaultActiveKey="1" className={'contacts-wrapper'}>
                <Accordion.Item eventKey="0" className={'profile-page__contacts'}>
                    <Accordion.Header
                      className={`contact-header `}>
                        <ArrowDownIcon className={`arrow-icon`}/>
                        {t('signature_vks')}
                    </Accordion.Header>
                    <Accordion.Body>
                        <UserSignatures onAddSignature={(arr)=>onChangePersonInfo('signatures', arr)}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={'profile-page__contacts'}>
                    <Accordion.Header
                        className={`contact-header `}>
                        <ArrowDownIcon className={`arrow-icon`}/>
                        {t('profile_roles')}
                    </Accordion.Header>
                    <Accordion.Body>
                        <UserRoles rolesList={rolesList}
                                   toggleRole={toggleRole}
                                   userRole={userRole}
                                   isEdited={true}
                        />

                        <div className={'connect_person'}>
                            <span>{t('connect_person_roles')}</span>

                            <CombinePersonsDropdown user={personById}/>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {!!errors?.addUser && (
                <div className="input_group__error error_main">{errors?.addUser}</div>
            )}
            <div className="buttons-wrapper">
                <button className={`close_btn`} onClick={onClose}
                >
                    {t('button_cancel')}
                </button>

                <button
                    className={`save_btn`}
                    onClick={saveChanges}
                >
                    {isLoading ? <Loader size={20} color="white"/> : ''} {t('button_save_changes')}
                </button>
            </div>
        </div>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['videosList', 'personById', 'rolesList', 'userRoles','userProfile'])
};

const mapDispatchToProps = {
    AddUser,
    GetUsersPersons
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
