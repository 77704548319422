import React from 'react';
import './fuseSearchInput.scss';
import {CloseIcon, LoopIcon} from "../../../image";
import {Loader} from "../Loader";

function FuseSearchInput(props) {
	const {isLoader, placeholder, setSearchText, changeVideoList, handleBlur, searchText,value, onClear} = props;
	const handleChange = (event) => {
		const searchValue = event?.target?.value;

    setSearchText(searchValue);
	};

	return (
		<div className="container">
			<input
				className="container--input"
				value={value}
				placeholder={placeholder}
				onChange={handleChange}
				autoComplete="off"
				type="text"
				onBlur={handleBlur}
				onKeyDown={e => {
					e.key === 'Enter' && changeVideoList()
				}}
			/>
			{(value && onClear && !isLoader) ?
				<CloseIcon
					onClick={onClear}
					className="container--close"
				/>
				: null
			}
			{isLoader ?
				<Loader size={20}/>
				:
				<LoopIcon
					className="search__logo"
					onClick={changeVideoList}
				/>
			}
		</div>

	);
}

export default FuseSearchInput;
