import React from 'react';
import {Avatar} from "antd";
import {generateMediaUrl} from "../../../../../../utils/generateMediaUrl";
import {useSelector} from "react-redux";


const UsersGroupList = (props) => {
	const {users, isActiveUser} = props;
	const userProfile = useSelector(state => state.profile?.userProfile)

	function isCheckActiveUser(data) {
		return isActiveUser && (userProfile?.id === data?.user_id || userProfile?.attached_person?.id === data?.person_id)
	}

	return (
		<div
			{...props}
			className={`user-group__list ${props?.className}`}
		>
			{
				!!users?.length && users?.slice(0, 2)?.map((user, index) => (
					<Avatar
						key={index}
						src={generateMediaUrl(user?.avatar)}
						className={`user-group__list--avatar ${isCheckActiveUser(user) ? 'user-group__list--active' : ''}`}

						size={36}
					/>
				))
			}
				{users?.length > 2 ?
					<Avatar
						className="user-group__list--more"
						size={36}
					>
						+{users?.length - 2}
					</Avatar>
					: null
				}
		</div>
	);
};

export default UsersGroupList;