// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Import assets
import './section.scss';
import {DeletingIcon, EditIcon, EllipsisIcon} from "../../../../../image";

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {GetVideos, GetVideosSearch, DeleteChannel, GetChannelById} from "../../../../../redux/actions";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import ChannelsModal from "../../../../../shared/modals/channelModal/ChannelsModal";
import UserInfoItem from "../../../../../shared/features/userInfoItem";
import {hasPermission} from "../../../../../utils/helperFunctions";
import VideoBlock from "../VideoBlock/VideoBlock";
import ChainsDropDown from "../../../../../shared/features/Chains/ChainsDropDown/ChainsDropDown";
import {generateChannelsList} from "../../../../../shared/features/Chains/helpers";

function Section(props) {
    const {badge, list} = props;
    const {t} = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [activeChannel, setActiveChannel] = useState(undefined);
    const [activeChannelId, setActiveChannelId] = useState(undefined);
    const [showChannelCount, setShowChannelCount] = useState(false);
    const videosArr = list?.slice(0, 8);
    const mockupArr = Array.from({ length: 8 - (videosArr?.length || 0) });
    const chainsList = useSelector(state => state.chains?.chainsList || []);
    const channels = useSelector(state => state.channels?.channels || []);
    const channelsList = generateChannelsList(channels);

    useEffect(() => {
        // console.log('activeChannelId', activeChannelId);
        if (activeChannelId) {
            if (!showChannelCount) {
                props.GetChannelById(activeChannelId)
                    .then((res) => {
                        setActiveChannel(res)
                    })
            }
        } else {
            setActiveChannel(undefined)
            setActiveChannelId(undefined)
            setShowChannelCount(false)
        }
    }, [activeChannelId])

    function closeModal() {
        setShowModal(false)
    }

    function setChannelId(id) {
        setActiveChannelId(id)
    }

    function toggleChannelCount(isClosed) {
        setShowChannelCount(isClosed)
        isClosed && setActiveChannelId(undefined)
    }

    return (
        <div className={`channels__section ${!badge?.is_private ? '' : 'private'}`}>
            <div className="channels__section-header">
                <p className="channels__section-header__name">
                    <span className="name">{badge?.name}</span>
                    {!badge?.is_private &&
                        <DropdownMenu button={
                            <span
                                className="count"
                                onClick={() => {
                                    if(badge?.users_count > 0){
                                        setChannelId(badge?.id)
                                    }
                                }}
                            >
                                {badge?.users_count || 0} {t('participants')}
                            </span>
                        }
                                      closeAfterToggle={true}
                                      toggle={toggleChannelCount}
                                      className={`count-menu ${activeChannel?.managers?.length + activeChannel?.participants?.length
                                      < 7 ? '' : 'large'}`}
                                      contentWidth={
                                          activeChannel?.managers?.length + activeChannel?.participants?.length
                                          < 7 ? '317px' : '580px'
                                      }
                        >
                            {!!activeChannel?.managers?.length && <div className="content-item">
                                <div className="title">{t('channel_managers')}</div>
                                <div className="list">
                                    {
                                        activeChannel?.managers?.map(item => {
                                            return <UserInfoItem data={item}/>
                                        })
                                    }
                                </div>
                            </div>}
                            {!!activeChannel?.participants?.length && <div className="content-item">
                                <div className="title">{t('channel_members')}</div>
                                {
                                    !activeChannel?.all_users_are_participants ? <div className="list">
                                            {
                                                activeChannel?.participants?.map(item => {
                                                    return <UserInfoItem data={item}/>
                                                })
                                            }
                                        </div>
                                        : <div className="text">{t('all_users_system')}</div>
                                }
                            </div>}
                        </DropdownMenu>}
                    <DropdownMenu
                      className="chains-menu"
                      button={
                            <span className="chains-menu--count">
                                &nbsp;&bull;&nbsp;{badge?.chains?.length || 0} {t('Chains')}
                            </span>
                        }
                    >
                        <ChainsDropDown
                          chains={badge?.chains || []}
                          channel={badge}
                          isAll={true}
                          channelStyle={channelsList?.[badge?.id]}
                        />
                    </DropdownMenu>
                    {!badge?.is_private && (hasPermission('channels_update') || hasPermission('channels_delete'))
                      && <DropdownMenu button={
                          <EllipsisIcon className={'icon-menu'}/>}
                                       closeAfterToggle={true}
                                       className="actions-menu"
                                       contentWidth={'176px'}
                      >
                          {hasPermission('channels_update') && <div className={'menu-item'} onClick={(e) => {
                              e.stopPropagation()
                              // toggleUpdate()
                              setShowModal(true)
                          }}>
                              <EditIcon className={''}/> {t('button_edit')}
                          </div>}
                          {hasPermission('channels_delete') &&
                            <div className={'menu-item delete'} onClick={() => props.DeleteChannel(badge?.id)}>
                                <DeletingIcon className={''}/> {t('button_delete')}
                            </div>}
                      </DropdownMenu>}
                </p>
                 {list?.length > 6 && <div
                    className="channels__section-header__view-all"
                >
                    <Link to={`channels/${badge.id}`}>
                        {t('see_all')}&nbsp;
                        ({list?.length})
                    </Link>
                </div>}
            </div>
            <div
                className={`channels__section-videos`}
            >
                {!!list?.length ? <>
                        {
                            videosArr?.map((item) => {
                                return <VideoBlock key={item?.id}
                                                  badge={badge}
                                                  channelsList={channelsList}
                                                  chainList={chainsList}
                                                   isPreview={true}
                                                  routs={[
                                                      {
                                                          path: '/dashboard/videos/review',
                                                          name: t('menu_item_video')
                                                      },
                                                      {
                                                          path: '/dashboard/videos/review',
                                                          name: t('menu_item_review')
                                                      },
                                                      {
                                                          path: `/dashboard/videos/channels/${badge.id}`,
                                                          name: badge?.name
                                                      }
                                                  ]}
                                                  video={item}
                                                  isChaneType={true}
                                />
                            })
                        }
                        {videosArr?.length < 8 ? mockupArr.map(()=>(<div className="mockup"/>)) : null}
                    </>
                    : <div className={`channels__section-videos-empty`}>
                        {t('empty_list')}
                    </div>}
            </div>
            {!badge?.is_private && showModal && <ChannelsModal showModal={showModal}
                                                               channel={badge}
                                                               onHide={closeModal}/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'videosList',
        'videos',
        'isMobile',
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    DeleteChannel,
    GetChannelById
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
