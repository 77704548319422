// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";

// Import assets
import './channel-page.scss';

// Import Components
import VideoCard from "../card/VideoCard";
import {Breadcrumb} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    ChangeHeaderTitle,
    GetVideos,
    GetVideosSearch,
    GetPersons,
    DeleteChannel,
    GetChannelById, GetVideosService
} from "../../../../../redux/actions";
import {GetChannels} from "../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {DeletingIcon, EditIcon, EditNewIcon, EllipsisIcon} from "../../../../../image";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import ChannelsModal from "../../../../../shared/modals/channelModal/ChannelsModal";
import ChainsDropDown from "../../../../../shared/features/Chains/ChainsDropDown/ChainsDropDown";
import VideoBlock from "../VideoBlock/VideoBlock";
import {generateChannelsList} from "../../../../../shared/features/Chains/helpers";
import {getChains} from "../../../../../redux/actions/chainsActions";
import {hasPermission} from "../../../../../utils/helperFunctions";
import UserInfoItem from "../../../../../shared/features/userInfoItem";
import {Loader} from "../../../../../shared/features/Loader";
import {VIDEOS_CONSTS} from "../../../../../redux/constants";
import {Popover} from "antd";

function ChannelsPage(props) {
    const location = useLocation();
    const params = useParams();
    const {t} = useTranslation();
    const channels = useSelector(state => state.channels?.channels);
    const channelById = useSelector(state => state.channels?.channelById);
    const [isLoading, setIsLoading] = useState(false);
    const channelsUserCount = channelById?.users_count || 0;
    const chainsCount = channelById?.chains?.length || 0;
    const [showChannelModal, setShowChannelModal] = useState(false);
    const [editingChannel, setEditingChannel] = useState(false);
    const chainsList = channelById?.chains || [];
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const videosChannel = useSelector(state => state.videos?.videosByChannel?.results || []);
    const channelsList = generateChannelsList(channels);
    const channelName = channels?.find(i => i?.id === Number(params?.id))?.name;

    const getContent = async () => {
        try{
            setIsLoading(true);
            const response = await dispatch(GetVideosService({
                limit: 1000,
                channel_id: params?.id
            }, true));
            dispatch({
                type: VIDEOS_CONSTS.GET_VIDEOS_CHANNEL,
                payload: response
            })
            if(params?.id) props.GetChannelById(params?.id)
            const title = t('menu_item_review')
            await props.ChangeHeaderTitle(title)
            // TODO Если все ок, то со временем удалить
            // await props.GetPersons({
            //     status: 'known',
            //     limit: 1000
            // })
            setIsLoading(false);
        }catch (e) {
            setIsLoading(false);
            console.warn(e)
        }

    }

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
        getContent();
        return ()=>{
            dispatch({
                type: VIDEOS_CONSTS.CLEAR_VIDEOS_CHANNEL,
                payload: {}
            })
        }
    }, []);

    function closeChannelsModal() {
        setShowChannelModal(false)
    }

    return <div className={`main-container channels-container ${props.isFullWidth ? '' : 'full'}`}>
        <div className="channels-page">
            {isLoading ?
              <div className="loader-wrap">
                  <Loader siz={50}/>
              </div>
              :
              <div>
            <div className="channels-page__wrap">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={'/dashboard/videos/review'}>{t('menu_item_video')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={'/dashboard/videos/review'}>{t('menu_item_review')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <div className="channels-page__name">
                        {channelName}
                    </div>
                </Breadcrumb.Item>
            </Breadcrumb>
                <div className="channels-page__info">
                  <DropdownMenu
                    button={
                      <span className="channels-info--text" >
                        {channelsUserCount} {t('participants')}
                      </span>
                    }
                    className={
                      `count-menu ${channelById?.managers?.length + channelById?.participants?.length
                                < 7 ? '' : 'large'}`
                  }
                    contentWidth={
                      channelById?.managers?.length + channelById?.participants?.length
                          < 7 ? '317px' : '580px'
                    }
                  >
                      {!!channelById?.managers?.length && <div className="content-item">
                          <div className="title">{t('channel_managers')}</div>
                          <div className="list">
                              {
                                  channelById?.managers?.map(item => {
                                      return <UserInfoItem data={item}/>
                                  })
                              }
                          </div>
                      </div>}
                      {!!channelById?.participants?.length && <div className="content-item">
                          <div className="title">{t('channel_members')}</div>
                          {
                              !channelById?.all_users_are_participants ? <div className="list">
                                    {
                                        channelById?.participants?.map(item => {
                                            return <UserInfoItem data={item}/>
                                        })
                                    }
                                </div>
                                : <div className="text">{t('all_users_system')}</div>
                          }
                      </div>}
                  </DropdownMenu>
                    <Popover
                      arrow={false}
                      trigger='click'
                      placement="rightTop"
                      overlayClassName='popover_manage-chain'
                      content={
                          <ChainsDropDown
                            chains={channelById?.chains || []}
                            channel={channelById}
                            channelStyle={channelsList?.[channelById?.id]}
                          />
                      }
                    >
                        <span className="channels-info--text">
                                &nbsp;&bull;&nbsp;{chainsCount} {t('Chains')}
                            </span>
                    </Popover>

                </div>
                {(hasPermission('channels_update') || hasPermission('channels_delete')) ?
                <DropdownMenu
                              closeAfterToggle={true}
                              className="actions-menu"
                              contentWidth={'176px'}
                >
                    {hasPermission('channels_update') && <div className={'menu-item'} onClick={(e) => {
                        e.stopPropagation()
                        // toggleUpdate()
                        setShowModal(true)
                    }}>
                        <EditIcon className={''}/> {t('button_edit')}
                    </div>}
                    {hasPermission('channels_delete') &&
                      <div className={'menu-item delete'} onClick={() => props.DeleteChannel(channelName?.id)}>
                          <DeletingIcon className={''}/> {t('button_delete')}
                      </div>}
                </DropdownMenu>
                : null}
                {/*<DropdownMenu*/}
                {/*  className="chains-menu"*/}
                {/*>*/}
                {/*    <ChainsDropDown*/}
                {/*      chains={channelById?.chains || []}*/}
                {/*      channel={channelById}*/}
                {/*    />*/}
                {/*</DropdownMenu>*/}
            </div>
            <div
                className={`channels__section-videos`}
            >
                {!!videosChannel?.length ? <>
                        {
                            videosChannel?.map((item) => {
                                return(
                                    <VideoBlock key={item?.id}
                                                badge={channelById}
                                                channelsList={channelsList}
                                                chainList={chainsList}
                                                isPreview={true}
                                                routs={[
                                                    {
                                                        path: '/dashboard/videos/review',
                                                        name: t('menu_item_video')
                                                    },
                                                    {
                                                        path: '/dashboard/videos/review',
                                                        name: t('menu_item_review')
                                                    },
                                                    {
                                                        path: `/dashboard/videos/channels/${params.id}`,
                                                        name: channelName?.name
                                                    }
                                                ]}
                                                video={item}
                                    />
                                  )
                            })
                        }
                    </>
                    : <div className={`channels__section-videos-empty`}>
                        {t('empty_list')}
                    </div>}
            </div>
              </div>
            }
        </div>
        {showChannelModal &&
              <ChannelsModal
                showModal={showChannelModal}
                channel={ editingChannel}
                createChannel={false}
                onHide={closeChannelsModal}
              />
        }
        { showModal &&
          <ChannelsModal
            showModal={showModal}
            channel={channelName}
            onHide={()=>setShowModal(false)}
          />}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'channels',
        'videosList',
        'isFullWidth',
        'userRoles'
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    GetChannels,
    ChangeHeaderTitle,
    GetPersons,
    DeleteChannel,
    getChains,
    GetChannelById
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsPage);
