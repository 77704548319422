// Import packages
import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";

// Import assets
import './user-videos-page.scss';

// Import Components
import VideoCard from "../Channels/card/VideoCard";
import {Breadcrumb} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {ChangeHeaderTitle, GetVideos, GetVideosSearch, GetPersons} from "../../../../redux/actions";
import {GetChannels} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";

function UserVideosPage(props) {
    const location = useLocation();
    const params = useParams();
    const {t} = useTranslation();

    // useLayoutEffect(()=>{
    //     props.ClearVideoList()
    // },[])

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
        props.GetVideos({
            limit: 1000,
            filters: location?.state?.personId ? [
                {
                    firstColumnVal: 'and',
                    secondColumnVal: 'staff',
                    thirdColumnVal: 'present',
                    fourthColumnVal: location?.state?.personId
                }
            ] : []
        })
        const title = t('menu_item_review')
        props.ChangeHeaderTitle(title)
    }, [location?.state?.personId]);

    let channelsList = {}

    const colors = {
        '1': {
            color: '#4F40F7',
            bgColor: '#4F40F719',
        },
        '2': {
            color: '#2877D5',
            bgColor: '#2877D419',
        },
        '3': {
            color: '#F7404B',
            bgColor: '#F7404B19',
        },
    }

    props?.channels?.length && props.channels.forEach(item => {
        channelsList = {
            ...channelsList,
            [item.id]: {
                name: item.name,
                color: colors[item.id]?.color,
                bgColor: colors[item.id]?.bgColor,
            }
        }
    })

    return !!props?.videosList?.results?.length && <div className={`main-container user-video-container ${props.isFullWidth ? '' : 'full'}`}>
        <div className="user-videos-page">

            <Breadcrumb>
                {
                    !!location?.state?.routs?.length && location?.state?.routs?.map(item => {
                        return   <Breadcrumb.Item key={item?.name}
                                                  active={item?.active}
                                                  className={item?.active ? 'breadcrumb_name' : ''}>
                            <Link to={item?.path}>{item?.name}</Link>
                        </Breadcrumb.Item>
                    })
                }
            </Breadcrumb>

            <div
                className={`user-videos-page__videos`}
            >
                {!!props?.videosList?.results?.length ? <>
                        {
                            props?.videosList?.results?.map((item) => {
                                return <VideoCard key={item?.id}
                                                  badge={props?.channels?.find(i => i?.id === Number(params?.id))}
                                                  channelsList={channelsList}
                                                  routs={location?.state?.routs}
                                                  video={item}
                                                  isPreview={true}
                                />
                            })
                        }
                    </>
                    : <div className={`channels__section-videos-empty`}>
                        {t('empty_list')}
                    </div>}
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'channels',
        'videosList',
        'isFullWidth',
        'userRoles'
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    GetChannels,
    ChangeHeaderTitle,
    GetPersons,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVideosPage);
