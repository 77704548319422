// Import packages
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Draggable from 'react-draggable';
import {useLocation, useParams} from "react-router-dom";

// Import assets
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {
    DownIcon, MarkIcon,
    PlayerPauseIcon,
    CloseIcon,
    PlayerPlayIcon, Second15Icon, Second30Icon, UsersIcon, MarkFillIcon, ArrowDownIcon,
} from "../../../../../image";

//Import utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {generateMediaUrl} from "../../../../../utils/generateMediaUrl";

//Import Component
import UserInfoModal from "../../Users/modals/userInfoModal/UserInfoModal";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import {getAuthorName, getTimeString} from "../../../../../utils/helperFunctions";
import {EventColors, GetVideoById, UserDetachVideoService} from "../../../../../redux/actions";
import {MAX_ZOOM, MIN_ZOOM} from "../../../../../utils/constants";
import RangeSlider from "react-bootstrap-range-slider";
import {useTranslation} from "react-i18next";
import {Form, Toast} from "react-bootstrap";
import InfoTooltip from "../../Profile/tooltip";
import {Loader} from "../../../../../shared/features/Loader";
import i18n from "i18next";

function NewPlayer(props) {
    const {
        videoById, videoPlayer, isPlayed, setIsPlayed, videoPlayerCurrentTime,
        videoPlayerCurrentTimeScroll, setVideoPlayerCurrentTimeScroll, changeVideoTime, playerHeight,
        videoTimelineScale, setVideoTimelineScale, changeVideoSpeed, eventsColor, showSmallVideo, protocolList,onScroll
    } = props
    const params = useParams();
    const location = useLocation();

    const timeline = useRef();
    const events = useRef();

    const {t,i18n} = useTranslation();

    const [timelineOffsetLeft, setTimelineOffsetLeft] = useState(0);
    const [activeCursor, setActiveCursor] = useState(false);
    const [activeScroll, setActiveScroll] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [activeTimeline, setActiveTimeline] = useState(true)
    const [videoSpeed, setVideoSpeed] = useState(1)
    const [eventsList, setEventsList] = useState([])
    const [zoom, setZoom] = useState(MIN_ZOOM)
    const [timelineDefWidth, setTimelineDefWidth] = useState(undefined)
    const [distance, setDistance] = useState(0);
    const [checkedPersons, setCheckedPersons] = useState({});
    const [checkedAll, setCheckedAll] = useState(true);
    const [togglingMark, setTogglingMark] = useState(false);
    const [cursorTime, setCursorTime] = useState(undefined);
    const [isLoading, setIsLoading] = useState({isShow: false, id: 0});
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});

    const oneSecondToPixels = videoById?.duration ? timelineDefWidth / (videoById?.duration) + zoom : 1;
    const speedList = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
    const personsColors = videoById?.persons?.length ? videoById?.persons.map((item, index) => {
        return {
            person_id: item?.id,
            color: eventsColor?.colors?.[index]
        }
    }) : []

    /* Timeline body segment width  */
    const getTimelineBodySegmentWidth = (startTime, endTime) => {
        return (endTime - startTime) * oneSecondToPixels;
    }

    let timelineWidth = 0;
    videoById?.events.filter(item => item?.event_type === 'talk').forEach(event => {
        timelineWidth = timelineWidth + getTimelineBodySegmentWidth(event.start_time, event.end_time)
    })

    const width = zoom > MIN_ZOOM ? timelineWidth : timelineDefWidth

    useEffect(() => {
        setVideoTimelineScale({
            ...videoTimelineScale,
            minScaleX: timeline?.current?.offsetWidth / (timeline?.current?.offsetWidth * 15),
            scaleX: timeline?.current?.offsetWidth / (timeline?.current?.offsetWidth * 15),
        })
        setTimelineDefWidth(timeline.current?.offsetWidth)
        if (eventsColor?.id !== videoById?.id) {
            props.EventColors({
                id: videoById?.id,
                length: videoById?.persons?.length
            })
        }
    }, [videoById?.id, showModal, videoById?.persons?.length])

    useEffect(() => {
        setEventsList(setEventsData())
    }, [width, protocolList, videoById])

    useEffect(() => {
        /* Hide timeline scroll if video playing or show if video stopped */
        syncCursorWithCurrentVideoTime();
    }, [videoPlayerCurrentTime, isPlayed, activeTimeline])

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const paramsTime = urlParams.get('t')
        changeVideoTime(paramsTime ? paramsTime : params?.time)
    }, [params?.time])

    function setEventsData() {
        let markers = {};
        let interval = Number(videoById?.duration) / Math.floor(width / 70)
        const protocols = protocolList?.map(item => item?.end_time && item?.end_time === 0 ? item : {
            ...item,
            end_time: videoById?.events.find(i => Number(i?.start_time) === Number(item?.start_time))
                ? videoById?.events.find(i => Number(i?.start_time) === Number(item?.start_time))?.end_time
                : item?.start_time + 10
        })

        let filteredList = [...protocols, ...videoById?.events]

        filteredList?.filter(i => (i.isMarked || i.marked) && i.event_type !== 'person').forEach(item => {
            const section = Math.floor(Number(item?.start_time) / interval) || 0
            markers[section] = markers[section]?.length ? markers[section].sort() : [];
            !markers[section]?.includes(Number(item.start_time)) && markers[section].push(Number(item.start_time))
        })
        return filteredList?.map(item => {
            const section = Math.floor(Number(item?.start_time) / interval)

            let sortedList = markers?.[section]?.sort(function (a, b) {
                return a - b;
            });
            return {
                ...item,
                showMark: markers?.[section]?.[0] === Number(item?.start_time),
                markCount: markers?.[section]?.length,
                markIndex: section,
                topIndex: markers?.[section]?.findIndex(i => i === Number(item.start_time)),
                showCount: !!markers?.[section]?.length,
                isMarked: item.isMarked,
                position: sortedList?.findIndex(i => Number(i) === Number(item.start_time))
            }
        })
    }

    const playOn = () => {
        setIsPlayed(true)
        videoPlayer.current.play();
        changeVideoPlayerCurrentTimeAfterScrollCallback(videoPlayerCurrentTimeScroll)
    }

    const playOff = () => {
        setIsPlayed(false)
        videoPlayer.current.pause();
    }

    /* Sync cursor with current video time */
    const syncCursorWithCurrentVideoTime = () => {
        const time = videoPlayerCurrentTime * oneSecondToPixels;
        if (activeTimeline && time <= width && time > 0) {
            setVideoPlayerCurrentTimeScroll(videoPlayerCurrentTime)
            setTimelineOffsetLeft(time)
        }
        timeline?.current?.scrollTo({
            left: time - distance,
        });
    }

    /* Change video playback from Player Child Component */
    const changeVideoPlayerCurrentTimeAfterScrollCallback = (videoPlayerCurrentTime) => {
        videoPlayer.current.currentTime = videoPlayerCurrentTime;
    }

    /* Timeline scale */
    const zoomIn = () => {
        setZoom(zoom >= MAX_ZOOM ? zoom : zoom + 0.25)
        syncCursorWithCurrentVideoTime();
    }

    const zoomOut = () => {
        setZoom(zoom === MIN_ZOOM ? zoom : zoom - 0.25)
        syncCursorWithCurrentVideoTime();
    }
    const changeZoom = (e) => {
        setZoom(Number(e))
        syncCursorWithCurrentVideoTime();
    }


    const drugHandler = (e, data) => {
        changeVideoTime(data.x / oneSecondToPixels)
        setDistance(data.x - timeline?.current.scrollLeft)
    }

    const onTimeLineScroll = () => {
        if (isPlayed) {
            return;
        }
        setActiveScroll(true)
    }
    const onTimeLineClick = (e) => {
        if (!togglingMark) {
            const scrollLeft = timeline.current.scrollLeft;
            const mouseX = (e.clientX - timeline.current.getBoundingClientRect().left) + scrollLeft;
            changeVideoTime(mouseX / oneSecondToPixels)

            // timeline?.current && (timeline.current.style.overflowX = 'scroll');
            if(mouseX < width){
                setTimelineOffsetLeft(mouseX)
                setActiveCursor(true)
                setActiveTimeline(false)
            }

        }
    }

    const mouseTimelineUp = () => {
        if (activeCursor && !activeScroll) {
            changeVideoTime(timelineOffsetLeft / oneSecondToPixels);
            setDistance(timelineOffsetLeft - timeline?.current.scrollLeft);
            setActiveTimeline(true);
        }

        setActiveCursor(false);
        setActiveScroll(false);
    }

    const onMoveTimeline = (e) => {
        const x = e.clientX - timeline.current.getBoundingClientRect().left
        // console.log('onMoveTimeline', getTimeString(x / oneSecondToPixels));
        setCursorTime(getTimeString(x / oneSecondToPixels))
        if (activeCursor) {
            const scrollLeft = timeline.current.scrollLeft;
            const mouseX = e.clientX - timeline.current.getBoundingClientRect().left + scrollLeft;
            // console.log(mouseX, 'mouseX');
            activeCursor && setTimelineOffsetLeft(mouseX);
        }
    }

    function toggleUserModal(person) {
        setShowModal(!showModal);
        setCurrentUser(person);
    }

    function changeSpeed(speed) {
        changeVideoSpeed(speed);
        setVideoSpeed(speed);
    }

    function filterPersonsList(list) {
        const keys = []
        Object.entries(list)?.forEach(([_, val]) => {
            val.checked && keys.push(val.data.id)
        })
        const filteredList = []
        setEventsData()?.forEach(i => {
            keys?.includes(i?.person_id) && filteredList.push(i)
        })

        return filteredList
    }

    function selectPerson(list) {
        setEventsList(filterPersonsList(list))
    }

    function checkTags(e, data) {
        let channels = {
            ...checkedPersons,
            [data?.id]: {
                data,
                checked: e?.target?.checked,
            }
        }
        // let x = Object.entries(channels)?.filter(([_, val]) => val.checked)
        // setCheckedAll(false)
        setCheckedPersons(channels)
        setCheckedAll(Object.values(channels)?.every(element => element?.checked))
        selectPerson(channels)
    }

    function checkAll() {
        let channels = {}
        videoById?.persons?.forEach((data) => {
            channels = {
                ...channels,
                [data?.id]: {
                    data,
                    checked: !checkedAll,
                }
            }
        })
        setCheckedPersons(channels)
        setCheckedAll(!checkedAll)
        selectPerson(channels)
    }

    // console.log('checkedPersons', checkedPersons);

    function showMarks(e, event) {
        e.stopPropagation()
        let list = eventsList?.map(item => {
            return item.markIndex === event.markIndex && item?.isMarked ? {
                ...item,
                showTop: true,
                showCount: false,
            } : {
                ...item,
                showTop: false,
                showCount: true,
            }
        })

        setEventsList(list)
    }

    function hideMarks(e, event) {
        e.stopPropagation()
        let list = eventsList?.map(item => {
            return item.markIndex === event.markIndex && item?.isMarked ? {
                ...item,
                showTop: false,
                showCount: true,
            } : item
        })
        changeVideoTime(event?.start_time)
        setEventsList(list)
    }
    const onDetachUser = async (person_id) => {
        try {
            setIsLoading({isShow: true, id: person_id});
            await props.UserDetachVideoService({
                video_id: Number(videoById?.id),
                person_id: person_id || null,
            });
            await props.GetVideoById(Number(videoById?.id));
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t('unpin_user_video_success')
            });
            setIsLoading({isShow: false, id: 0});
        } catch (e) {
            console.log(e);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text: t('unpin_user_video_error')
            });
            setIsLoading({isShow: false, id: 0});
        }
    }

    useEffect(() => {
        let channels = {};
        !!videoById?.persons?.length && videoById?.persons?.forEach((data) => {
            channels = {
                ...channels,
                [data?.id]: {
                    data,
                    checked: true,
                }
            }
        })
        setCheckedPersons(channels)
        setCheckedAll(true)
    }, []);

    let checkedPersonsCount = Object.values(checkedPersons)?.filter(i => i.checked)?.length

    return <div className="video_details__player" ref={playerHeight}>
        <div className="video_details__player__timeline-wrapper custom-scroll" id={'scrolled-element'}>
            <div className="timeline-marks"
                 ref={events}
                 onClick={(e) => {
                     e.stopPropagation()
                 }}
                 style={{
                     width: zoom > MIN_ZOOM ? `${timelineWidth}px` : `${timelineDefWidth}px`
                 }}>
                {
                    !!eventsList?.length && eventsList?.map((event) => {
                        const currentItem = event?.start_time <= videoPlayerCurrentTime && event?.end_time > videoPlayerCurrentTime
                        return !!event?.text?.length
                            && <div className="talk-block"
                                    key={event?.id}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: event.start_time * oneSecondToPixels,
                                    }}
                            >
                                {
                                    event?.showTop && <>
                                        {currentItem ? <MarkFillIcon className={`mark-icon-top`}
                                                                     onClick={() => changeVideoTime(event?.start_time)}
                                                                     onMouseOver={() => setTogglingMark(true)}
                                                                     onMouseLeave={() => setTogglingMark(false)}
                                                                     style={{
                                                                         position: "absolute",
                                                                         width: '16px',
                                                                         height: '16px',
                                                                         top: "-66px",
                                                                         left: `${(event?.topIndex || 0) * 16}px`,
                                                                         // transform: 'translateX(-100%)',
                                                                         zIndex: '100',
                                                                         cursor: 'pointer'
                                                                     }}/>
                                            : <MarkIcon className={`mark-icon-top`}
                                                        onClick={() => {
                                                            changeVideoTime(event?.start_time)
                                                        }}
                                                        onMouseOver={() => setTogglingMark(true)}
                                                        onMouseLeave={() => setTogglingMark(false)}
                                                        style={{
                                                            position: "absolute",
                                                            width: '16px',
                                                            height: '16px',
                                                            top: "-66px",
                                                            left: `${(event?.topIndex || 0) * 16}px`,
                                                            // transform: 'translateX(-100%)',
                                                            zIndex: '100',
                                                            cursor: 'pointer'
                                                        }}/>}
                                    </>
                                }
                                {
                                    event?.showMark && <>
                                        {currentItem ? <MarkFillIcon className={`mark-icon`}
                                                                     onClick={(e) => hideMarks(e, event)}
                                                                     onMouseOver={() => setTogglingMark(true)}
                                                                     onMouseLeave={() => setTogglingMark(false)}
                                                                     style={{
                                                                         position: "absolute",
                                                                         width: '16px',
                                                                         height: '16px',
                                                                         top: "-48px",
                                                                         left: 0,
                                                                         zIndex: '100',
                                                                         cursor: 'pointer'
                                                                     }}/>
                                            : <MarkIcon className={`mark-icon`}
                                                        onClick={(e) => hideMarks(e, event)}
                                                        onMouseOver={() => setTogglingMark(true)}
                                                        onMouseLeave={() => setTogglingMark(false)}
                                                        style={{
                                                            position: "absolute",
                                                            width: '16px',
                                                            height: '16px',
                                                            top: "-48px",
                                                            left: 0,
                                                            zIndex: '100',
                                                            cursor: 'pointer'
                                                        }}/>}
                                        {event?.showCount && event?.markCount  && <span style={{
                                            position: "absolute",
                                            top: "-50px",
                                            left: '16px',
                                            zIndex: '100',
                                            cursor: 'pointer',
                                            color: '#9898A6',
                                            fontSize: '12px'
                                        }}
                                                                                           onClick={(e) => showMarks(e, event)}
                                                                                           onMouseOver={() => setTogglingMark(true)}
                                                                                           onMouseLeave={() => setTogglingMark(false)}>
                                           ({event?.markCount})</span>}
                                    </>
                                }
                            </div>
                    })
                }
            </div>
            <div className="timeline" ref={timeline}
                 style={{overflow: 'auto'}}
            >
                <div style={{
                    width: zoom > MIN_ZOOM ? `${timelineWidth}px` : `${timelineDefWidth}px`,
                    // position: 'relative'
                }}
                     onMouseDown={onTimeLineClick}
                     onMouseMove={onMoveTimeline}
                     onMouseUp={mouseTimelineUp}
                     onScroll={onTimeLineScroll}
                >
                    <Draggable
                        axis="x"
                        handle=".handle"
                        defaultPosition={{x: 0, y: 0}}
                        position={{x: timelineOffsetLeft <= width ? timelineOffsetLeft : width, y: 0}}
                        bounds={{left:0, right: width - 18}}
                        scale={1}
                        allowAnyClick={false}
                        onStop={drugHandler}
                    >
                        <div className="handle timeline__current-time-vert-line"/>
                    </Draggable>

                    <div className="timeline__control"
                         style={{
                             width: zoom > MIN_ZOOM ? `${timelineWidth}px` : `${timelineDefWidth}px`,
                             backgroundImage: "unset",
                         }}>
                    </div>
                    {
                        !!videoById?.chapter_list?.length && videoById?.chapter_list?.map((chapter, index) => {
                            const startPosition = Number(chapter?.start_time * oneSecondToPixels)
                            const x = Number(timelineOffsetLeft) - startPosition
                            const indexEnd = chapter?.start_time.length - 1;
                            const width = getTimelineBodySegmentWidth(chapter?.start_time, chapter?.end_time) - (index !== indexEnd ?  8 : 0);

                            return <div className="talk-block chapter"
                                        key={chapter?.id}
                                        style={{
                                            width: `${getTimelineBodySegmentWidth(chapter?.start_time, chapter?.end_time) -8}px`,
                                            position: "absolute",
                                            borderRadius: '4px',
                                            top: '10px',
                                            left: chapter?.start_time * oneSecondToPixels,
                                            display: 'inline-block',
                                            backgroundImage: `linear-gradient(to right,#1ABB78  ${x >= (chapter?.start_time && x <= chapter?.end_time) ? width : x}px,#2222221a  ${x}px 100%)`,
                                            zIndex: '10'
                                        }}
                            >

                                {/*<div className="block">*/}
                                {/*    <div className="chapter-info">*/}
                                {/*        <span>{`Глава ${index + 1}: ${chapter?.text}`}</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <InfoTooltip placement={'top'}
                                             className={'custom-tooltip'}
                                             customContent={<>
                                                 <span className={'tooltip-item text'}>{`${i18n.language === 'ru' ? 'Глава' : 'Chapter'} ${index + 1}: ${chapter?.text}`}</span>
                                                 <span className={'tooltip-item time'}>{`${cursorTime}`}</span>
                                             </>}
                                             elem={<div className="block"/>}
                                />

                            </div>
                        })
                    }
                    {/*<div className="timeline-time"*/}
                    {/*style={{*/}
                    {/*    left: videoPlayerCurrentTime * oneSecondToPixels,*/}
                    {/*}}>*/}
                    {/*    {getTimeString(videoPlayerCurrentTime)}*/}
                    {/*</div>*/}
                </div>

                <div className="timeline-events"
                     ref={events}
                     onClick={(e) => {
                         e.stopPropagation()
                     }}
                     onMouseDown={onTimeLineClick}
                     onMouseUp={mouseTimelineUp}
                     style={{
                         width: zoom > MIN_ZOOM ? `${timelineWidth}px` : `${timelineDefWidth}px`
                     }}>
                    {
                        !!eventsList?.length && eventsList?.map((event) => {
                            return event?.event_type === 'talk'
                                && <div className="talk-block"
                                        key={event?.id}
                                        style={{
                                            width: `${getTimelineBodySegmentWidth(event.start_time, event.end_time)}px`,
                                            position: "absolute",
                                            height: '10px',
                                            borderRadius: '4px',
                                            top: 0,
                                            left: event.start_time * oneSecondToPixels,
                                            display: 'inline-block',
                                            backgroundColor: personsColors.find(item => item?.person_id === event?.person_id)?.color
                                        }}
                                >
                                    <div className="block"/>
                                </div>
                        })
                    }
                </div>
            </div>

            <div className={'timeline-time'}>
                <span>{getTimeString(videoPlayerCurrentTime)}</span>
                {videoById?.duration && <span>{getTimeString(videoById?.duration)}</span>}
            </div>

            <div className="controls-wrapper" onClick={(e) => e.stopPropagation()}>
                <div className="speakers">
                    <DropdownMenu
                      button={
                            <button className="speakers-btn">
                                <UsersIcon className="speakers-icon"/>
                                    {
                                        // !checkedAll ? getAuthorName(checkedPersons[0]?.data) :
                                        <>
                                            {t('all_speakers')} ({checkedPersonsCount})
                                        </>
                                    }
                                <DownIcon className="speakers-arrow"/>
                            </button>
                        }
                        placement={!showSmallVideo && 'top'}
                        closeAfterToggle={false}
                        className={'speakers-menu'}
                        contentWidth={'370px'}
                    >
                      <div className="speakers-menu__header">
                            <Form className="check-icon">
                                <Form.Check
                                    checked={checkedAll}
                                    type={'checkbox'}
                                    onChange={checkAll}
                                />
                            </Form>
                            <div className="" onClick={() => selectPerson([])}>
                                <UsersIcon className="speakers-icon"/>
                                {t('all_speakers')}
                            </div>
                            <div className="count">{videoById?.persons?.length} {t('speakers')}</div>
                        </div>
                        <Form onWheel={(e)=>e.stopPropagation()}>
                            {
                                !!videoById?.persons?.length && videoById?.persons?.map((item, index) => {
                                    const data = {
                                        ...item,
                                        person_id: item.id
                                    }
                                    delete data.id
                                    return <div className={`video_details__player__persons__item`}
                                                key={item?.id}>
                                        <Form.Check
                                            type={'checkbox'}
                                            checked={checkedPersons?.[item?.id]?.checked}
                                            onChange={(e) => {
                                                checkTags(e, item)
                                            }}
                                        />
                                        <img
                                            src={generateMediaUrl(item?.image_path)}
                                            alt=""
                                            className="video_details__player__persons__item__img"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleUserModal(data)
                                            }}
                                        />
                                        <div className="video_details__player__persons__item__info"
                                             onClick={(e) => {
                                                 e.stopPropagation();
                                                 toggleUserModal(data)
                                             }}>
                                            <div className="">
                                                <div>{getAuthorName(item,null, i18n.language)}</div>
                                                <span className="person_id">id-{item?.id}</span>
                                            </div>

                                            <div className="time-wrapper">
                                                <span>{item?.speech_time}</span>
                                                <span className="percent" style={{
                                                    backgroundColor: eventsColor?.colors?.[index]
                                                }}>{item?.speech_percentage}%</span>
                                            </div>
                                        </div>
                                        {((item?.image_path || item?.image_path !== "None") && item?.first_name) ?
                                            <InfoTooltip
                                                className={[((!item?.image_path || item?.image_path === "None") && !item?.first_name) ? "icon-disabled" : "", "video_details__player__persons__item-delete"].join(' ')}
                                                placement={'left'}
                                                show={false}
                                                customContent={
                                                    <p>{t('unpin_user_video')}</p>
                                                }
                                                elem={
                                                    <Fragment>
                                                        {(isLoading.isShow && isLoading.id === item?.id) ?
                                                          <Loader size={20}/>
                                                          :
                                                          <CloseIcon onClick={(e) => {
                                                              e.stopPropagation();
                                                              if(!isLoading.isShow && !!item?.image_path && item?.image_path !== "None") {
                                                                  onDetachUser(item.id)
                                                              }
                                                          }}
                                                          />
                                                        }
                                                    </Fragment>
                                                }
                                            />
                                            :
                                          <div className="icon-disabled video_details__player__persons__item-delete">
                                              {(isLoading.isShow && isLoading.id === item?.id) ?
                                                <Loader size={20}/>
                                                :
                                                <CloseIcon onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                />
                                              }
                                          </div>
                                        }

                                        {/*<EllipsisIcon className="video_details__player__persons__item__more"/>*/}
                                    </div>
                                })
                            }
                        </Form>
                    </DropdownMenu>
                </div>
                <div className="controls">
                    <button className="controls__left control_btn">
                        <Second15Icon onClick={() => {
                            changeVideoTime(videoPlayerCurrentTime > 15 ? videoPlayerCurrentTime - 15 : 0)
                        }}/>
                    </button>
                    <button className="controls__play control_btn"
                            onClick={isPlayed ? playOff : playOn}>
                        {
                            isPlayed ? <PlayerPauseIcon/> : <PlayerPlayIcon/>
                        }
                    </button>
                    <button className="controls__left control_btn">
                        <Second30Icon onClick={() => {
                            changeVideoTime(videoById?.duration - videoPlayerCurrentTime > 30 ? videoPlayerCurrentTime + 30 : videoById?.duration)
                        }}/>
                    </button>
                    <DropdownMenu button={
                        <span className="speed-icon">
                            {videoSpeed}x
                        </span>
                    }
                                  closeAfterToggle={true}
                                  placement={!showSmallVideo && 'top'}
                                  className={'speed-menu'}
                                  contentWidth={'110px'}>
                        {
                            speedList.map(s => {
                                return <div className={`speed-menu__item ${s === videoSpeed ? 'active' : ''}`}
                                            onClick={() => changeSpeed(s)}
                                            key={s}>
                                    {s}x
                                </div>
                            })
                        }
                    </DropdownMenu>
                </div>
                <div className="zoom-wrap">
                    <button className={`zoom-wrap__out ${zoom === MIN_ZOOM ? 'disabled' : ''}`}
                            onClick={zoomOut}/>
                    <RangeSlider
                        min={MIN_ZOOM}
                        max={MAX_ZOOM}
                        tooltip={'off'}
                        value={zoom}
                        step={0.25}
                        size={'sm'}
                        onChange={changeEvent => changeZoom(changeEvent.target.value)}
                    />
                    <button className={`zoom-wrap__in ${zoom === MAX_ZOOM ? 'disabled' : ''}`}
                            onClick={zoomIn}/>
                </div>
            </div>
            <div className={[showSmallVideo ? 'btn-scroll--hidden' : '', 'btn-scroll'].join(' ')}>
                <InfoTooltip
                    title={t('go_to_work_with_videos')}
                    placement={'top'}
                    elem={
                        <div
                            className="btn-scroll--btn"
                            onClick={onScroll}
                        >
                            <ArrowDownIcon/>
                        </div>
                    }
                />
            </div>
        </div>
        {showModal && <UserInfoModal show={showModal}
                                     closeModal={toggleUserModal}
                                     user={currentUser}
                                     />}
        <Toast
          className='toaster'
          bg={toastInfo.type} onClose={() => setToastInfo({isShow: false})}
          show={toastInfo.isShow}
          delay={3000}
          autohide
        >
            {toastInfo?.text || ''}
        </Toast>
    </div>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'videoById',
        'token',
        'protocolList',
        'eventsColor',
    ])
};

const mapDispatchToProps = {EventColors, GetVideoById, UserDetachVideoService};

export default connect(mapStateToProps, mapDispatchToProps)(NewPlayer);
