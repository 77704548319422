import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";
import './Chain.scss';
import {Breadcrumb, Toast} from "react-bootstrap";

import {
	GetChannelById,
	DeleteVideoToChain, GetChannels, GetVideosService,
} from "../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {CloseIcon, ErrorIcon, PlusIcon, SuccessIcon} from "../../../../../image";
import {getChain, getChains} from "../../../../../redux/actions/chainsActions";
import {Loader} from "../../../../../shared/features/Loader";
import AddVideoToChainModal from "../../../../../shared/features/Chains/AddVideoToChainModal/AddVideoToChainModal";
import {generateChannelsList} from "../../../../../shared/features/Chains/helpers";
import VideoBlock from "../../Channels/VideoBlock/VideoBlock";
import InfoTooltip from "../../Profile/tooltip";

const ChainPage = (props) => {
	const location = useLocation();
	const params = useParams();
	const dispatch = useDispatch();

	const {t} = useTranslation();
	const channels = useSelector(state => state.channels?.channels);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisibleAddVideoModal, setIsVisibleAddVideoModal] = useState(false);
	const toggleIsVisibleAddVideoModal = useCallback(()=>setIsVisibleAddVideoModal(prevState => !prevState),[]);
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});

	const userPersonId = useSelector(state => state.profile.userProfile?.attached_person?.id);
	const videosList = useSelector(state => state.videos?.videosList || []);
	const channelVideos = videosList?.results?.filter(i => !!i?.channels?.includes(Number(params?.channelId)));
	const videos = channelVideos.filter((video) => (video?.chains_ids || []).includes(Number(params?.chainId)));
	const channelInfo = useSelector(state => state.channels?.channelById);
	const channelsList = generateChannelsList(channels);

	const getContent = async () => {
		try {
			setIsLoading(true);
			await dispatch(GetVideosService({
				limit: 1000,
				channel_id: params?.channelId
			}));
			await dispatch(GetChannelById(params?.channelId));
			await dispatch(getChains());
			await dispatch(GetChannels());
			await dispatch(getChain(params?.chainId));
			// await dispatch(GetPersons({
			// 	status: 'known',
			// 	limit: 1000
			// }));
			setIsLoading(false);
		}catch (e) {
			setIsLoading(false);
			console.warn(e)
		}
	}

	const deleteChainToVideo = async (id) => {
		try{
			await dispatch(DeleteVideoToChain(id, [{
				id: Number(params?.chainId),
				is_bound: false
			}]));
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t('video_detach_to_chain_success')
			});
		}catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t('video_detach_to_chain_error')
			});
			console.warn(e)
		}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0
		});
		getContent();

	}, []);

	return (
		<div className={`main-container channels-container ${props.isFullWidth ? '' : 'full'}`}>
			<div className="channels-page">
				<div className="channels-page__wrap">
					<Breadcrumb>
						{
							 (location?.state?.routs || []).map(item => {
								return(
									<Breadcrumb.Item
										key={item?.name}
										active={item?.active}
										className={item?.active ? 'breadcrumb_name' : ''}
									>
										{item?.active ?
											<div className="channels-page__name">
												{item?.name}
											</div>
											:
											<Link to={item?.path}>{item?.name}</Link>
										}
									</Breadcrumb.Item>)
							})
						}
					</Breadcrumb>
					<button
						className="chain__add-btn"
						onClick={toggleIsVisibleAddVideoModal}
					>
						<PlusIcon/>
						{t('btn_add_video_to_chaine')}
					</button>
				</div>
				{isLoading ? <Loader/> :
					<div
						className="chain__wrap"
					>
						{!!videos?.length ? <>
								{
									videos?.map((video) => {
										return(
											<div className="chain__wrap--video">
												<div className="video__detach">
													<InfoTooltip
														placement={'right'}
														customContent={
															<p>{t('video_detach_chain')}</p>
														}
														elem={
															<div
																className="video__detach--icon"
																onClick={() => deleteChainToVideo(video?.id)}
															>
																{isLoading ?
																	<Loader size={20}/>
																	:
																	<CloseIcon/>
																}
															</div>}
													/>
												</div>
												<VideoBlock
													badge={channelInfo}
													channelsList={channelsList}
													isPreview={true}
													routs={[
														{
															path: '/dashboard/videos/review',
															name: t('menu_item_video')
														},
														{
															path: '/dashboard/videos/review',
															name: t('menu_item_review')
														},
														{
															path: `/dashboard/videos/channels/${channelInfo?.id}`,
															name: channelInfo?.name
														}
													]}
													video={video}
												/>
											</div>
										)

									})
								}
							</>
							: <div className={`channels__section-videos-empty`}>
								{t('empty_list')}
							</div>}
					</div>
				}
			</div>
			{isVisibleAddVideoModal &&
				<AddVideoToChainModal
					onClose={toggleIsVisibleAddVideoModal}
					show={isVisibleAddVideoModal}
					channelId={params?.channelId}
					chainId={params?.chainId}
				/>
			}
			<Toast
				className='upload_video_answer'
				onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				<div className="toasblock">
					{toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
					<div className="toasblock__wrap">
						<span
							className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
						<span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
					</div>
				</div>
			</Toast>
		</div>
	)
}

export default ChainPage
