// Import packages
import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Import assets
import './participation-data.scss';

// Import Components
import VideoCard from "../../Channels/card/VideoCard";
import CombinePersonsDropdown from "../../../../../shared/features/PombinePersons/CombinePersonsDropdown";

// Import Utils
import {getAuthorName, getTimeString} from "../../../../../utils/helperFunctions";
import {FileIcon, PictureIcon, PinFillIcon, PlayIcon} from "../../../../../image";
import {history} from "../../../../../utils/history";
import {useDispatch, useSelector} from "react-redux";
import {DeleteHighlights} from "../../../../../redux/actions";

function ParticipationData(props) {
    const {videosList, user, highlights, avatar, setVideoList, closeModal} = props;
    const {t, i18n} = useTranslation();
    const personsList = useSelector(state => state?.persons?.personsList)
    const dispatch = useDispatch();

    function navigateTo() {
        props.closeModal();
        history.push({
            pathname: `/dashboard/videos/userVideos/${user?.person_id ? user?.person_id : user?.id}`,
            state: {
                personId: user?.person_id ? user?.person_id : user?.id,
                routs: [
                    {
                        path: '/dashboard/profile',
                        name: t('profile_profile')
                    },
                    {
                        path: `/dashboard/videos/userVideos/${user?.person_id ? user?.person_id : user?.id}`,
                        name: getAuthorName(user?.id ? user : user,null,i18n.language),
                        active: true
                    }
                ]
            }
        })
    }

    return <div className="participation-data">
        {
            videosList?.count ? <div>
                    <div className={'participation-data__title'}>
                        {t('profile_video')}
                        <span className="count">{videosList?.count}</span>
                    </div>

                    <div className="video_list">
                        {
                            videosList?.results?.slice(0, 2).map(item => {
                                return <VideoCard
                                            user={user}
                                            setVideoList={setVideoList}
                                            key={item?.id}
                                                  className={'user_video_card'}
                                                  routs={[
                                                      {
                                                          path: '/dashboard/profile',
                                                          name: t('profile_profile')
                                                      },
                                                      {
                                                          id: user?.id ? user : user,
                                                          name: getAuthorName(user?.id ? user : user,null,i18n.language),
                                                      },
                                                      {
                                                          path: `/dashboard/profile`,
                                                          name: `${i18n.language === 'ru' ? 'Видео с' : 'Video with'} ` + getAuthorName(user?.id ? user : user,null,i18n.language),
                                                          active: true
                                                      }
                                                  ]}
                                                  video={item}/>
                            })
                        }

                        {videosList?.count > 2 && <div className="more_videos" onClick={navigateTo}>
                            <FileIcon/>

                            <div className="more_videos__bottom">
                                <div className="more_videos__bottom__count">
                                    {videosList?.count}
                                    <span className="text">{t('video')}</span>
                                </div>
                            </div>

                        </div>}
                    </div>
                </div>
                : <div className="empty_video">{t('empty_video')}</div>
        }

        {!!highlights?.length && <div className={'participation-data__title'}>
            {t('profile_highlights')}
            <span className="count">{highlights?.length}</span>
        </div>}

        <div className="highlights-list">
            {
                !!highlights?.length && highlights.sort((a,b) => b.id - a.id)?.map(({protocol_text, id, video}) => {
                    const protocolAuthor = (personsList?.results || []).find(el => el.id === (protocol_text?.person_id))
                    const protocolExecutorAuthor = (personsList?.results || []).find(item => item.id === protocol_text?.task_executor_person_id)

                    return <div className="protocol-item" key={id}>
                        <div className="protocol-item__content">
                            <div className="left">
                                <div className="video-title">
                                    <span>{t('from')}</span>
                                    <Link
                                        to={`/dashboard/videos/video/${video?.id}/time/${protocol_text?.start_time}`}
                                        className="video-link">
                                        <PlayIcon/>
                                        {video?.description}
                                    </Link>
                                </div>
                                <div className="text">
                                    {protocol_text?.text}
                                    <PinFillIcon className="highlights-star"
                                                  onClick={() => dispatch(DeleteHighlights(id))}/>
                                </div>

                                <div className="protocol-item__author_wrapper">
                                    <div className="protocol-item__author_name">
                                        {
                                            protocol_text.author_name ? protocol_text.author_name
                                                : getAuthorName(protocolAuthor || {id: protocol_text?.person_id},null,i18n.language)
                                        }
                                    </div>
                                    {(protocol_text?.task_executor_person_id || protocol_text?.task_executor_name) && <>
                                        <span>{t('to')}</span>
                                        <div className="protocol-item__to_name">
                                            @ {
                                            protocol_text?.task_executor_name ? protocol_text.task_executor_name
                                                : getAuthorName(protocolExecutorAuthor || {id: protocol_text?.person_id},null,i18n.language)
                                        }
                                        </div>
                                    </>}
                                    {(protocol_text?.start_time || protocol_text?.start_time === 0) &&
                                        <div className="time">
                                            {getTimeString(protocol_text?.start_time || 0)}
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                })
            }
        </div>

        {!!user?.person_id && <div className="recognition-title">{t('recognition_settings')}</div>}

        {
            user && <div className={'connect_person'}>
                <span>{t('connect_person')}</span>

                <CombinePersonsDropdown user={user} setVideoList={setVideoList}/>
            </div>
        }
        {
            (!avatar || avatar === 'None' )&& <div className={'add_img'}>
                <span>{t('add_img')}</span>

                <button className="add_img_button">
                    <PictureIcon/>
                    {t('upload_img')}
                </button>
            </div>
        }
    </div>
}

export default ParticipationData;
