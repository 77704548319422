import React from 'react';
import {Popover} from "antd";
import './AvatarGroup.scss';
import UsersGroupList from "./UsersGroupList";
import UserGroupContainer from "./UserGroupContainer";

const AvatarGroup = (props) => {
	const {users,videoId, updateUserInfo, userInfo, activePage, isDetach, isActiveUser, toggleUserModal} = props;

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			content={
				<UserGroupContainer
					users={users}
					videoId={videoId}
					updateUserInfo={updateUserInfo}
					userInfo={userInfo}
					activePage={activePage}
					isDetach={isDetach}
					isActiveUser={isActiveUser}
					toggleUserModal={toggleUserModal}
				/>
			}
			overlayClassName="user-group__popover"
			destroyTooltipOnHide={true}
		>
			<UsersGroupList users={users} isActiveUser={isActiveUser}/>
		</Popover>
	);
};

export default AvatarGroup;