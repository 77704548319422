import React, {useState} from 'react';
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {Loader} from "../Loader";
import './SearchVideoBlock.scss';
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import {monthsShort, monthsShortEn} from "../../../utils/constants";
import InfoTooltip from "../../../pages/Dashboard/containers/Profile/tooltip";
import i18n from "i18next";

const formatDate = (date,lng) => {
	const day = dayjs(date).date();
	const monthIndex = dayjs(date).month(); // 0-11
	const year = dayjs(date).year();
	const month = lng === 'ru' ? monthsShort[monthIndex] : monthsShortEn[monthIndex];


	return `${day} ${month} ${year}`;
};

const SearchVideoBlock = (props) => {
	const {item, searchText} = props;
	const [isVisibleDefaultPoster, setIsVisibleDefaultPoster] = useState(false);
	const [isLoader, setLoader] = useState(false);

	return (
		<div className={"video"}>
			<div className="video__poster">
				{isLoader && (
					<div className="video__loader-container">
						<Loader size={50} />
					</div>
				)}
				<video
					className={"video__poster"}
					poster={isVisibleDefaultPoster ? "https://grinvich.com/static/assets/images/media-default-video.png" : null}
					width="114" height="74"
					onCanPlay={() => setIsVisibleDefaultPoster(false)}
					onError={() =>{
						setIsVisibleDefaultPoster(true);
						setLoader(false);
					}}
					onLoadStart={() => setLoader(true)}
					onLoadedData={() => setLoader(false)}
					onLoad={() => setLoader(false)}
				>
					<source
						src={item?.url_s3 ? item?.url_s3 : generateMediaUrl(item?.url)}
						type="video/mp4"
					/>
				</video>
				{item?.duration ?
					<div className="video__poster--duration">
						{item?.duration}
					</div>
					: null}
			</div>
			<div className="video__info">
				{item?.description.length > 15 ?
				<InfoTooltip
					elem={
					<Highlighter
						className={"video__info--title"}
						highlightClassName="highlight-text"
						searchWords={[searchText]}
						autoEscape={true}
						textToHighlight={item?.description}
					/>
				}
				             title={item?.description}
				/>
					:
					<Highlighter
						className={"video__info--title"}
						highlightClassName="highlight-text"
						searchWords={[searchText]}
						autoEscape={true}
						textToHighlight={item?.description}
					/>
				}
				<div className={"video__info--date"}>
					<span>{formatDate(item?.created_at, i18n.language)}</span>
					<span>{dayjs(item?.created_at).format('HH:mm:ss')}</span>
				</div>
			</div>
		</div>
	);
};

export default SearchVideoBlock;