// Import packages
import React, {useEffect, useRef, useState} from 'react';

// Import assets
import './texts.scss';

//Import Components
import TextItem from "./TextItem/TextItem";
import {VIDEO_PANEL_TABS} from "../const";
import {useTranslation} from "react-i18next";
import i18n from "i18next";


function Texts({
	               texts,
	               getTime,
	               videoPlayerCurrentTime,
	               isPlayed,
	               token,
	               searchText,
	               setFoundedTexts,
	               AddProtocol,
	               videoId,
	               changeVideoTime,
	               videoPersons,
	               UpdateTranscription,
	               lastProtocolId,
	               isAI,
	               MarkVideo,
	               ReplaceTranscriptionWord,
	               GetVideoById,
	               isShort,
	               activeTabs,
	               tabType,
	               currentVideoId,
	               activeTextIndex,
	               countTask,
	               foundedTexts,
	               editAll
               }) {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [scrolled, setScrolled] = useState(true)

	const textsContent = useRef();
	const currentIndex = useRef();
	const {t} = useTranslation();

	useEffect(() => {
		let list = []
		if(tabType === activeTabs) {
			texts?.length && texts.forEach((item, index) => {
				if(videoPlayerCurrentTime > item.end_time) {
					currentIndex.current = index
				}
				const regex = new RegExp(searchText, 'gi'); // Регулярное выражение для поиска фразы
				const getTextItemSummary = `##### ${i18n.language === 'ru' ? 'Глава' : 'Chapter'} ${index}. ${item?.text} \n ${item?.summary}`;

				const matches = activeTabs === VIDEO_PANEL_TABS.SUMMERY
					? (getTextItemSummary).match(regex)
					: (item?.author + item?.text).match(regex); // Поиск всех вхождений фразы
				const count = matches ? matches.length : 0; // Возвращаем количество найденных вхождений

				if(count > 0) {
					matches.forEach(() => {
						list.push(index)
					})
				}
			})
			setFoundedTexts && setFoundedTexts(searchText ? list : [])
		}
	}, [searchText, activeTabs])


	useEffect(() => {
		if(activeTabs === tabType) {
			const delta = 20;
			let startTime = 0;
			let endTime = 0;

			texts?.length && texts.forEach((item, index) => {
				if(currentVideoId === Number(item.id)) {
					currentIndex.current = index
				}
			})

			if(videoPlayerCurrentTime > texts[currentIndex?.current || 0]?.end_time) {
				currentIndex.current = (currentIndex.current || 0) + 1
			}

			if(!texts?.[currentIndex?.current] || !texts[currentIndex?.current]?.start_time) {
				return;
			}

			startTime = parseFloat(texts[currentIndex?.current]?.start_time);
			endTime = parseFloat(texts[currentIndex?.current]?.end_time || 0);
			let timeElem = activeTabs === VIDEO_PANEL_TABS.AI
				? document.querySelector(`#controlled-tab-example-tabpane-0 .texts__wrap .times_index_${currentIndex?.current}`)
				: document.querySelector(`#controlled-tab-example-tabpane-${activeTabs} .times_index_${currentIndex?.current}`);

			let container = activeTabs === VIDEO_PANEL_TABS.AI
				? document.querySelector(`#controlled-tab-example-tabpane-0`)
				: textsContent?.current
			;

			if((startTime <= Math.ceil(videoPlayerCurrentTime))) {
				//   Get time elem
				if(!timeElem) {
					return;
				}
				scrolled && container?.scrollTo({
					top: timeElem?.offsetTop - delta,
				});
			} else {
				videoPlayerCurrentTime === 0 && scrolled && container?.scrollTo({
					top: 0,
				});
			}
		}
	}, [videoPlayerCurrentTime, isPlayed, texts, activeTabs])

	useEffect(() => {
		if(activeTabs === tabType && foundedTexts?.length) {
			const delta = 20;
			let startTime = 0;
			let endTime = 0;
			const index = foundedTexts[activeTextIndex - 1];

			const selectors = [VIDEO_PANEL_TABS.AI].includes(activeTabs)
				? `#controlled-tab-example-tabpane-${VIDEO_PANEL_TABS.AI}`
				: `#controlled-tab-example-tabpane-${activeTabs} #video_texts`;

			let selectorsElem = [VIDEO_PANEL_TABS.AI].includes(activeTabs)
				? `#controlled-tab-example-tabpane-${VIDEO_PANEL_TABS.AI} .show .times_index_${index}`
				: `#controlled-tab-example-tabpane-${activeTabs} .times_index_${index}`;

			let timeElem = document.querySelector(selectorsElem);
			const container = document.querySelector(selectors);

			if(timeElem) {
				container && container.scrollTo({
					top: timeElem?.offsetTop - delta,
				});
			}
		}
	}, [activeTextIndex])

	const handleScroll = (e, position) => {
		const currentPosition = e?.target?.scrollTop;
		currentPosition && setScrollPosition(position ? position : currentPosition);
	};

	// console.log(foundedTexts, 'foundedTexts');
	const toggleAddProtocol = (e, data) => {
		e.preventDefault()
		const {start_time, text, author_name, person_id, author, task_executor_person_id, task_executor_name} = data
		let reqData = {
			video_id: videoId,
			// start_time: parseInt(start_time) || 0,
			author_name,
			text,
			insert_after_protocol_block_id: lastProtocolId,
			task_executor_person_id,
			task_executor_name
		}
		author !== 'unrecognized' && person_id && (reqData.person_id = person_id)
		author_name && (reqData.author_name = reqData.person_id ? null : author_name)
		start_time && (reqData.start_time = parseInt(start_time))
		AddProtocol(reqData).then(() => {
			MarkVideo(data)
		});
	}

	const textInsight = texts.slice(countTask, texts.length);
	return (
		<div
			className={`texts ${isAI ? 'custom-scroll' : 'custom-scroll'} ${editAll ? 'texts-edit' : ''}`}
			id={'video_texts'}
			ref={textsContent}
			onWheel={(e) => e.stopPropagation()}
			onScroll={handleScroll}
			onMouseOver={(e) => {
				e.stopPropagation()
				setScrolled(false)
			}}
			onMouseLeave={(e) => {
				e.stopPropagation()
				setScrolled(true)
			}}>
			{
				!!texts?.length &&
				texts.map((item, index) => {
					const currentItem = Number(item?.start_time) === videoPlayerCurrentTime && (Number(currentVideoId) === Number(item?.start_time) || Number(currentVideoId) === Number(item?.id))
					const isActiveTextItemPlay = activeTabs === VIDEO_PANEL_TABS.TEXTS && videoPlayerCurrentTime >= Number(item?.start_time) && videoPlayerCurrentTime <= Number(item?.end_time)

					return (
						<>
							{(index === countTask && textInsight.length && activeTabs === VIDEO_PANEL_TABS.AI) ?
								<div className="ai-container__block">
									<div className="ai-container__block--title">{t('insights_title')}</div>
								</div>
								: null}
							<TextItem key={item?.id}
							          item={item}
							          isShort={isShort}
							          searchText={searchText}
							          index={index}
							          token={token}
							          videoId={videoId}
							          scrollPosition={scrollPosition}
							          currentItem={currentItem}
							          videoPersons={videoPersons}
							          getTime={getTime}
							          GetVideoById={GetVideoById}
							          handleScroll={handleScroll}
							          UpdateTranscription={UpdateTranscription}
							          toggleAddProtocol={toggleAddProtocol}
							          ReplaceTranscriptionWord={ReplaceTranscriptionWord}
							          changeVideoTime={changeVideoTime}
							          MarkVideo={MarkVideo}
							          activeTabs={activeTabs}
							          activeTextIndex={activeTextIndex}
							          foundedTexts={foundedTexts}
							          tabType={tabType}
							          className={isActiveTextItemPlay ? 'texts__item--active' : ''}
							/>
						</>
					)
				})
			}
		</div>
	);
}

export default Texts;
