import {LINKS_CONSTS, PROTOCOL_CONSTS, TRANSCRIPTIONS_CONSTS, VIDEOS_CONSTS} from "../constants";
import {ApiStatus} from "../../utils/constants";

export const initialState = {
    videoById: null,
    videosList: {},
    videosByChannel: {},
    searchVideos: {},
    serviceStatuses: true,
    searchText: '',
    isFetchingVideos: ApiStatus.INITIAL
};

export default (state = initialState, action) => {
    switch (action.type) {
        case VIDEOS_CONSTS.GET_VIDEOS:
            return {
                ...state,
                videosList: action.payload,
            };
        case VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS:
            return {
                ...state,
                isFetchingVideos: action.payload,
            };
        case VIDEOS_CONSTS.CLEAR_VIDEOS:
            return {
                ...state,
                videosList: initialState.videosList,
            };
        case VIDEOS_CONSTS.DELETE_VIDEOS:
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: state.videosList.results.filter(item => item.id !== action.payload)
                }
            };
        case VIDEOS_CONSTS.UPDATE_VIDEO_CHANNEL:
            let newList = state.videosList.results.map(item => {
                return item.id !== action.payload.id ? item
                    : {
                        ...item,
                        channels: action.payload.channels.filter(item => item.is_bound).map(i => i.id)
                    }
            })
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: newList
                },
                videoById: {
                    ...state.videoById,
                    channels: action.payload.channels.filter(item => item.is_bound).map(i => i.id)
                }
            };
        case LINKS_CONSTS.UPDATE_LINK:
            let listLinks = state.videosList.results.map(item => {
                return item.id !== action.payload.video_id ? item : {
                        ...item,
                        link: {
                            ...item.link,
                            ...action.payload
                        }
                    }
            })
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: listLinks
                },
                videoById: {
                    ...state.videoById,
                    link: {
                        ...state.videoById?.link,
                        ...action.payload
                    }
                }
            };
        case VIDEOS_CONSTS.GET_SERVICE_STATUSES:
            return {
                ...state,
                serviceStatuses: action.payload?.ml_service_running
            };
        case VIDEOS_CONSTS.GET_VIDEOS_SEARCH:
            return {
                ...state,
                searchVideos: action.payload
            };
        case VIDEOS_CONSTS.CLEAR_VIDEOS_SEARCH:
            return {
                ...state,
                searchVideos: {}
            };
        case VIDEOS_CONSTS.ADD_VIDEO_DESCRIPTION:
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: state.videosList.results.map(item => {
                        return item.id === action.payload.video_id ? {
                            ...item,
                            description: action.payload.description
                        } : item
                    })
                },
                videoById: {
                    ...state.videoById,
                    description: action.payload.description
                }
            };
        case VIDEOS_CONSTS.REPROCESS_VIDEOS:
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: state.videosList.results.map(item => {
                        return item.id === action.payload ? {
                            ...item,
                            video_status: 'processing'
                        } : item
                    })
                }
            };
        case VIDEOS_CONSTS.GET_VIDEO_BY_ID:
            return {
                ...state,
                videoById: action.payload
            };
        case VIDEOS_CONSTS.MARK_VIDEO:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time))
                        && item.start_time !== null ? {
                            ...item,
                            isMarked: true
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time))
                         ? {
                            ...item,
                            isMarked: true
                        } : {...item, isMarked: false}
                    }),
                    task_list: state.videoById.task_list.map(item => {
                        const isCheckKey = Object.keys(item).some((key)=>key === 'isMarked')
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time)  && !isCheckKey)
                          ? {
                              ...item,
                              isMarked: !!action.payload?.marked
                          } : {...item}
                    }),
                    chapter_list: state.videoById.chapter_list.map(item => {
                        const isCheckKey = Object.keys(item).some((key)=>key === 'isMarked')
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time)  && !isCheckKey)
                          ? {
                              ...item,
                              isMarked: !!action.payload?.marked
                          } : {...item}
                    })
                }
            };
        case VIDEOS_CONSTS.UNMARK_VIDEO:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time)) ? {
                            ...item,
                            isMarked: false
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time)) ? {
                            ...item,
                            isMarked: false
                        } : item
                    })
                }
            };
        case PROTOCOL_CONSTS.DELETE_PROTOCOL:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return parseInt(action.payload?.start_time) === parseInt(item.start_time) && item.person_id === action.payload?.person_id ? {
                            ...item,
                            isMarked: false
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return parseInt(action.payload?.start_time) === parseInt(item.start_time) && item.person_id === action.payload?.person_id ? {
                            ...item,
                            isMarked: false
                        } : item
                    })
                }
            };
        case TRANSCRIPTIONS_CONSTS.UPDATE_TRANSCRIPTION:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return item.id === action.payload.transcriptions_id ? {
                            ...item,
                            person_id: action.payload.person.id,
                            author: action.payload.person.first_name
                                ? action.payload.person.first_name : '' + action.payload.person.last_name
                                    ? action.payload.person.last_name : '',
                            text: action.payload?.data?.text ? action.payload?.data?.text : item?.text
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return item.person_id === action.payload.transcription.person_id ? {
                            ...item,
                            person_id: action.payload.person.id
                        } : item
                    })
                }
            };
        case VIDEOS_CONSTS.CLEAR_VIDEO_BY_ID:
            return {
                ...state,
                videoById: null
            };
        case VIDEOS_CONSTS.SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload?.search_query
            };
        case VIDEOS_CONSTS.ADD_CHAIN_TO_VIDEO:
            let newId = action.payload.data.filter(item => item.is_bound).map(i => i.id);
            let videosNew = (state.videosList?.results || []).map(item => {
                let ids = [...newId, ...item?.chains_ids]
                let uniqueArray = [...new Set(ids)];

                return item.id !== action.payload.video_id ? item
                  : {
                      ...item,
                      chains_ids: uniqueArray
                  }
            })
            let videosChannelNew = (state.videosByChannel?.results || []).map(item => {
                let ids = [...newId, ...item?.chains_ids]
                let uniqueArray = [...new Set(ids)];

                return item.id !== action.payload.video_id ? item
                  : {
                      ...item,
                      chains_ids: uniqueArray
                  }
            })
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: videosNew
                },
                videosByChannel: Object.keys(state?.videosByChannel).length ? {
                    ...state.videosByChannel,
                    results: videosChannelNew
                } : {
                    ...state.videosByChannel
                },
                videoById: {
                    ...state.videoById,
                    chains_ids: action.payload.data.filter(item => item.is_bound).map(i => i.id)
                }
            }
        case VIDEOS_CONSTS.ADD_CHAIN_TO_VIDEOS:
            let id = action.payload.chain_id;
            let videos = (state.videosList?.results || []).map(item => {
                let arrIds = [id, ...item?.chains_ids];
                let uniqueArray = [...new Set(arrIds)];

                return !(action.payload.data).includes(item.id)  ? item
                  : {
                      ...item,
                      chains_ids: uniqueArray
                  }
            })

            let videosChannel = (state.videosByChannel?.results || []).map(item => {
                let arrIds = [id, ...item?.chains_ids];
                let uniqueArray = [...new Set(arrIds)];

                return !(action.payload.data).includes(item.id)  ? item
                  : {
                      ...item,
                      chains_ids: uniqueArray
                  }
            })

            return {
                ...state,
                videosByChannel: Object.keys(state?.videosByChannel).length ? {
                      ...state.videosByChannel,
                      results: videosChannel
                  } : {
                    ...state.videosByChannel
                },
                videosList: {
                    ...state.videosList,
                    results: videos
                }
            };
        case VIDEOS_CONSTS.DELETE_CHAIN_TO_VIDEO:
            const idsChains = action.payload.data.filter(item => !item.is_bound).map(i => i.id);
            const arr = (state.videosList?.results || []).map(item => {
                return item.id !== action.payload.video_id ? item
                  : {
                      ...item,
                      chains_ids: item.chains_ids.filter((item)=> !idsChains.includes(item))
                  }
            })
            const arrChannel = (state.videosByChannel?.results || []).map(item => {
                return item.id !== action.payload.video_id ? item
                  : {
                      ...item,
                      chains_ids: item.chains_ids.filter((item)=> !idsChains.includes(item))
                  }
            })
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: arr
                },
                videosByChannel: Object.keys(state?.videosByChannel).length ? {
                    ...state.videosByChannel,
                    results: arrChannel
                } : {
                    ...state.videosByChannel
                }
            };
        case VIDEOS_CONSTS.GET_VIDEOS_CHANNEL:
            return {
                ...state,
                videosByChannel: action.payload,
            };
        case VIDEOS_CONSTS.CLEAR_VIDEOS_CHANNEL:
            return {
                ...state,
                videosByChannel: {},
            };
        default:
            return state;
    }
}
