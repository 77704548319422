export const profileErrorMessages = {
    first_name: 'Имя должно содержать не менее 2 символов.',
    last_name: 'Фамилия должна содержать не менее 2 символов.',
    email: 'Эл. почта неверна',
    linkedin: 'Linkedin некорректный',
    facebook: 'Facebook некорректный',
    phone_number: 'Должно быть не менее 11 символов.',
    password: 'Ваш пароль должен быть длиной не менее 8 символов',
    login: 'Пользователь уже связан с человеком',
    required: 'Обязательное поле',

    // first_name: 'Name must be at least 2 characters',
    // last_name: 'Surname must be at least 2 characters',
    // email: 'E-mail is incorrect',
    // linkedin: 'Linkedin is incorrect',
    // facebook: 'Facebook is incorrect',
    // phone_number: 'Must be at least 11 characters',
    // password: 'Your password must be at least 8 characters long',
    // login: 'The user is already associated with the person',
}


export const MIN_ZOOM = 0;
export const MAX_ZOOM = 1.5;

export const HOUR_IN_SECOND = 3600;
// export const MIN_MENU_SIZE = 100;
// export const MAX_MENU_SIZE = 233;

export const rolePermissions = (lng) => {
    return {
        [lng === 'ru' ? 'Пользователи' : 'Users'] : ['users_all', 'users_create', 'users_read', 'users_update', 'users_delete', /*'users_check_email'*/],
        [lng === 'ru' ? 'Видео': 'Video'] : ['videos_all', 'videos_create', 'videos_read', 'videos_update', 'videos_delete', 'videos_reprocess'],
        [lng === 'ru' ? 'Каналы' : 'Channels'] : ['channels_all', 'channels_create', 'channels_read', 'channels_update', 'channels_delete'],
        [lng === 'ru' ? 'Основные моменты' : 'Highlights'] : ['highlights_all', 'highlights_create', 'highlights_read', 'highlights_update', 'highlights_delete'],
        [lng === 'ru' ? 'Участники' : 'Participants'] : ['persons_all', 'persons_attach', 'persons_create', 'persons_read', 'persons_update', 'persons_delete'],
        [lng === 'ru' ? 'Протокол' : 'Protocol'] : ['protocol_texts_all', 'protocol_texts_create', 'protocol_texts_update', 'protocol_texts_delete'],
        [lng === 'ru' ? 'События календаря' : 'Calendar events'] : ['calendar_events_all', 'calendar_events_read', 'calendar_events_update'],
        [lng === 'ru' ? 'Календари пользователей' : 'User calendars'] : ['user_calendars_all', 'user_calendars_read', 'user_calendars_update'],
        [lng === 'ru' ? 'События журнала' : 'Log events'] : ['journal_events_read'],
        [lng === 'ru' ? 'Роли' : 'Roles'] : ['roles_all', 'roles_create', 'roles_read', 'roles_update', 'roles_delete'],
        [lng === 'ru' ? 'Теги' : 'Tags'] : ['tags_all', 'tags_create', 'tags_read', 'tags_update', 'tags_delete'],
        [lng === 'ru' ? 'Услуга' : 'Service'] : ['service_statuses'],
        [lng === 'ru' ? 'Поделиться' : 'To share'] : ['share_links_create'],
        [lng === 'ru' ? 'Транскрипции' : 'Transcriptions'] : ['transcriptions_update'],
    }
}
export const rolePermissionsKeys = {
    'users': ['users_all', 'users_create', 'users_read', 'users_update', 'users_delete', /*'users_check_email'*/],
    'videos': ['videos_all', 'videos_create', 'videos_read', 'videos_update', 'videos_delete', 'videos_reprocess'],
    'channels': ['channels_all', 'channels_create', 'channels_read', 'channels_update', 'channels_delete'],
    'highlights': ['highlights_all', 'highlights_create', 'highlights_read', 'highlights_update', 'highlights_delete'],
    'persons': ['persons_all', 'persons_attach', 'persons_create', 'persons_read', 'persons_update', 'persons_delete'],
    'protocol_texts': ['protocol_texts_all', 'protocol_texts_create', 'protocol_texts_update', 'protocol_texts_delete'],
    'calendar_events': ['calendar_events_all', 'calendar_events_read', 'calendar_events_update'],
    'user_calendars': ['user_calendars_all', 'user_calendars_read', 'user_calendars_update'],
    'journal_events': ['journal_events_read'],
    'roles': ['roles_all', 'roles_create', 'roles_read', 'roles_update', 'roles_delete'],
    'tags': ['tags_all', 'tags_create', 'tags_read', 'tags_update', 'tags_delete'],
    'service_statuses': ['service_statuses'],
    'share_links': ['share_links_create'],
    'transcriptions': ['transcriptions_update'],
}

export const rolePermissionTypes = (lng) =>{
    return {
        'all': lng === 'ru' ? 'Всё' : 'All',
        'read': lng === 'ru' ? 'Просмотр' : 'Viewing',
        'create': lng === 'ru' ? 'Создание' : 'Creation',
        'update': lng === 'ru' ? 'Редактирование' : 'Editing',
        'delete': lng === 'ru' ? 'Удаление' : 'Removal',
        'check_email': 'check_email',
        'reprocess': lng === 'ru' ? 'Переобработка' : 'Reprocessing',
        'attach': lng === 'ru' ? 'Прикреплять' : 'Attach',
        'statuses': lng === 'ru' ? 'Статусы' : 'Statuses',
    }
}

export const uris = {
    OAUTH2_PROVIDER: "https://demo1-reis.dev.ntrnx.com",
    LOGIN_REDIRECT: "/dashboard/videos/review",
    LOGOUT_REDIRECT: "/",
}

export const statusesList = {
    uploading: {
        key: 'uploading',
        name: 'Up.'
    },
    converting: {
        key: 'converting',
        name: 'Cv.'
    },
    'conversion-failed': {
        key: 'conversion-failed',
        name: 'Cv-F.'
    },
    processing: {
        key: 'processing',
        name: 'Pr.'
    },
    'processing-rejected': {
        key: 'processing-rejected',
        name: 'Pr-R.'
    },
    'processing-failed': {
        key: 'processing-failed',
        name: 'Pr-F.'
    },
    processed: {
        key: 'processed',
        name: 'Prd.'
    },
    'sending-to-storage': {
        key: 'sending-to-storage',
        name: 'S-S.'
    }
}

export const statusesListRu = {
    uploading: {
        key: 'загрузка',
        name: 'Загр.'
    },
    converting: {
        key: 'конвертирование',
        name: 'Конв.'
    },
    'conversion-failed': {
        key: 'конвертирование не выполнено',
        name: 'Конв. н.в.'
    },
    processing: {
        key: 'обработка',
        name: 'Обр-ка'
    },
    'processing-rejected': {
        key: 'обработка отклонена',
        name: 'Обр. откл.'
    },
    'processing-failed': {
        key: 'обработка не выполнена',
        name: 'Обр. н.в.'
    },
    processed: {
        key: 'обработано',
        name: 'Обр-но'
    },
    'sending-to-storage': {
        key: 'отправка в хранилище',
        name: 'Отпр. в хр.'
    }
}

export  const monthsShort = [
    "Янв.", "Февр.", "Март", "Апр.", "Май", "Июнь",
    "Июль", "Авг.", "Сент.", "Окт.", "Нояб.", "Дек."
];

export  const monthsShortEn = [
    "Jan.", "Feb.", "March", "Apr.", "May", "June",
    "July", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
];

export const ApiStatus =  {
    INITIAL: 'INITIAL',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    LOADING: 'LOADING',
}