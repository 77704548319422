// Import packages
import React, {useEffect, useRef, useState} from 'react';
import Nestable from "react-nestable";
import {useLocation} from "react-router-dom";
import {connect, useSelector} from "react-redux";

// Import assets
import './protocol.scss';
import {CloseIcon, PlusIcon, ShareIcon, VoiceMessage} from "../../../../../image";

//Import utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    AddProtocolHighlight,
    DeleteProtocolHighlight,
    UpdateProtocolPosition,
    GetVideosSearch,
    GetProtocol
} from "../../../../../redux/actions";
import {getAuthorName, parseTime} from "../../../../../utils/helperFunctions";

//Import components
import ProtocolItem from "./ProtocolItem/ProtocolItem";
import {useTranslation} from "react-i18next";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Tooltip} from "react-bootstrap";
import AutocompleteUsers from "../../../../../shared/features/autocomplete/AutocompleteUsers";

const initProtocolData = {
    person_id: '',
    author_name: '',
    text: '',
    time: '',
    task_executor_person_id: '',
    task_executor_name: ''
}

function Protocol(props) {
    const {
        AddProtocol,
        UpdateProtocol,
        UpdateProtocolPosition,
        DeleteProtocol,
        GetLink,
        protocolList,
        videoPersons,
        changeVideoTime, videoPlayerCurrentTime, getTime,
        videoId, company,
        token,
        userProfile,
        personsList,
        MarkVideo,
        texts,
        setCopied,
        currentVideoId
    } = props

    const {t, i18n} = useTranslation();
    const [protocolData, setProtocolData] = useState(initProtocolData)
    const [showFirstForm, setShowFirstForm] = useState(false)

    const [error, setError] = useState({
        person_id: false,
        author_name: false,
        text: false,
        time: false
    })
    const [persons, setPersons] = useState([])

    let disabledBtn = !!protocolData.text?.length
    const location = useLocation()
    const protocolContent = useRef();
    const profileInfo = useSelector(state => state.profile?.userProfile)

    // useEffect(() => {
    //     if (copied) {
    //         setTimeout(() => {
    //             setCopied(false)
    //         }, 300)
    //     }
    // }, [copied])

    useEffect(() => {
        setProtocolData({
            ...protocolData,
            author_name: `${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`
        })

        !!personsList?.results?.length && (setPersons([...videoPersons, ...personsList?.results?.filter(
            i => !videoPersons?.find(person => person?.id === i?.id)
        )]))
    }, [userProfile])

    const handleScroll = (position) => {
        protocolContent?.current && protocolContent.current.scrollTo({
            top: position
        });
        protocolContent?.current && protocolContent.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    };

    const changeNoteData = (e) => {
        const {name, value} = e.target

        if (name === "time" && value && !/^[+\d]\d*$/.test(parseInt(value.replaceAll(':', '')))) {
            return
        }

        let changedData = {
            ...protocolData,
            [name]: value
        }
        if (name === 'author_name') {
            changedData.person_id = ''
        }
        if (name === 'task_executor_name') {
            changedData.task_executor_person_id = ''
        }

        setProtocolData(changedData)

        setError({
            ...error,
            [name]: false
        })
    }

    const addVideoProtocol = () => {
        const {text, time, author_name, person_id, task_executor_name, task_executor_person_id} = protocolData
        let start_time = parseTime(time)
        let result = true
        let errors = {}

        if (!text) {
            errors.text = true
            result = false
        }

        setError(errors)
        if (result) {
            let reqData = {
                text,
            }
            time && (reqData.start_time = start_time || 0)
            videoId && (reqData.video_id = videoId)
            person_id && (reqData.person_id = person_id)
            task_executor_person_id && (reqData.task_executor_person_id = task_executor_person_id)
            if (!person_id && author_name) {
                reqData.author_name = author_name ? author_name : null
            }
            if (!task_executor_person_id && task_executor_name) {
                reqData.task_executor_name = task_executor_name ? task_executor_name : null
            }

            AddProtocol(reqData, -1)

            setProtocolData(initProtocolData)
            setShowFirstForm(false)
        }
    }

    const addProtocolItem = (data, index) => {
        AddProtocol(data, index)
    }

    function onPositionChange({items}) {
        let order = items?.map((i, index) => {
            return {
                number: index + 1,
                id: i.protocol.id
            }
        })

        UpdateProtocolPosition(videoId, {order})
    }

    const convertTime = (price) => {
        let arr = price.split('')
        let newArr = []

        for (let i = 0; i < arr.length; i++) {
            if ((i + 1) % 3 === 0) {
                newArr.push(':')
            } else {
                newArr.push(arr[i])
            }
        }

        return newArr.join('')
    }

    const changePersonId = (person) => {
        setProtocolData({
            ...protocolData,
            person_id: person.id,
            author_name: getAuthorName(person, null, i18n.language)
        })
        setError({
            ...error,
            person_id: false
        })
    }

    const changeExecutorPersonId = (person) => {
        setProtocolData({
            ...protocolData,
            task_executor_person_id: person.id,
            task_executor_name: `${person.first_name ? person.first_name : ''} ${person.last_name ? person.last_name : ''}`
        })
        setError({
            ...error,
            task_executor_person_id: false,
        })
    }

    const openFirstForm = () => {
        setShowFirstForm(true)
        setProtocolData({
            ...protocolData,
            person_id: profileInfo?.attached_person?.id || undefined,
            time: getTime(videoPlayerCurrentTime) ? getTime(videoPlayerCurrentTime) : ''
        })
    }

    const clearTime = () => {
        setProtocolData({
            ...protocolData,
            time: ''
        })
    }

    const closeEditing = () => {
        setProtocolData({
            ...initProtocolData,
            author_name: `${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`
        })
        setShowFirstForm(false)
    }

    const renderTooltip = (props, text) => (
        <Tooltip id="menu-tooltip" {...props}>
            {text}
        </Tooltip>
    );
    const tooltipWrapper = ({element, text}) => {
        return <OverlayTrigger
            flip={false}
            popperConfig={{}}
            onHide={undefined}
            onToggle={undefined}
            target={'auto-start'}
            defaultShow={false}
            show={undefined}
            trigger={undefined}
            placement="left"
            delay={{show: 100, hide: 200}}
            overlay={(props) => renderTooltip(props, text)}
        >
            {element}
        </OverlayTrigger>
    }

    const items = [];
    !!protocolList?.length && protocolList.forEach((item, index) => {
        const current = texts.find(i => i.text === item?.text)
        items.push({
            id: index,
            index: index,
            protocol: {
                ...item,
                end_time: Number(current?.end_time) ? Number(current?.end_time) : Number(item?.start_time) + 10
            }
        })
    });
    const renderItem = ({item, index}) => {
        const currentItem = Number(item?.protocol?.start_time) === Number(videoPlayerCurrentTime) && Number(currentVideoId) === Number(item?.protocol?.start_time)


        return <ProtocolItem key={item.protocol.id}
                             protocol={item.protocol}
                             videoId={videoId}
                             persons={persons}
                             authorName={`${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`}
                             company={company}
                             videoPersons={videoPersons}
                             changeVideoTime={changeVideoTime}
                             showFirstForm={showFirstForm}
                             setShowFirstForm={setShowFirstForm}
                             videoPlayerCurrentTime={videoPlayerCurrentTime}
                             getTime={getTime}
                             token={token}
                             prevProtocolId={protocolList?.[index]?.id}
                             protocolIndex={index}
                             GetLink={GetLink}
                             DeleteProtocol={DeleteProtocol}
                             UpdateProtocol={UpdateProtocol}
                             AddProtocolHighlight={props.AddProtocolHighlight}
                             DeleteProtocolHighlight={props.DeleteProtocolHighlight}
                             currentItem={currentItem}
                             addProtocolItem={addProtocolItem}
                             MarkVideo={MarkVideo}
                             texts={texts}
                             handleScroll={handleScroll}
                             setCopied={setCopied}
                             GetVideosSearch={props.GetVideosSearch}
                             GetProtocol={props.GetProtocol}
                             personsList={props.personsList}
        />
    };
    let copyText = '';
    protocolList?.length && protocolList.forEach(item => {
        copyText = copyText + `${item.author_name ? item.author_name
            : item?.first_name ? `${item.first_name ? item.first_name : ''} 
                                ${item?.last_name ? item?.last_name : ''}` : `id-${item?.id} ${t('unknown')}`}\n${item?.text}
                                \n${window.location.origin + location.pathname}/time/${item?.start_time || 0}/company/${company?.id}\n`
    })

    return <div className="protocol">

        <div className="protocol__header">
            <div className="protocol__header__line"/>
            {t('protocol_title')}
            {token && <>

                <button className="protocol__header__btn"
                        onClick={openFirstForm}>
                    <PlusIcon/>
                </button>
                {
                    tooltipWrapper({
                        element: <ShareIcon
                            className={'share_icon'}
                            onClick={() => {
                                // GetLink({
                                //     base_url: `${window.location.origin + location.pathname}`,
                                //     video_id: videoId
                                // }).then((res) => {
                                //     navigator.clipboard.writeText(res.text);
                                // })
                                GetLink({
                                    video_id: videoId,
                                })
                                    .then((res) => {
                                        navigator.clipboard.writeText(res.text);
                                        setCopied(true)
                                    })
                            }}/>,
                        text: t('share_video_and_protocol')
                    })
                }
            </>}
        </div>

        {
            showFirstForm && <div className="protocol__form">
                <div className="protocol__form__content">
                    <button className="protocol__form__close"
                            onClick={() => {
                                setProtocolData(initProtocolData)
                            }}>
                        <CloseIcon/>
                    </button>
                    <div className="protocol__form__title">{t('button_add')} {t('protocol_note')}</div>

                    <AutocompleteUsers
                      persons={persons}
                      protocolData={protocolData}
                      authorName={`${profileInfo?.first_name} ${profileInfo?.last_name}`}
                      placeholder={t('input_name')}
                      name={'author_name'}
                      onChangeUser={changePersonId}
                      onChangeInput={changeNoteData}
                    />
                    <textarea name="text"
                              placeholder={t('input_message')}
                              value={protocolData.text}
                              className={`${error.text ? 'error' : ''}`}
                              onChange={changeNoteData}
                    />
                    <AutocompleteUsers
                      persons={persons}
                      protocolData={protocolData}
                      authorName={protocolData['task_executor_name']}
                      placeholder={t('input_to_name')}
                      name={'task_executor_name'}
                      onChangeUser={changeExecutorPersonId}
                      onChangeInput={changeNoteData}
                    />
                    <div className="time-wrapper">
                        <input type="text"
                               className={`form_time`}
                               value={convertTime(protocolData.time)}
                               name={'time'}
                               placeholder={'00:00:00'}
                               maxLength={"8"}
                               onChange={changeNoteData}/>

                        {protocolData?.time && <CloseIcon onClick={clearTime}/>}
                    </div>

                    <div className={`protocol__form__btn ${!disabledBtn ? 'disabled' : ''}`}
                         onClick={disabledBtn && addVideoProtocol}>
                        {
                            (protocolData.author_name || protocolData.text || protocolData.time) ? t('button_save') : t('button_add')
                        }
                    </div>
                    <div className="protocol__form__btn edit" onClick={closeEditing}>
                        {t('button_cancel')}
                    </div>
                </div>
            </div>
        }
        {(!showFirstForm && items.length === 0 && token) ?
          <div className="info-block">
              <button className="info-block--btn"
                      onClick={openFirstForm}>
                  <PlusIcon/>
              </button>
              <p className="info-block--text">
                  {t('favourites_text_1')}
              </p>
          </div>
          : null}
        {(!showFirstForm && items.length === 0 && token) ?
          <div className="voice-block">
              <button className="voice-block--btn">
                  <VoiceMessage/>
              </button>
              <p className="voice-block--text">
                  {t('favourites_voice_text_1')}
              </p>
          </div>
          : null}

        <div
          className="protocol-list" ref={protocolContent}
          onWheel={(e)=>e.stopPropagation()}
        >
            <Nestable
                items={items}
                maxDepth={1}
                renderItem={renderItem}
                onChange={onPositionChange}
            />
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['token', 'userProfile', 'personsList'])
};

const mapDispatchToProps = {
    AddProtocolHighlight,
    DeleteProtocolHighlight,
    UpdateProtocolPosition,
    GetVideosSearch,
    GetProtocol
}
export default connect(mapStateToProps, mapDispatchToProps)(Protocol);
