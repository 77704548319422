import React from 'react';
import MarkdownIt from "markdown-it";

function highlightMatches(text, words, activeIndex,activeTextIndex) {
  if (words) {
    const regexSafeWords = words.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(regexSafeWords, 'gi');
    // Функция для замены совпадений в тексте с учетом HTML-тегов
    let currentActiveIndex = activeIndex;

    const replaceFn = (match, index) => {
      const isActive = currentActiveIndex === activeTextIndex;
      const span = `<span class="found-text" data-active-index="${isActive}" style="background:${isActive ? '#FFA80066' : '#FFA80033' }; opacity: 1">${match}</span>`;
      currentActiveIndex++;  // Увеличиваем activeIndex при каждом проходе, для подсведки выделенного слова, если в тексе их несколько
      return span;
    };

    // Разделяем текст на части, учитывая HTML-теги
    const parts = text.split(/(<[^>]*>)/);

    // Проходим по каждой части и заменяем совпадения внутри текстовых частей
    const highlightedParts = parts.map(part => {
      if (part.startsWith('<')) {
        // Если это тег, возвращаем как есть
        return part;
      } else {
        // Если это текст, заменяем совпадения
        return part.replace(regex, replaceFn);
      }
    });
    return highlightedParts.join('');
  }
  return text;
}


// text - string
// isSearch - boolean, включает поиск по маркдауну
// searchText - string текст, который необходимо искать
export const MarkDownText = (props) => {
  const {text, isSearch, searchText, className,activeIndex,activeTextIndex, ref,id} = props;
  const processedText = (text || '').replace(/-\s\[\s\]/g, '-[ ]')
    .replace(/-\s\[[xX]\]/g, '-[x]');

  const md = require('markdown-it')('default',{
    html: true,
    linkify: true,
    typographer: true,
    langPrefix:   'language-',
    breaks: true
  })
    .use(require('markdown-it-checkbox'),{
      divWrap: true,
      divClass: 'markdown-checkbox',
      idPrefix: `markdownChk_${id}`
    })

  const defaultRender = md.renderer.rules.paragraph_open || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

// Переопределяем рендерер для абзацев
  md.renderer.rules.paragraph_open = function (tokens, idx, options, env, self) {
    tokens[idx].attrPush(['class', 'markdown-paragraph']);
    return defaultRender(tokens, idx, options, env, self);
  };


  const stringHTML = md.render(processedText || '');

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html:  Boolean(isSearch) ?  highlightMatches(stringHTML, searchText, activeIndex,activeTextIndex) : stringHTML }}
    />
  );
};
