import React, {Fragment, useEffect, useRef, useState} from "react";
import {DeletingIcon, EditIcon, EllipsisIcon, PlusIcon} from "../../../../../../image";

import ChangeVideoPerson from "../../../../../../shared/features/ChangeVideoPerson/ChangeVideoPerson";
import {getAuthorName} from "../../../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import UserInfoModal from "../../../Users/modals/userInfoModal/UserInfoModal";
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";
import {useLocation} from "react-router-dom";
import InfoTooltip from "../../../Profile/tooltip";
import {MarkDownText} from "../../../../../../shared/features/MarkDownText";
import AutocompleteUsers from "../../../../../../shared/features/autocomplete/AutocompleteUsers";
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {VIDEO_PANEL_TABS} from "../../const";
import i18n from "i18next";

function TextItem({
                      index,
                      item,
                      token,
                      videoPersons,
                      toggleAddProtocol,
                      changeVideoTime,
                      getTime,
                      currentItem,
                      scrollPosition,
                      videoId,
                      searchText,
                      UpdateTranscription,
                      GetVideoById,
                      handleScroll,
                      personsList,
                      activeTabs,
                      className,
                      foundedTexts,
                      activeTextIndex
                  }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});
    const [selectedText, setSelectedText] = useState('');
    const [showModal, setShowModal] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [editingText, setEditingText] = useState('')
    const [assigneePersonId, setAssigneePersonId] = useState(undefined);
    const [checkUserId, setCheckUserId] = useState(undefined);

    let disabledBtn = editingText === item?.text && item.assignee_id === assigneePersonId?.id

    const location = useLocation()
    const ref = useRef();
    const {t} = useTranslation();

    const getTextItem = activeTabs === VIDEO_PANEL_TABS.SUMMERY ? item?.summary : item?.text

    useEffect(() => {
        setShowTooltip(false)
        setTooltipPosition({top: 0, left: 0})
        setSelectedText('')
    }, [scrollPosition]);

    useEffect(() => {
        ref?.current?.offsetHeight &&
        handleScroll(null, (ref?.current?.offsetHeight * index) || 1)
    }, [currentItem]);

    useEffect(() => {
        document.addEventListener('click', toggleText, {capture: true});
        return () => {
            document.removeEventListener('click', toggleText)
        }
    }, []);

    const toggleText = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowTooltip(false)
        }
    }
    const handleMouseUp = () => {
        const selection = window.getSelection();
        const text = selection.toString().trim();
        if (text) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            setShowTooltip(true);
            setTooltipPosition({
                top: rect.top - (rect.height > 30 ? rect.height / 2 : rect.height),
                left: rect.left,
            });
            setSelectedText(text);
        } else {
            setShowTooltip(false);
        }
    };

    function Tooltip({show, position, content}) {
        if (!show) return null;
        let data = {
            ...item,
            text: content
        }
        return (
            <div
                style={{
                    top: position.top,
                    left: position.left,
                }}
                className={'text-tooltip'}
                onClick={(e) => {
                    token && toggleAddProtocol(e, data)
                }}
            >
                <PlusIcon/>
                {t('add_to_protocol')}
            </div>
        );
    }

    function toggleProtocol(e, item) {
        e.stopPropagation()
        toggleAddProtocol(e, {
          ...item,
          text: activeTabs === VIDEO_PANEL_TABS.SUMMERY ? `##### ${i18n.language === 'ru' ? 'Глава' : 'Chapter'} ${index + 1}. ${item?.text} \n ${item?.summary}` : item?.text,
        })
    }

    function addFavourites(e, item) {
        e.stopPropagation()
        toggleAddProtocol(e, {
            ...item,
            task_executor_person_id: item?.assignee_id || undefined,
            task_executor_name: !item?.assignee_id ? (item?.assignee_name || undefined) : undefined
        })
    }

    function toggleUserModal(id) {
        setCheckUserId(id);
        setShowModal(!showModal)

        if (showModal){
            GetVideoById(videoId)
        }
    }

    function getInputValue(e) {
        setEditingText(e.target.value)
    }

    function toggleUpdate() {
        setEditingText(item?.text)
        setIsEdited(true)
    }

    function onClose() {
        setEditingText('')
        setIsEdited(false)
    }

    function saveChanges() {
        setEditingText('')
        setIsEdited(false)

        UpdateTranscription(
            item?.id,
            undefined, undefined,
            {
                text: editingText,
                start_time: Number(item?.start_time),
                end_time: Number(item?.end_time || 0),
                assignee_id : assigneePersonId?.id  || undefined,
                assignee_name : assigneePersonId?.name  || undefined,
            })
    }

    function getAssigneePersonName(id) {
        const person = [...(personsList?.results || []), ...videoPersons];
        const personFind = person.find((item)=> item.id === id);

        return personFind ? `${personFind.first_name || ''} ${personFind.last_name || ''}`: `id-${id} ${t('unknown')}`
    }
  useEffect(() => {
    const handleClick = (event) => {
      const summaryElement = event.target.closest('summary');
      if (summaryElement) {
        const detailsElement = summaryElement.closest('details');
        if (detailsElement) {
          if (detailsElement.open) {
            summaryElement.textContent = 'Expand';
          }else{
            summaryElement.textContent = 'Collapse';
          }
        }
      }
    };

    const currentContainer = ref?.current;
    currentContainer?.addEventListener('click', handleClick);

    return () => {
      currentContainer?.removeEventListener('click', handleClick);
    };
  }, [ref]);


    return <div className={`texts__item ${item?.isMarked && currentItem ? 'active-text' : ''} ${className || ''}`}
                ref={ref}>
        <div className="texts__item__action">
             <div className="texts__item__action__time"
                                      onClick={() => {
                                          changeVideoTime(item?.start_time,item?.start_time)
                                      }}>
                {getTime(item?.start_time || 0)}
            </div>

            {token &&  <InfoTooltip
              elem={
                <button
                  className="texts__item__action__btn"
                  onClick={(e) => {
                      if(activeTabs !== VIDEO_PANEL_TABS.AI ){
                          toggleProtocol(e, item)
                      }else {
                          addFavourites(e, item)
                      }
                  }}>
                <PlusIcon/>
            </button>}
                                    title={t('add_to_favourites')}
                                    placement={'left'}/>}
        </div>
        <div className={`texts__item__text times_index_${index}`}>
            <div className={"texts__top-block"}>
                <div className={`author_name`}>
                    <span className={`author_name__text`} onClick={ () => {
                        if(activeTabs !== VIDEO_PANEL_TABS.SUMMERY){
                            toggleUserModal(item?.person_id)
                        }
                    }}>
                      {activeTabs === VIDEO_PANEL_TABS.SUMMERY ?
                        <MarkDownText
                          text={`${i18n.language === 'ru' ? 'Глава' : 'Chapter'} ${index+1}. ${item?.text}`}
                          isSearch={true}
                          searchText={searchText}
                          className={'text-markdown'}
                          item={item}
                          activeIndex={(foundedTexts || []).findIndex((item)=> item === index) + 1}
                          activeTextIndex={activeTextIndex}
                        />
                        :
                         <MarkDownText
                           text={
                               item.author
                                 ? item.author
                                 : getAuthorName(videoPersons?.find(i => i.id === item.person_id), item?.person_id)
                                 || `${t('unknown')} ${item?.person_id}`
                           }
                           isSearch={true}
                           searchText={searchText}
                           className={'text-markdown'}
                           item={item}
                           foundedTexts={foundedTexts}
                           activeIndex={(foundedTexts || []).findIndex((item)=> item === index)}
                           activeTextIndex={activeTextIndex}
                         />
                      }
                    </span>
                    {(token && activeTabs !== VIDEO_PANEL_TABS.SUMMERY) &&
                        <ChangeVideoPerson
                            videoPersons={videoPersons}
                            videoId={videoId}
                            textItem={item}
                        />
                    }
                </div>
                {!isEdited && activeTabs === VIDEO_PANEL_TABS.TEXTS &&
                    <DropdownMenu button={
                        <EllipsisIcon className={'icon-menu'}/>}
                                  closeAfterToggle={true}
                                  className="actions-menu"
                                  contentWidth={'176px'}
                    >
                        <div className={'menu-item'} onClick={(e) => {
                            e.stopPropagation()
                            toggleUpdate()
                        }}>
                            <EditIcon className={''}/> {t('button_edit')}
                        </div>
                        <div className={'menu-item delete'} onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <DeletingIcon className={''}/> {t('button_delete')}
                        </div>
                    </DropdownMenu>
                }
            </div>

            {
                !isEdited ?
                    <>
                      <span
                        onMouseUp={handleMouseUp}
                        className={`transcription-text ${ currentItem ? 'marked' : ''}`}
                      >
                      <MarkDownText
                          text={getTextItem}
                          isSearch={true}
                          searchText={searchText}
                          className="text-markdown"
                          item={item}
                          activeIndex={(foundedTexts || []).findIndex((item)=> item === index) + 1}
                          activeTextIndex={activeTextIndex}
                      />
                    </span>
                    {(activeTabs === VIDEO_PANEL_TABS.AI) && (item?.assignee_id  || item?.assignee_name) &&
                    <Fragment>
                        <div className="texts__assigne">
                            <span>{t('to')}</span>
                            <span className="texts__assigne-user" onClick={() => toggleUserModal(item?.assignee_id)}>
                                    @ { item?.assignee_id ? getAssigneePersonName(item?.assignee_id ) :( item?.assignee_name || '')}
                                </span>
                        </div>
                    </Fragment>
                    }
                    </>
                    : <div className="text_update">
                        <textarea
                            onChange={getInputValue}
                            value={editingText}
                            name="editingText"
                        />
                      {(activeTabs === VIDEO_PANEL_TABS.AI)  &&
                          <AutocompleteUsers
                            persons={videoPersons}
                            authorName={item?.assignee_id ? getAssigneePersonName(item?.assignee_id) : (item?.assignee_name || '') }
                            name={'author_name'}
                            placeholder={t('tasks_user_title')}
                            onChangeUser={setAssigneePersonId}
                            onChangeInput={(e)=>setAssigneePersonId(Number(e?.id)  ? e : {name: e?.target?.value} )}
                          />
                      }
                        <div className="update_buttons">
                            <button className={`close_btn`}
                                    onClick={onClose}
                            >
                                {t('button_cancel')}
                            </button>

                            <button className={`save_btn ${disabledBtn ? 'disabled' : ''}`}
                                    disabled={disabledBtn}
                                    onClick={() => {
                                        saveChanges()

                                        //change this method
                                        const urlParams = new URLSearchParams(location.search);
                                        const paramsUuid = urlParams.get('uuid')

                                        GetVideoById(videoId, paramsUuid)
                                    }}
                            >
                                {t('button_save')}
                            </button>
                        </div>
                    </div>
            }

        </div>

        <Tooltip show={showTooltip} position={tooltipPosition} content={selectedText}/>
        {showModal && <UserInfoModal show={showModal}
                                     closeModal={toggleUserModal}
                                     user={videoPersons.find(i => i.id === checkUserId)}
        />}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['personsList'])
};

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TextItem);