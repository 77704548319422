import React, {useEffect, useState} from 'react';
import {
	AvatarPlaceholder,
	ChatIcon,
	CloseIcon,
	OctopusIcon, PlayIcon,
	SearchIcon
} from "../../../../image";
import './SearchBlock.scss';
import {TABS} from "../Chat/Chat";
import {useDispatch, useSelector} from "react-redux";
import {ClearVideosSearch, GetUsersPersons, GetVideosSearch} from "../../../../redux/actions";
import {Loader} from "../../../features/Loader";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import VideoBlock from "../../../../pages/Dashboard/containers/Channels/VideoBlock/VideoBlock";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import Highlighter from "react-highlight-words";
import UserInfoModal from "../../../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import {Tooltip} from "antd";
import {findUserText} from "../../helpers";
import {formatDate, getAuthorName} from "../../../../utils/helperFunctions";
import dayjs from "dayjs";


const SearchBlock = (props) => {
	const {activeTab, setActiveTab} = props;
	const {t,i18n} = useTranslation();
	const dispatch = useDispatch();
	const [text, setText] = useState('');
	const [isStart, setIsStart] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const searchVideosInfo = useSelector(state => state.videos.searchVideos);
	const [showPersonsModal, setShowPersonsModal] = useState(false)
	const [currentUser, setCurrentUser] = useState(null);
	const usersList = useSelector(state => state.users.usersPersonsList.results || []);


	function openModal(data) {
		setShowPersonsModal(true)
		setCurrentUser(data)
	}
	function closeModal() {
		setShowPersonsModal(false)
	}

	const [showAll, setShowAll] = useState({
		videos: false,
		persons: false,
		transcriptions: false,
	});

	const changeText = (e) => {
		setText(e?.target?.value || '')
	}
	const searchVideos = async () => {
		try {
			setIsStart(false);
			setIsLoading(true);
			await dispatch(GetVideosSearch({
				search_query: text
			}));
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			console.error(e);
		}
	}

	function toggleShowAll(name) {
		setShowAll({
			...showAll,
			[name]: !showAll[name]
		})
	}

	const clearSearch = () => {
		setText('')
	}

	useEffect(() => {
		dispatch(GetUsersPersons({filter: 'all', limit: 1000}));
		return ()=> {
			dispatch(ClearVideosSearch())
		}
	}, []);

	return (
		<div className='root'>
			<div className="search-block">
				<div
					className="search-block__wrap   custom-scroll"
				>
					<div className="search-block__list">
						{isLoading ?
							<div className="search-block__preview">
								<Loader size={150}/>
							</div>
							: null}
						{!isLoading && isStart && !Boolean(searchVideosInfo?.videos?.length)  && !Boolean(searchVideosInfo?.persons?.length) ?
							<div className="search-block__preview">
								<span>{t('switch_info_text')}</span>
								<span>{t('switch_search_text')}</span>
							</div>
							: null}
						{!isLoading && !isStart && !Boolean(searchVideosInfo?.videos?.length) && !Boolean(searchVideosInfo?.persons?.length)  ?
							<div className="search-block__preview">
								<span>{t('no_found_search_item')}</span>
							</div>
							: null}
						{!isLoading ?
						<div className="content">
							{!!searchVideosInfo?.videos?.length &&
								<div className="videos">
									<div className="content__header">
										<span
											className="content__header--title"
										>
											{t('table_video')}
										</span>
										{(searchVideosInfo?.videos?.length || 0) > 2 &&
											<span
												className="content__header--count"
												onClick={(e) => {
													e.stopPropagation();
													toggleShowAll('videos');
												}}
											>
	                      {showAll.videos ? t('hide_all') : t('show_all')}&nbsp;
												{searchVideosInfo?.videos?.length}
                      </span>
										}
									</div>
									<div className="videos__list">
										{searchVideosInfo?.videos?.slice(0, showAll.videos ? searchVideosInfo?.videos?.length : 2)?.map(item => {
											return <Link to={{
												pathname: `/dashboard/videos/video/${item?.id}`,
												state: {
													routs: [
														{
															path: '/dashboard/videos/review',
															name: t('menu_item_video')
														},
														{
															path: '/dashboard/videos/review',
															name: t('menu_item_review')
														},
														{
															isBack: true,
															name: t('input_search')
														}
													]
												}
											}}
											             className="videos__list--item" key={item.id}>
												<VideoBlock
													video={item}
													badge={{}}
													isSearchText={text}

												/>
											</Link>
										})}

									</div>
								</div>
							}
							{!!searchVideosInfo?.persons?.length && <div className="search-persons">
								<div className="content__header">
									<span className="content__header--title">{t('menu_item_persons')}</span>
									{(searchVideosInfo?.persons?.length || 0) > 2 &&
										<span
											className="content__header--count"
											onClick={(e) => {
												e.stopPropagation();
												toggleShowAll('persons');
											}}
										>
                                             {showAll.persons ? t('hide_all') : t('show_all')}&nbsp;{searchVideosInfo?.persons?.length}
                                        </span>
									}
								</div>
								<div className="search-persons__list">
									{searchVideosInfo?.persons?.slice(0, showAll.persons ? searchVideosInfo?.persons?.length : 2)?.map(item => {
										return (
											<div
												key={item.id}
												className="search-persons__list-item"
												onClick={(e) => {
													e.stopPropagation();
													openModal(item)
												}}
											>
												{item?.avatar && item?.avatar !== "None"
													? <img src={generateMediaUrl(item?.avatar)} alt=""/>
													: <img src={AvatarPlaceholder} alt={""}/>
												}
											<div className="search-persons__list-item--wrap">
												<div
													className="search-persons__list-item--title">
													{item?.first_name ?
														<Highlighter
															highlightClassName="highlight-text"
															searchWords={[text]}
															autoEscape={true}
															textToHighlight={item?.first_name}
														/>
														: ''}
													{item?.last_name ?
														<Highlighter
															highlightClassName="highlight-text"
															searchWords={[text]}
															autoEscape={true}
															textToHighlight={item?.last_name}
														/>
														: ''}
												</div>
													<Highlighter
														className="find-text"
														highlightClassName="highlight-text find-text"
														searchWords={[text]}
														autoEscape={true}
														textToHighlight={findUserText(item?.id,usersList, text, i18n)}
													/>
											</div>
											</div>
										)
									})}
								</div>
							</div>}

							{!!searchVideosInfo?.transcriptions?.length &&
								<div className="transcriptions">
									<div className="content__header">
										<span className="content__header--title">{t('transcribed')}</span>
										{(searchVideosInfo?.transcriptions?.length || 0) > 2 &&
											<span
												className="content__header--count"
												onClick={(e) => {
													e.stopPropagation();
													toggleShowAll('transcriptions');
												}}
											>
                                            {showAll.transcriptions ? t('hide_all') : t('show_all')}&nbsp;{searchVideosInfo?.transcriptions?.length}
                                        </span>
										}
									</div>
									<div className="transcriptions__list">
										{searchVideosInfo?.transcriptions?.slice(0, showAll.transcriptions ? searchVideosInfo?.transcriptions?.length : 2)?.map(item => {
											return (
												<div className="transcriptions__list--item" key={item.id}>
													<div className="search_transcriptions__list--header">
														<p
															className="transcriptions__list--header-name"
															onClick={(e) => {
																e.stopPropagation();
																if(item?.person?.first_name && item?.person?.last_name) {
																	openModal(item?.person);
																}
															}
															}
														>
															{getAuthorName(item?.person, item?.person?.id, i18n.language)}
														</p>
														<Link
															to={{
																pathname: `/dashboard/videos/video/${item?.video?.id}/time/${item?.start_time}`,
																state: {
																	routs: [
																		{
																			path: '/dashboard/videos/review',
																			name: t('menu_item_video')
																		},
																		{
																			path: '/dashboard/videos/review',
																			name: t('menu_item_review')
																		},
																		{
																			isBack: true,
																			name: t('input_search')
																		}
																	]
																}
															}}
															className="transcriptions__list--header-video-name">
															<PlayIcon/>
															{item?.video?.description || ''}
														</Link>
													</div>
													<Link
														to={{
															pathname: `/dashboard/videos/video/${item?.video?.id}/time/${item?.start_time}`,
															state: {
																routs: [
																	{
																		path: '/dashboard/videos/review',
																		name: t('menu_item_video')
																	},
																	{
																		path: '/dashboard/videos/review',
																		name: t('menu_item_review')
																	},
																	{
																		isBack: true,
																		name: t('input_search')
																	}
																]
															}
														}}
														className="transcriptions__list--link">
														<Highlighter
															highlightClassName="highlight-text"
															searchWords={[text]}
															autoEscape={true}
															textToHighlight={item?.text}
														/>
													</Link>
													{item?.video?.created_at ?
														<div className="transcriptions__list--footer">
															<span>{formatDate(item?.video?.created_at, i18n.language)}, </span>
															<span>{dayjs(item?.video?.created_at).format('HH:mm:ss')}</span>
														</div>
														: null}
												</div>)
										})}
									</div>
								</div>}
						</div>
							: null}
					</div>
				</div>
			</div>
			<div className="chat__footer">
				<Tooltip
					placement="left"
					title={t('chat_tooltip_badge_message')}
				>
				<div className='chat__tabs'>
					<div
						className={`chat__tabs-item ${activeTab === TABS.MESSAGE ? 'chat__tabs--active' : ''}`}
						onClick={() => setActiveTab(TABS.MESSAGE)}
					>
						<OctopusIcon className='chat__tabs_icon-octopus'/>
					</div>
					<div
						className={`chat__tabs-item ${activeTab === TABS.SEARCH ? 'chat__tabs--active' : ''}`}
						onClick={() => setActiveTab(TABS.SEARCH)}
					>
						<SearchIcon className='chat__tabs_icon-search'/>
					</div>
				</div>
				</Tooltip>
				<div
					className="chat-input"
					tabIndex={0}
				>
					<ChatIcon className="chat-input__left-icon"/>
					<input
						value={text}
						onChange={changeText}
						className="chat-input__input custom-scroll"
						placeholder={t('input_search')}
						onKeyDown={(e) => {
							if(e.key === 'Enter') {
								searchVideos()
							}
						}}
					/>
					{text ?
						<CloseIcon className={`chat-input__right-icon`} onClick={clearSearch}/>
						: null}
				</div>
			</div>
			{showPersonsModal && <UserInfoModal show={showPersonsModal} closeModal={closeModal} user={currentUser}/>}

		</div>
	);
};

export default SearchBlock;