import {_urlChat, request} from "../api";

export const GetMessagesService = (id) => {
	let requestData = {
		url: `${_urlChat}?video_id=${id}`,
		method: "GET",
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				return res?.data;
			})
	};
};

export const SendMessageService = (data) => {
	let requestData = {
		url: `${_urlChat}`,
		method: "POST",
		data
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				return res?.data;
			})
	};
};
