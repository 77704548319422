import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import './ProtocolAuthorBlock.scss';


const ProtocolAuthorBlock = (props) => {
	const {protocolItem, onClick} = props;
	const {t} = useTranslation();
	const personsList = useSelector(state => state.videos.videoById?.persons || []);
	const isCheckPersonId = Boolean(protocolItem?.person_id);
	const isCheckTaskExecutorPersonId = Boolean(protocolItem?.task_executor_person_id);

	function getAssigneePersonName(id) {
		const personFind = personsList.find((item) => item.id === id);

		return (personFind?.first_name && personFind?.last_name) ? `${personFind.first_name || ''} ${personFind.last_name || ''}` : `id-${id} ${t('unknown')}`;
	}

	return (

		<div className="author_block">
			{(protocolItem?.author_name || isCheckPersonId) &&
				<div className={`author_block__name ${!isCheckPersonId ? 'author_block__name--disabled' : ''}`}
				     onClick={() => {
					     if(isCheckPersonId) onClick(protocolItem?.person_id)
				     }}
				>
					{
						protocolItem.author_name ? protocolItem.author_name
							: getAssigneePersonName(protocolItem.person_id)
					}
				</div>
			}
			{(isCheckTaskExecutorPersonId || protocolItem?.task_executor_name) && <>
				<span>{t('to')}</span>
				<div
					className={`author_block__to_name ${!isCheckTaskExecutorPersonId ? 'author_block__to_name--disabled' : ''}`}
					onClick={() => {
						if(isCheckTaskExecutorPersonId) onClick(protocolItem?.task_executor_person_id)
					}}
				>
					{isCheckTaskExecutorPersonId ? '@' : ''}
					{
						protocolItem?.task_executor_name ? protocolItem.task_executor_name
							: getAssigneePersonName(protocolItem?.task_executor_person_id)
					}
				</div>
			</>}
		</div>
	);
};

export default ProtocolAuthorBlock;