import {combineReducers} from "redux";

// import reducers
import videos from './videos';
import utils from './utils';
import auth from './auth';
import persons from './persons';
import protocol from './protocol';
import channels from './channels';
import users from './users';
import profile from './profile';
import tags from './tags';
import highlights from './highlights';
import userCalendars from './userCalendars';
import roles from './roles';
import link from './link';
import notification from './notification';
import chains from './chains';

export default combineReducers({
    videos,
    utils,
    auth,
    persons,
    protocol,
    users,
    channels,
    profile,
    tags,
    highlights,
    userCalendars,
    roles,
    link,
    notification,
    chains
});
