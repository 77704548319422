// Import packages
import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Route, useRouteMatch} from "react-router-dom";

// Import assets
import './videos.scss';

// Import Components
import ManageVideo from "./ManageVideo/ManageVideo";
import Channels from "../Channels/Channels";
import VideoDetails from "../VideoDetails/VideoDetails";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {GetPersons, GetRoles} from "../../../../redux/actions";

function Videos(props) {
    const {path} = useRouteMatch();

    useEffect(() => {
        // props.GetPersons({
        //     status: 'known',
        //     limit: 1000
        // })
        props.GetRoles()
    }, [])

    return (
        <>
            <Route path={`${path}/review`} component={Channels}/>
            <Route path={`${path}/manage`} component={ManageVideo}/>

            <Route path={`${path}/video/:videoId/(time)?/:time?`} component={VideoDetails} />
        </>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {GetPersons, GetRoles};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
