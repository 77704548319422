// let API_BASE_URL =  "http://80.254.127.105:34568/api/v1";
export let HOST_NAME =  window.location.origin;
let DEMO_URL = 'https://develop.secretopus.ai'
let API_BASE_URL =  window.location.origin + '/api/v1';


if (process.env.NODE_ENV === 'development') {
    API_BASE_URL = DEMO_URL + '/api/v1';
    HOST_NAME = DEMO_URL;
}

export const _hostMediaUrl = HOST_NAME;
export const _urlChannels = API_BASE_URL + "/channels";
export const _urlVideos = API_BASE_URL + "/videos";
export const _urlProtocol = API_BASE_URL + "/protocol";
export const _urlAuth = API_BASE_URL + "/auth";
export const _urlPersons = API_BASE_URL + "/persons";
export const _urlTranscriptions = API_BASE_URL + "/transcriptions";
export const _urlShare = API_BASE_URL + "/share_links";
export const _urlUsers = API_BASE_URL + "/users";
export const _urlUsersPersons = API_BASE_URL + "/users_persons";
export const _urlProfile = API_BASE_URL + "/profile";
export const _urlTags = API_BASE_URL + "/tags";
export const _urlHighlights = API_BASE_URL + "/highlights";
export const _urlServiceStatuses = API_BASE_URL + "/service_statuses";
export const _urlUserCalendars = API_BASE_URL + "/user_calendars";
export const _urlRoles = API_BASE_URL + "/roles";
export const _urlLink = API_BASE_URL + "/share_links";
export const _urlNotification = API_BASE_URL + "/user_notifications";
export const _urlSignatures = API_BASE_URL + "/signatures";
export const _urlChains = API_BASE_URL + "/chains";

export const _urlChat = API_BASE_URL + "/chat";

