// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './change-video-person.scss';
import {CheckIcon, EditIcon, PlusGreenIcon} from "../../../image";

// Import Components
import DropdownMenu from "../dropdownMenu";
import {Form} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {GetVideosSearch, GetVideoById, UpdateTranscription, UpdateTranscriptionAll} from "../../../redux/actions";
import {getAuthorName} from "../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import debounce from "lodash.debounce";
import {Loader} from "../Loader";

function ChangeVideoPerson(props) {
    const {videoPersons, videoId, textItem} = props;
    const {t, i18n} = useTranslation();
    const [checkedType, setCheckedType] = useState(true);
    const [selectedPerson, setSelectedPerson] = useState(undefined);
    const [showAddPerson, setShowAddPerson] = useState(false);
    const [persons, setPersons] = useState(undefined);
    const [videoPersonsList, setVideoPersonsList] = useState(undefined);
    const [text, setText] = useState('');
    const [menuIsClosed, setMenuIsClosed] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const controllerRef = useRef(null);

    useEffect(() => {
        setPersons(videoPersons)
        setVideoPersonsList(videoPersons)
    }, [])

    const selectAuthor = (e, author) => {
        setSelectedPerson(author?.id !== selectedPerson?.id ? author : undefined)
    }

    const changeAuthorName = () => {
        if (checkedType) {
            props.UpdateTranscription(textItem.id, selectedPerson, textItem)
        } else {
            props.UpdateTranscriptionAll({
                video_id: videoId,
                old_person_id: textItem.person_id,
                new_person_id: selectedPerson.id
            })
                .then(() => {
                    props.GetVideoById(videoId)
                })
        }
        setMenuIsClosed(!menuIsClosed)
    }

    const createSignal = () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        controllerRef.current = controller;
        return signal;
    }

    const getSearchValue = React.useCallback(debounce(async (value) => {
        try {
            setIsLoader(true);
            const response = await props.GetVideosSearch({
                search_query: value,
                exclude_videos: false,
                exclude_transcriptions: false,
                exclude_persons: false
            }, true, createSignal())
            setIsLoader(false);

            const findUsers = response?.persons || [];
            const filteredVideoPersons = persons
              ?.filter(item => item.first_name !== 'unrecognized')
              .filter((item) => `${item.first_name} ${item.last_name}`?.toLowerCase()?.includes(value?.toLowerCase()))

            setPersons(findUsers.length ? findUsers : filteredVideoPersons)

        } catch (error) {
            if(error?.code !== "ERR_CANCELED"){
                setIsLoader(false);
            }
            console.warn(error)
        }
    }, 500), [])


    function handleClosePersonsMenu() {
        setSelectedPerson(undefined)
        setCheckedType(true)
        setMenuIsClosed(false)
    }
    function handleCloseAddMenu() {}

    return <DropdownMenu button={<EditIcon className="edit_person_icon"/>}
                         className={'persons-menu'}
                         contentWidth={'326px'}
                         closeDropdown={menuIsClosed}
                         placement={'bottom'}
                         onClose={handleClosePersonsMenu}
    >
        <div className="header">
            <Form>
                <Form.Check
                    type="radio"
                    id="switch-one"
                    label={t('check_only_this')}
                    checked={checkedType}
                    onChange={(e) => {
                        setCheckedType(e.target.checked)
                    }}
                />
                <Form.Check
                    type="radio"
                    label={t('check_everyone')}
                    id="switch-everyone"
                    checked={!checkedType}
                    onChange={(e) => {
                        setCheckedType(!e.target.checked)
                    }}
                />
            </Form>
        </div>
        <DropdownMenu
            button={showAddPerson
                ?
              <div className={"input-wrap"}>
              <input type="text"
                         value={text}
                         name={'text'}
                         onChange={(e)=>{
                             setText(e?.target?.value || '');
                             getSearchValue(e?.target?.value || '')
                         }}
                         placeholder={t('input_search')}
                />
                  {isLoader ? <Loader className={"input-wrap-loader"} size={20}/> : null}
              </div>
                : <div className="add-person"
                       onClick={() => setShowAddPerson(true)}>
                    <PlusGreenIcon/> {t('add_person')}
                </div>}
            onClose={handleCloseAddMenu}
            className={'add-persons-dropdown'}
            closeAfterToggle={true}
            fullWidth={false}>
            {!!persons?.length && <div className="persons_list">
                {
                    persons?.map(item => {
                        return <div className="person_item"
                                    onClick={() => {
                                        setVideoPersonsList([...videoPersonsList, item])
                                        setSelectedPerson(item)
                                        setShowAddPerson(false)
                                    }}
                                    key={item?.id}>
                            {item?.id === selectedPerson?.id && <CheckIcon className="person-check"/>}
                            <img
                                src={generateMediaUrl(item?.avatar ? item?.avatar : item?.image_path)}
                                key={item.id}
                                alt=""
                            />
                            <div className="person_info">
                                <div className="name">
                                    {getAuthorName(item, null, i18n.language)}
                                </div>
                                <div className="email">
                                    {item.email || 'user@gmail.com'}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}
        </DropdownMenu>
        <div className="persons_list">
            {
                videoPersonsList?.map(item => {
                    return <div key={item.id} className="person_item"
                                onClick={(e) => selectAuthor(e, item)}
                    >
                        <Form.Check
                            type="radio"
                            id="switch-one"
                            // label={t('check_only_this')}
                            checked={item?.id === selectedPerson?.id}
                            onChange={(e) => {
                                setCheckedType(e.target.checked)
                            }}
                        />
                        {/*{item?.id === selectedPerson?.id && <CheckIcon className="check_icon"/>}*/}
                        <img
                            src={generateMediaUrl(item?.avatar ? item?.avatar : item?.image_path)}
                            key={item.id}
                            alt=""
                        />
                        <div className="person_info">
                            <div className="name">
                                {getAuthorName(item, null, i18n.language)}
                            </div>
                            <div className="email">
                                {item.email || 'user@gmail.com'}
                            </div>
                        </div>
                    </div>
                })
            }

        </div>


        <div className="buttons">
            <button className={'cancel-btn'}
                    onClick={handleClosePersonsMenu}
            >{t('button_cancel')}
            </button>
            <button className={'save_btn'} onClick={changeAuthorName}>
                {t('button_save')}
            </button>
        </div>
    </DropdownMenu>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    GetVideosSearch,
    GetVideoById,
    UpdateTranscription,
    UpdateTranscriptionAll
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVideoPerson);
