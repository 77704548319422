// Import packages
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {Accordion, Modal, Tab, Tabs} from "react-bootstrap";
import {useParams} from "react-router-dom";

// Import assets
import './userInfoModal.scss';
import {
    ArrowDownIcon, CloseIcon,
    EyeIcon,
    InfoCheckIcon, InfoIcon,
} from "../../../../../../image";

//Import Components
import TabsDropdown from "../../../../../../shared/features/tabsDropdown/TabsDropdown";
import ProfileMedia from "../../../Profile/media";
import ProfileContacts from "../../../Profile/contacts";
import UserRoles from "../../../../../../shared/profile/UserRoles";
import ParticipationData from "../../../Profile/ParticipationData";
import InfoTooltip from "../../../Profile/tooltip";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {
    GetVideos,
    GetPersonById,
    UpdatePersons,
    UpdateUser,
    GetUserById,
    GetHighlights,
    ClearPersonById,
    ClearUserById, GetUsersPersons, GetVideosService
} from "../../../../../../redux/actions";
import {history} from "../../../../../../utils/history";
import {profileErrorMessages} from "../../../../../../utils/constants";
import {useTranslation} from "react-i18next";
import config from "../../../../../../utils/config"
import {validateData} from "../../../Profile/helpers/helpers";
import {hasPermission} from "../../../../../../utils/helperFunctions";
import UserSignatures from "../../../../../../shared/profile/UserSignatures/UserSignatures";
import {isCheckContactInfo, isCheckUserInfo} from "./helper";

const initialState = {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    position: undefined,
    login: undefined,
    password: undefined,
    note: undefined,
}
const initialContactState = {
    email: [''],
    phone_number: [''],
    linkedin: [''],
    facebook: [''],
}
const initialErrors = {
    login: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: [],
    email: [],
    linkedin: [],
    facebook: [],
}

function UserInfoModal(props) {
    const {show, personById, userById, highlights, rolesList} = props;

    const [userInfo, setUserInfo] = useState(initialState);
    const [types, setTypes] = useState({
        user: true,
        participant: false,
    });
    const [contactItems, setContactItems] = useState(initialContactState);
    const [errors, setErrors] = useState(initialErrors);
    const [videoList, setVideoList] = useState(null);
    const [user, setUser] = useState(null);
    // const [isCopied, setIsCopied] = useState(false);
    const [haveChanges, setHaveChanges] = useState(new Map());
    const [isAdmin, setIsAdmin] = useState(false);
    const [userRole, setUserRole] = useState(undefined);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [findUserInfo, setFindUserInfo] = useState();

    const params = useParams();

    const {t} = useTranslation();

    useEffect(() => {
        let paramsUser = null
        params?.personId && (paramsUser = {
            ...paramsUser,
            person_id: params?.personId,
        })
        params?.userId && (paramsUser = {
            ...paramsUser,
            user_id: params?.userId,
        })
        setUser(paramsUser ? paramsUser : props.user)
    }, [props?.user])

    const getInfo = async () => {
        try{
            if(user && !user?.user_id && show){
                const response = await props.GetUsersPersons({filter: 'all', limit: 1000}, true);
                const users = response?.results || [];
                const userPersonId = user?.person_id || user?.id;
                const findUser = users.find((item) => item?.person_id === userPersonId);
                setFindUserInfo(findUser || {});
                if(findUser?.user_id){
                    await props.GetUserById(findUser?.user_id).then((results) => {
                        setUserInfo(isCheckUserInfo(userInfo, personById, results));
                    })
                }
            }else if (show && user && user?.user_id ){
                setFindUserInfo(user || {});
                await props.GetUserById(user?.user_id || user?.id).then((results) => {
                    setUserInfo(isCheckUserInfo(userInfo, personById, results));
                })
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getInfo();
    }, [user, show]);

    useEffect(() => {
        if (user?.person_id || user?.user_id || user?.id) {
            if(user?.person_id || user?.id){
                props.GetPersonById(user?.person_id || user?.id).then((results)=>{
                    setUserInfo(isCheckUserInfo(userInfo, results, userById))
                    setContactItems(isCheckContactInfo(contactItems, results))
                })
            }
            props.GetVideosService({
                person_id: (user?.person_id || user?.id) ? (user?.person_id || user?.id) : user?.user_id,
                limit: 1000
            }, true).then(rec => {
                setVideoList(rec)
            })

            let reqData = {}
            user?.id && (reqData.person_id = user?.id)
            user?.person_id && (reqData.person_id = user?.person_id)
            setTypes({
                user: !!user?.user_id,
                participant: (!!user?.person_id || !!user?.id),
            })
            props.GetHighlights(reqData)
        }
    }, [user?.user_id, user?.person_id, user?.id, show])

    useEffect(() => {
        if (user?.person_id || user?.user_id || user?.id) {
            setIsAdmin(userById?.is_admin)
            setUserRole(userById?.roles?.[0])
        }
    }, [userById, user?.person_id, user?.id, show])

    useLayoutEffect(() => {
        props.ClearPersonById()
        props.ClearUserById()
    }, [])

    function checkValidation(data) {
        setErrors(initialErrors)

        const result = validateData({
            data,
            initialErrors,
            t,
            id: personById?.associated_user?.id
        })
        setErrors(result.errors)
        return result.isValid
    }

    function clearHaveChanges() {
        const changes = new Map(haveChanges);
        changes.clear()
        setHaveChanges(changes)
    }

    function saveChanges() {
        let recData = {};
        setErrors(initialErrors)
        userInfo?.first_name && (recData.first_name = userInfo.first_name)
        userInfo?.last_name && (recData.last_name = userInfo.last_name)
        if (user?.person_id || user?.id) {
            if (userInfo?.position !== undefined && userInfo?.position !== null){
                recData.position = userInfo.position;
            }
            if (userInfo?.note !== undefined && userInfo?.note !== null){
                recData.note = userInfo.note
            }
            if (userInfo?.organization !== undefined && userInfo?.organization !== null){
                recData.organization = userInfo.organization
            }

            Object.entries(contactItems).forEach(([key, value]) => {
                // let item = value.filter(i => !!i)
                // item.length && (recData[key] = item)
                recData[key] = value.filter(i => !!i)
            })

            if ((types.user || types.participant) && !personById?.associated_with_user) {

                userInfo?.login && (recData.user = {
                    ...recData?.user,
                    email: userInfo?.login
                })
                userInfo?.password && (recData.user = {
                    ...recData?.user,
                    password: userInfo?.password
                })
            }
            if (checkValidation(recData)) {

                props.UpdatePersons(user.person_id || user?.id, recData, userInfo)
                    .then(() => {
                        onClose()
                        clearHaveChanges()
                    })
                    .catch(() => {
                        setErrors({
                            ...errors,
                            login: profileErrorMessages?.login
                        })
                    })
                onClose()
                clearHaveChanges()
            }
        }

        if (findUserInfo?.user_id || isAdmin !== userById?.isAdmin) {
            let recData = {
                is_admin: isAdmin,
                role_ids: (userRole?.id && userRole?.id !== findUserInfo?.role_id) ? [userRole?.id] : undefined,
                last_name: userInfo?.last_name && userInfo?.last_name !== findUserInfo?.last_name  ? userInfo?.last_name : undefined,
                first_name: (userInfo?.first_name && userInfo?.first_name !== findUserInfo?.first_name) ? userInfo?.first_name : undefined,
                login: (userInfo?.login && userInfo?.login !== findUserInfo?.login) ? userInfo?.login : undefined,
                password: (userInfo?.password ) ? userInfo?.password : undefined,
            }
            props.UpdateUser(findUserInfo?.user_id, recData)
            clearHaveChanges()
            onClose()
        }
    }

    function getInputValue(e) {
        const {name, value} = e.target
        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
        const changes = new Map(haveChanges);
        if ((personById?.[name] || userById?.[name] || '') !== value) {
            changes.set(name, value)
        } else {
            changes.delete(name)
        }
        setHaveChanges(changes)
    }

    function onClose() {
        setUserInfo(initialState)
        setErrors(initialErrors)
        setContactItems(initialContactState)
        setUser(null)
        // setDataFile(null)
        if (params?.personId || params?.userId || params?.id) {
            history.push('/dashboard/users/persons')
        }
        props.ClearPersonById()
        props.ClearUserById()
        props.closeModal()
    }

    /*   function copyPersonLink() {
           let url = `${HOST_NAME}/dashboard/users/persons`;
           user?.user_id && (url = url + `/userId/${user?.user_id}`)
           user?.person_id && (url = url + `/personId/${user?.person_id}`)
           user?.id && (url = url + `/personId/${user?.id}`)

           navigator.clipboard.writeText(url);
           setIsCopied(true)
       }*/

    function toggleRole(e, role) {
        e.stopPropagation()
        setUserRole(role)

        const changes = new Map(haveChanges);
        if (userById?.roles?.[0].id !== role?.id) {
            changes.set('userRole', role?.id)
        } else {
            changes.delete('userRole')
        }
        setHaveChanges(changes);
    }

     return (personById || userById) && <Modal
        show={show}
        onHide={onClose}
        size="lg"
        centered
        className={'info_modal'}
        onWheel={(e)=>e.stopPropagation()}
    >
        <div className="user_info_modal">
            <div className="user-header">
                <p className="title">{t('profile_page_title')}
                    {/*{token && <span className={'copy-link'}*/}
                    {/*                onClick={copyPersonLink}>{isCopied ? t('profile_copied') : t('profile_link')}</span>}*/}
                </p>
                <CloseIcon onClick={onClose}/>
            </div>
            <div className="user_info_modal__wrapper">
                <div className="user_info_modal__media">
                    <ProfileMedia personById={personById}/>

                    <TabsDropdown user={user} personById={personById} show={show}/>

                </div>
                <div className="user_info_modal__content">
                    <form autoComplete={'off'} className={'input-form'}>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_name')}</label>
                                <input type="text"
                                       value={userInfo?.first_name}
                                       autoComplete={'off'}
                                       name={'first_name'}
                                       onChange={getInputValue}
                                       placeholder={t('input_name')}
                                />
                                {!!errors.first_name && (
                                    <div className="input_group__error">{errors.first_name}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_surname')}</label>
                                <input type="text"
                                       value={userInfo?.last_name}
                                       name={'last_name'}
                                       autoComplete="off"
                                       onChange={getInputValue}
                                       placeholder={t('input_surname')}
                                />
                                {!!errors.last_name && (
                                    <div className="input_group__error">{errors.last_name}</div>
                                )}
                            </div>
                        </div>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_company')}</label>
                                <input type="text"
                                       value={userInfo?.organization}
                                       name={'organization'}
                                       disabled={user?.company?.id || (!user?.person_id && !user?.id)}
                                       onChange={getInputValue}
                                       placeholder={t('input_company')}
                                />
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_position')}</label>
                                <input type="text"
                                       value={userInfo?.position}
                                       name={'position'}
                                       disabled={(!user?.person_id && !user?.id)}
                                       onChange={getInputValue}
                                       placeholder={t('input_position')}
                                />
                            </div>
                        </div>
                        <div className="input_group full">
                            <label htmlFor="">{t('input_notes')}</label>
                            <textarea value={userInfo?.note}
                                      name={'note'}
                                      disabled={(!user?.person_id && !user?.id)}
                                      onChange={getInputValue}
                                      placeholder={t(config.productName)}
                            />
                        </div>
                    </form>
                </div>
            </div>

            <ProfileContacts contactItems={contactItems}
                             setContactItems={setContactItems}
                             errors={errors}
                             setErrors={setErrors}
                             haveChanges={haveChanges}
                             setHaveChanges={setHaveChanges}
                             personById={personById}/>

            <div className="settings_tab">
                <Tabs
                    defaultActiveKey="0"
                    id="uncontrolled-tab-example">
                    <Tab eventKey="0"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                         {t('profile_info_title')}
                                 {!(videoList?.count || highlights?.length) ?
                                     <InfoTooltip title={t('tooltip_info_title')}
                                                  description={t('tooltip_info_description')}
                                     /> : <InfoCheckIcon/>}
                    </span>
                         }>
                        <ParticipationData videosList={videoList}
                                           user={user}
                                           highlights={highlights}
                                           avatar={personById?.avatar}
                                           userRoles={props.userRoles}
                                           setVideoList={setVideoList}
                                           closeModal={props.closeModal}
                        />
                        <Accordion defaultActiveKey="1" className={'contacts-wrapper system'}>
                            <Accordion.Item eventKey="0" className={'profile-page__contacts'}>
                                <Accordion.Header
                                  className={`contact-header `}>
                                    <ArrowDownIcon className={`arrow-icon`}/>
                                    {t('signature_vks')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <UserSignatures userID={user?.person_id || user?.id || 0}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Tab>
                    <Tab eventKey="1"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                         {t('profile_settings_title')}
                                 {!(personById?.associated_user?.login || userById?.login) ?
                                     <InfoTooltip title={t('tooltip_settings_title')}
                                                  description={t('tooltip_settings_description')}/>
                                     : <InfoCheckIcon/>}
                    </span>
                         }>
                        <div className="user-info-settings">
                            {hasPermission('users_update') ? <>
                                {!personById?.id && <p>{t('profile_settings_desc')}</p>}
                                <div className="input_group">
                                    <label htmlFor="">{t('input_login')}</label>
                                    <input type="text"
                                           value={userInfo?.login}
                                           name={'login'}
                                           onChange={getInputValue}
                                           placeholder={t('input_login')}
                                           autoComplete={'off'}
                                    />
                                    {!!errors.login && (
                                        <div className="input_group__error">{errors.login}</div>
                                    )}
                                </div>
                                <div className="input_group">
                                    <label htmlFor="">{t('input_password')}</label>
                                    <input
                                        type={`${passwordVisible ? "text" : "password"}`}
                                        value={userInfo?.password}
                                        name={'password'}
                                        onChange={getInputValue}
                                        placeholder={t('input_password')}
                                        autoComplete="new-password"
                                    />
                                    <EyeIcon className="input-icon"
                                             onClick={() => setPasswordVisible(!passwordVisible)}/>
                                    {!!errors.password && (
                                        <div className="input_group__error">{errors.password}</div>
                                    )}
                                </div>
                            </> : <>
                                <p>{t('no_access_to_update_user')}</p>
                                <div className="warning-info">
                                    <InfoIcon/>
                                    <span>{t('no_access_to_update_user_warning')}</span>
                                </div>
                            </>}
                        </div>
                    </Tab>
                </Tabs>
            </div>

            {((personById?.associated_user?.login || userById?.login))
                && <Accordion defaultActiveKey="0" className={'contacts-wrapper system'}>
                    <Accordion.Item eventKey="0" className={'profile-page__contacts'}>
                        <Accordion.Header
                            className={`contact-header`}>
                            <ArrowDownIcon className={`arrow-icon`}/>
                            {t('profile_roles')}
                        </Accordion.Header>
                        <Accordion.Body>
                            {
                                !!userById?.roles?.length ?
                                    <UserRoles rolesList={rolesList}
                                               toggleRole={toggleRole}
                                               userRole={userRole}
                                    /> :
                                    null
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>}
        </div>
        <div className="buttons-wrapper">
            <button className={`close_btn`} onClick={onClose}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn ${!haveChanges.size ? 'disabled' : ''}`}
                    disabled={!haveChanges.size}
                    onClick={saveChanges}
            >
                {t('button_save_changes')}
            </button>
        </div>
    </Modal>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personById', 'userById', 'highlights', 'token', 'rolesList', 'userRoles'
    ])
};

const mapDispatchToProps = {
    UpdatePersons,
    GetPersonById,
    GetVideos,
    UpdateUser,
    GetUserById,
    GetHighlights,
    ClearPersonById,
    ClearUserById,
    GetUsersPersons,
    GetVideosService
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoModal);
