import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {ArrowTopCircleIcon, ChatIcon, CleanIcon, CopyIcon, OctopusIcon, PlusIcon, SearchIcon} from "../../../../image";
import './MessageBlock.scss';
import {TABS} from "../Chat/Chat";
import {useDispatch, useSelector} from "react-redux";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {Loader} from "../../../features/Loader";
import {Tooltip} from "antd";
import {copyToClipboard} from "../../../../utils/helperFunctions";
import {GetMessagesService, SendMessageService} from "../../../../redux/actions/chatActions";
import {getTimeDifference, preparedMessages} from "../../helpers";
import {AddProtocol, GetProtocol} from "../../../../redux/actions";
import {MarkDownText} from "../../../features/MarkDownText";
import {useTranslation} from "react-i18next";

const DefaultMessages = (i18n) => [
	{
		response_text: i18n.language === 'en'
			? "Hi! I\'m Secretopus AI bot. I can help you find key information from your conversations without the need to go through lengthy chat logs."
			: "Привет! Я AI-бот Secretopus. Я могу помочь вам найти ключевую информацию из ваших разговоров без необходимости просматривать длинные журналы чатов."
	},
	{
		response_text: i18n.language === 'en'
			? 'Currently, I can search within specific meetings. Soon, I\'ll be able to search across multiple sections!'
			: 'В настоящее время я могу выполнять поиск по конкретным собраниям. Скоро я смогу выполнять поиск по нескольким разделам!'
		,
	},
	{
		response_text: i18n.language === 'en'
			? 'To enable global search mode, simply tab the switch next to the search bar.'
			: 'Чтобы включить режим глобального поиска, просто нажмите на переключатель рядом со строкой поиска.'
		,
	}
];

const MessageBlock = (props) => {
	const {activeTab, setActiveTab, isVisibleChat} = props;
	const [text, setText] = useState('');
	const userInfo = useSelector(state => state.profile.userProfile);
	const userAvatar = useSelector(state => state.profile.userAvatar);
	const videoInfo = useSelector(state => state.videos.videoById);
	const listRef = useRef(null);
	const textAreaRef = useRef(null);
	const [isLoadingMessage, setIsLoadingMessage] = useState(false);
	const [error, setError] = useState(false);
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const [messages, setMessages] = useState(preparedMessages(DefaultMessages(i18n), [], userInfo));
	const isCheckMessages = messages.length <= 3;

	const changeText = (e) => {
		setText(e?.target?.value || '')
	}

	const addText = async () => {
		try {
			if(text) {
				setError(null);
				setMessages([...messages, {
					id: 100,
					isBot: false,
					text,
					date: dayjs(),
					used_db_query: false,
					author_name: `${userInfo?.last_name || ''} ${userInfo?.first_name || ''}`,
				}]);
				setIsLoadingMessage(true);
				await dispatch(SendMessageService({
					video_id: videoInfo?.id,
					request_text: text,
					clear_session: false
				}))
				const response = await dispatch(GetMessagesService(videoInfo?.id));
				setMessages(preparedMessages(DefaultMessages(i18n), response?.chat || [], userInfo));

				setText('');
				textAreaRef.current.style.height = '20px';
				setIsLoadingMessage(false);
			}
		} catch (e) {
			setError(e);
			setIsLoadingMessage(false)
		}
	}

	const addFavorites = async (value) => {
		try {
			await dispatch(AddProtocol({
				video_id: videoInfo?.id,
				text: value,
				start_time: 0,
				person_id: userInfo?.attached_person?.id
			}));
			await dispatch(GetProtocol(videoInfo?.id));
		} catch (e) {
			console.error(e)
		}
	}

	const getMessages = async () => {
		try {
			const response = await dispatch(GetMessagesService(videoInfo?.id));
			setMessages(preparedMessages(DefaultMessages(i18n), response?.chat || [], userInfo))
		} catch (e) {
			setError(e);
			console.error(e);
		}
	}

	const clearChat = async () => {
		try {
			if(!isCheckMessages) {
				await dispatch(SendMessageService({
					video_id: videoInfo?.id,
					clear_session: true
				}));
				const response = await dispatch(GetMessagesService(videoInfo?.id));
				setMessages(preparedMessages(DefaultMessages(i18n), response?.chat || [], userInfo))
			}
		} catch (e) {
			setError(e);
			console.error(e);
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			// Если нажата клавиша Enter без Shift
			if (!e.shiftKey) {
				e.preventDefault(); // Предотвращаем перенос строки
				addText();
			}
		}
	};

	useEffect(() => {
		function updateHeightTextArea() {
			this.style.height = '16px';
			this.style.overflow = 'hidden';
			this.style.height = this.scrollHeight - 4 + 'px';
		}

		textAreaRef.current?.addEventListener('input', updateHeightTextArea);

		return () => {
			textAreaRef.current?.removeEventListener('input', updateHeightTextArea);

		}
	}, []);

	useLayoutEffect(() => {
		if(listRef.current) {
			listRef.current.scrollTop = listRef.current.scrollHeight;
		}
	}, [messages, isLoadingMessage]);

	useEffect(() => {
		if(activeTab === TABS.MESSAGE) {
			getMessages();
		}
	}, []);

	return (
		<>
			<div className={`clean-chat ${isCheckMessages ? 'clean-chat--disabled' : ''}`}>
				<CleanIcon onClick={clearChat}/>
				<span
					onClick={clearChat}
				>
					{t('chat_clean_btn')}
				</span>
			</div>
			<div className="message-block">
				<div
					className="message-block__wrap   custom-scroll"
					ref={listRef}
				>
					<div className="message-block__list">
						{messages.map((item) => (
							<div className="message">
								{item?.isBot ?
									<div className="message__avatar-bot">
										<OctopusIcon className='message__avatar-bot--icon'/>
									</div>
									:
									<img src={generateMediaUrl(userAvatar)} alt=""/>
								}
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">{item.author_name}</div>
										<div className="message__block_title--date">
											{item.date ? <span>{getTimeDifference(item.date, i18n)}</span> : null}
											{item?.used_db_query ? <span>&#32;&#32;●&#32;&#32;used DB query</span> : null}
										</div>
									</div>
									<MarkDownText
										className="message__block--text"
										text={item.text}
									/>
									<div className="message__block_icons">
										<Tooltip placement="top" title={'Copy'}>
											<CopyIcon
												onClick={() => copyToClipboard(item.text)}
												className="message__block_icons--copy"
											/>
										</Tooltip>
										{item?.isBot ?
											<Tooltip placement="top" title={'Add to favorites'}>
												<PlusIcon onClick={() => addFavorites(item.text)} className="message__block_icons--plus"/>
											</Tooltip>
											: null}
									</div>
								</div>
							</div>
						))}
						{isLoadingMessage ?
							<div className="message">
								<div className="message__avatar-bot">
									<OctopusIcon className='message__avatar-bot--icon'/>
								</div>
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">Secretopus</div>
										<div
											className="message__block_title--date">{i18n.language === 'ru' ? '0 мин. назад' : '0 m. ago'}</div>
									</div>
									<div className="message__block--text-loader">
										{t('chat_querying_db')}
										<Loader size={16}/>
									</div>
								</div>
							</div>
							: null}
						{error ?
							<div className="message">
								<div className="message__avatar-bot">
									<OctopusIcon className='message__avatar-bot--icon'/>
								</div>
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">Secretopus</div>
										<div className="message__block_title--date">{getTimeDifference(dayjs(), i18n)}</div>
									</div>
									<div className="message__block--text-error">
										{t('chat_system_error')}
									</div>
								</div>
							</div>
							: null}
					</div>
				</div>
			</div>
			<div className="chat__footer">
				<Tooltip
					placement="left"
					title={t('chat_tooltip_badge_search')}
				>
					<div className='chat__tabs'>
						<div
							className={`chat__tabs-item ${activeTab === TABS.MESSAGE ? 'chat__tabs--active' : ''}`}
							onClick={() => setActiveTab(TABS.MESSAGE)}
						>
							<OctopusIcon className='chat__tabs_icon-octopus'/>
						</div>
						<div
							className={`chat__tabs-item ${activeTab === TABS.SEARCH ? 'chat__tabs--active' : ''}`}
							onClick={() => setActiveTab(TABS.SEARCH)}
						>
							<SearchIcon className='chat__tabs_icon-search'/>
						</div>
					</div>
				</Tooltip>
				<div
					className="chat-input"
					tabIndex={0}
				>
					<ChatIcon className="chat-input__left-icon"/>
					<textarea
						ref={textAreaRef}
						value={text}
						onChange={changeText}
						onKeyDown={handleKeyDown}
						className="chat-input__input custom-scroll"
						placeholder={t('chat_message_input')}
						id={"autoTextarea"}
					/>
					<ArrowTopCircleIcon
						onClick={addText}
						className={`chat-input__right-icon${text ? '--active' : ''}`}/>
				</div>
			</div>
		</>
	);
};

export default MessageBlock;